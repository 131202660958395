/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { Route, withRouter, Link } from "react-router-dom";
import {
  getAutoTagStatus,
  getAutoTagStatusSuccess
} from "../../../redux/actions/performancePage";

import { connect } from "react-redux";

const l = console.log;

export const getColor = (value, negate) => {
  value = value / 100;
  var v = negate ? 1 - value : value;
  var hue = Math.round(v * 120).toString(10);
  return ["hsl(", hue, ",100%,50%)"].join("");
};
class AutotagStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      daysAgo: 7,
      groupByDay: false
    };
    this.props.getAutoTagStatus({ groupByEv: true });
  }

  render() {
    l("state=", this.state);
    l("props=", this.props);
    let keys = {};
    let ats;
    if (this.props.autoTagStatus) ats = this.props.autoTagStatus;
    else ats = {};
    if (ats) {
      for (let [grp, vals] of Object.entries(ats))
        for (let [keyk, keyv] of Object.entries(vals))
          for (let [itemk, itemv] of Object.entries(keyv)) {
            l("grp=", grp);
            if (!keys[grp]) keys[grp] = [];
            if (!keys[grp].includes(itemk)) keys[grp].push(itemk);
          }
    }
    l("keys=", keys);
    return (
      <div>
        {ats &&
          Object.entries(ats).map(([group, data], keyi) => {
            let dent = data ? Object.entries(data) : [];
            if (ats) l(group, ":", ats[keyi]);
            return (
              <div key={group + keyi}>
                <h4>{group}</h4>
                <table border={1}>
                  <thead>
                    <tr>
                      {group === "Packages" ? (
                        <React.Fragment>
                          <th>Package</th>
                          <th>Event</th>
                        </React.Fragment>
                      ) : (
                        <th>Annotator</th>
                      )}
                      {this.state.groupByDay && <th>Sample date</th>}
                      {keys[group].map(key => (
                        <th
                          style={{
                            maxWidth: "100px",
                            wordBreak: "break-word"
                          }}
                          key={"head-" + key}
                        >
                          {key}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {dent.map(([keyr, keyvs], rowidx) => {
                      let istop =
                        !dent[rowidx - 1] ||
                        dent[rowidx - 1][0].split(":")[0] !==
                          keyr.split(":")[0];
                      return (
                        <tr
                          key={group + keyi}
                          style={istop ? { borderTop: "black solid 2px" } : {}}
                        >
                          {keyr.split(":").map((grpfn, idx) => (
                            <td key={group + keyi + grpfn}>
                              {idx === 0 && istop
                                ? grpfn
                                : idx !== 0
                                  ? grpfn
                                  : ""}
                            </td>
                          ))}
                          {keys[group].map((keyk, keyki) => {
                            let keyv = keyvs[keyk] ? keyvs[keyk] : 0.0;
                            let summary = keyr !== "Summary";
                            let percent =
                              keyk.includes("percent") ||
                              keyk.includes("precent");
                            let negative =
                              keyk.includes("Error") ||
                              keyk.includes("Negative");
                            //l('keyv=',keyv);
                            return (
                              <td
                                key={keyki}
                                style={
                                  summary && percent
                                    ? {
                                        textAlign: "right",
                                        backgroundColor: getColor(
                                          keyv,
                                          negative ? 1 : 0
                                        )
                                      }
                                    : { textAlign: "right" }
                                }
                              >
                                {true
                                  ? percent
                                    ? keyv.toFixed(2)
                                    : keyv.toLocaleString()
                                  : keyv}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            );
          })}
      </div>
    );
  }
}

const mapStateToProps = ({ performancePage }) => ({
  autoTagStatus: performancePage.autoTagStatusByEv
});

const mapDispatchToProps = {
  getAutoTagStatus,
  getAutoTagStatusSuccess
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AutotagStatus)
);
