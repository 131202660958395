import {
  getContentDataFilesAPI,
  getContentDataLinkAPI
} from "../actions/contentDataGateway";
import * as AT from "../actionTypes/index";

export default ({ dispatch }) => next => async action => {
  switch (action.type) {
    case AT.GET_CONTENT_DATA_FILES: {
      dispatch(getContentDataFilesAPI(action.payload.dir));
      return next(action);
    }
    case AT.GET_CONTENT_DATA_LINK: {
      dispatch(getContentDataLinkAPI(action.payload.dir));
      return next(action);
    }
    default:
      return next(action);
  }
};
