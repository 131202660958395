import { createStore, compose, applyMiddleware } from "redux";
import appReducer from "./reducers";
import apiMiddleware from "./middlewares/api";
import performanceMiddleware from "./middlewares/performance";
import contentDataMiddleware from "./middlewares/contentData";

const middlewares = [
  apiMiddleware,
  performanceMiddleware,
  contentDataMiddleware
];

const isDev = process.env.NODE_ENV !== "production";

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers =
  (isDev && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

if (isDev) {
  // eslint-disable-next-line global-require
  middlewares.push(require("redux-freeze"));
}

const store = createStore(
  appReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);

export default store;
