export const NETWORK_LABELS = {
  LOAD_APPS: "LOAD_APPS",
  LOAD_INSIGHTS: "LOAD_INSIGHTS",
  LOAD_LABELS: "LOAD_LABELS",
  LOAD_APP_VERSIONS: "LOAD_APP_VERSIONS",
  SAVE_INSIGHT: "SAVE_INSIGHT",
  SET_INSIGHTS_IMPORTANCE: "SET_INSIGHTS_IMPORTANCE",
  LOAD_PERFORMANCE: "LOAD_PERFORMANCE",
  SAVE_PERFORMANCE: "SAVE_PERFORMANCE",
  LOAD_SIGNATURE_VIDEOS: "LOAD_SIGNATURE_VIDEOS",
  SAVE_ANNOTATED_EVENTS: "SAVE_ANNOTATED_EVENTS",
  GET_DEVICE_MODELS: "GET_DEVICE_MODELS",
  LOGIN: "LOGIN",
  LOAD_EMAILS: "LOAD_EMAILS",
  GET_STATUS: "GET_STATUS",
  INVALIDATE_CDN: "INVALIDATE_CDN",
  DEPLOY: "DEPLOY",
  GET_APPS_LIST: "GET_APPS_LIST",
  ADD_USER: "ADD_USER",
  GET_USER: "GET_USER",
  GET_ALL_USERS: "GET_ALL_USERS",
  DELETE_USER: "DELETE_USER",
  DELETE_USER_APP: "DELETE_USER_APP",
  EDIT_USER_APPS: "EDIT_USER_APPS",
  EDIT_USER_DETAILS: "EDIT_USER_DETAILS",
  GET_USER_APPS: "GET_USER_APPS",
  GET_RELEASES: "GET_RELEASES",
  ADD_NEW_APPS: "ADD_NEW_APPS",
  GET_APPS_DATA: "GET_APPS_DATA",
  UPDATE_APPS: "UPDATE_APPS",
  UPDATE_RELEASES: "UPDATE_RELEASES",
  CREATE_INSIGHT: "CREATE_INSIGHT",
  ADD_IMAGE: "ADD_IMAGE",
  SET_BAD_VIDEO_FOR_SAMPLE: "SET_BAD_VIDEO_FOR_SAMPLE",
  UNSET_BAD_VIDEO_FOR_SAMPLE: "UNSET_BAD_VIDEO_FOR_SAMPLE",
  VERIFY_PENDING_ANNOTATION: "VERIFY_PENDING_ANNOTATION",
  GET_CONTENT_DATA_FILES: "GET_CONTENT_DATA_FILES",
  GET_CONTENT_DATA_LINK: "GET_CONTENT_DATA_LINK"
};
