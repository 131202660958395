import React, { Fragment } from "react";
import { Popover, Button, Tooltip } from "antd";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const Swal2 = withReactContent(Swal);
const l = console.log;

export default function AnnotatorHeader({
  currentSampleIndex = 0,
  currentSample,
  dataLength = 0,
  setBadVideoForSampleAPI,
  verifyPendingSampleAPI,
  unsetBadVideoForSampleAPI,
  user,
  nextSample,
  previousSample,
  representatives,
  readonly
}) {
  let isPendingAnnotationVerification =
    currentSample._state === "pending_annotation_verification";
  let isAnnotationVerificationAllowed =
    user &&
    user.attributes &&
    user.attributes.email &&
    user.attributes.email.endsWith("@watchful.ai");
  let isBadSample = !!currentSample.error;

  let verificationReasons = null;
  let lastAnnotatorName = null;

  // if the sample is annotated
  if (currentSample._annotations && currentSample._annotations.length) {
    let lastAnnotation =
      currentSample._annotations[currentSample._annotations.length - 1];

    // Get the reasons of needed verification
    if (isPendingAnnotationVerification) {
      let validationInfo = lastAnnotation.annotation._validation || null;
      if (
        validationInfo &&
        validationInfo.verification_required &&
        validationInfo.reasons
      ) {
        verificationReasons = validationInfo.reasons.map((x, index) => (
          <p>
            {index + 1}. <b>{x.message}: </b>
            {x.code === "significant_deviation" ? (
              <em>
                {x.event_name}({x.subevent_name}) user: {x.value_user} / avg:{" "}
                {x.value_avg}
              </em>
            ) : x.code === "new_version" ? (
              <em>
                current ver.{" "}
                {currentSample.version_info &&
                  currentSample.version_info.version_name}
              </em>
            ) : (
              JSON.stringify(x)
            )}
          </p>
        ));
      }
      if (!verificationReasons) {
        verificationReasons = <p>unknown reason</p>;
      }
    }

    // Get last annotator name
    if (lastAnnotation.annotator) {
      lastAnnotatorName = lastAnnotation.annotator.name;
    }
  }

  if (!currentSample.guid) {
    return null;
  }

  //debugger
  let rep_guid;
  if (representatives.length > 0 && currentSample.device_info) {
    let rep = representatives.find(
      rep => rep.sample_product_model == currentSample.device_info.product_model
    );
    if (rep) {
      rep_guid = rep.rep_sample_guid;
    }
  }

  return (
    <div className="performance-tab__header">
      {dataLength > 1 &&
        currentSampleIndex != 0 && (
          <Tooltip title="Previous Sample">
            <Button
              type="primary"
              shape="circle"
              icon="left"
              onClick={() =>
                previousSample &&
                Swal2.fire({
                  title: "Are you sure?",
                  text: "Any unsaved changes will be dismissed!",
                  type: "info",
                  cancelButtonText: "Cancel"
                }).then(value => {
                  if (value.value) {
                    previousSample();
                  }
                })
              }
            />
          </Tooltip>
        )}
      Sample number: {currentSampleIndex + 1} out of: {dataLength}{" "}
      {dataLength > 1 &&
        currentSampleIndex + 1 < dataLength && (
          <Tooltip text="Next Sample">
            <Button
              type="primary"
              shape="circle"
              icon="right"
              onClick={() =>
                nextSample &&
                Swal2.fire({
                  title: "Are you sure?",
                  text: "Any unsaved changes will be dismissed!",
                  type: "info",
                  cancelButtonText: "Cancel"
                }).then(value => {
                  if (value.value) {
                    nextSample();
                  }
                })
              }
            />
          </Tooltip>
        )}
      {currentSample && (
        <div>
          <Popover
            placement="bottom"
            trigger="hover"
            content={
              <div>
                <p>
                  Navigate Forward: <em>→ (right arrow key)</em>
                </p>
                <p>
                  Navigate Backward: <em>← (left arrow key)</em>
                </p>
                <p>
                  Navigate Faster: <em>⇧ (hold shift key with arrow key)</em>
                </p>
                {!readonly && (
                  <p>
                    Capture Frame: <em>↓ (down arrow key)</em>
                  </p>
                )}
                <p>
                  Next Event: <em>N (N key)</em>
                </p>
                {!readonly && (
                  <p>
                    Save and Next Sample: <em>S (S key)</em>
                  </p>
                )}
              </div>
            }
          >
            <Button style={{ marginLeft: "1rem" }}>Key Shortcuts</Button>
          </Popover>
          <Popover
            placement="bottom"
            trigger="hover"
            content={
              <div>
                <p>Guid: {currentSample.guid || ""}</p>
                <p>App Package Name: {currentSample.package_name || ""}</p>
                <p>
                  App Version:{" "}
                  {(currentSample.version_info &&
                    currentSample.version_info.version_name) ||
                    "N/A"}
                </p>
                <p>
                  Device Manufacturer:{" "}
                  {(currentSample.device_info &&
                    currentSample.device_info.manufacturer) ||
                    "N/A"}
                </p>
                <p>
                  Device Model:{" "}
                  {(currentSample.device_info &&
                    currentSample.device_info.product_model) ||
                    "N/A"}
                </p>
                <p>
                  OS Version:{" "}
                  {(currentSample.device_info &&
                    currentSample.device_info.release_version) ||
                    "N/A"}
                </p>
                <p>Device ID: {currentSample.device_id || "N/A"}</p>
                <p>
                  Sample Date:{" "}
                  {(currentSample.started_at && currentSample.started_at) ||
                    "N/A"}
                </p>
                <p>
                  Jenkins URL:{" "}
                  {(currentSample.jenkins_build_url &&
                    currentSample.jenkins_build_url) ||
                    "N/A"}
                </p>
                <p>
                  Sample Video:{" "}
                  <a
                    href={currentSample.video_constant_fps}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Open sample video
                  </a>
                </p>
                <p>
                  Annotator:
                  {lastAnnotatorName || "N/A"}
                </p>
                <p>
                  <a
                    target="_blank"
                    href={`/#/performance/nonstrict-annotator?app=${
                      currentSample.package_name
                    }&location=${
                      currentSample.orchestrator_geolocation.country
                    }&guids[0]=${rep_guid}&deviceModels[0]=${
                      currentSample.device_info.product_model
                    }&fetch=true&readonly=true`}
                  >
                    Rep Sample
                  </a>
                </p>
              </div>
            }
          >
            <Button style={{ marginLeft: "1rem" }}>Sample Info</Button>
          </Popover>

          <Popover
            placement="bottom"
            trigger="hover"
            content={
              <div>
                {isBadSample && (
                  <Fragment>
                    <p>
                      <b>Reason:</b>
                    </p>
                    <p>{currentSample.error.reason}</p>
                  </Fragment>
                )}
              </div>
            }
          >
            <Button
              disabled={readonly}
              style={{
                marginLeft: 15
              }}
              onClick={() => {
                const docID = currentSample._id;
                if (isBadSample) {
                  unsetBadVideoForSampleAPI({ sampleDocID: docID });
                } else {
                  setBadVideoForSampleAPI({ sampleDocID: docID });
                }
              }}
            >
              {isBadSample ? "Mark as GOOD" : "Mark as BAD"}
            </Button>
          </Popover>

          {isPendingAnnotationVerification ? (
            <Popover
              placement="bottom"
              trigger="hover"
              content={
                <div>
                  {verificationReasons && (
                    <Fragment>
                      <p>
                        <b>Reasons:</b>
                      </p>
                      {verificationReasons}
                    </Fragment>
                  )}
                </div>
              }
            >
              <Button
                disabled={!isAnnotationVerificationAllowed}
                title="sample state is marked as pending annotation verification. click to verify"
                style={{
                  marginLeft: 15
                }}
                onClick={() => {
                  const docID = currentSample._id;
                  if (isPendingAnnotationVerification) {
                    verifyPendingSampleAPI({ sampleDocID: docID });
                  }
                  // nextSample();
                }}
              >
                Verify
              </Button>
            </Popover>
          ) : null}

          {isBadSample && (
            <div
              style={{
                width: 120,
                position: "relative",
                display: "inline",
                fontSize: "medium",
                marginLeft: 15,
                color: "white",
                background: "red"
              }}
            >
              BAD SAMPLE 💀
            </div>
          )}
        </div>
      )}
    </div>
  );
}
