import superagent from "superagent";

const apiUtils = {
  request({ method, url, data = null, headers = {}, outServer }) {
    let src = `${API_HOST}/`;
    if (outServer) {
      src = "";
    }
    const request = superagent(method, `${src}${url}`).set(
      "Accept",
      "application/json"
    );

    if (data) {
      if (method.toUpperCase() === "GET") {
        request.query(data);
      } else {
        request.send(data).set("Content-type", "application/json");
      }
    }

    request.set(headers);

    return request.then(data => data); // force request to be sent
  }
};

export default apiUtils;
