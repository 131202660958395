import React, { createRef, useState, useEffect } from "react";
import { Slider } from "antd";

export default function NonStrictAnnotatorScrubber({
  scrubberFrame = 0,
  startFrame = 0,
  endFrame = 0,
  handleScrubberChange,
  scrubberAnnotationMarkers,
  scrubberAverageAnnotationMarkers,
  setScrubberInFocusParent
}) {
  let scrubberRef = createRef();
  const [scrubberInFocus, setScrubberInFocus] = useState(false);
  useEffect(
    () => {
      if (setScrubberInFocusParent) {
        setScrubberInFocusParent(scrubberInFocus);
      }
    },
    [scrubberInFocus]
  );

  const filteredScrubberAvgMarkers = {};

  for (const [key, val] of Object.entries(scrubberAnnotationMarkers)) {
    if (Number(key) >= startFrame && Number(key) <= endFrame) {
      filteredScrubberAvgMarkers[Number(key)] = val;
    }
  }

  // const filteredScrubberAnnotationMarkers = {};
  // for (const [key, val] of Object.entries(scrubberAverageAnnotationMarkers)) {
  //   if (Number(key) >= startFrame && Number(key) <= endFrame) {
  //     filteredScrubberAnnotationMarkers[Number(key)] = val;
  //   }
  // }
  return (
    <Slider
      className="scrubber"
      ref={scrubberRef}
      marks={filteredScrubberAvgMarkers}
      min={startFrame}
      max={endFrame}
      value={scrubberFrame}
      tooltipVisible
      step={1}
      onChange={handleScrubberChange}
      onFocus={() => setScrubberInFocus(true)}
      onBlur={() => setScrubberInFocus(false)}
      tipFormatter={value => value}
    />
  );
}
