import Auth from "@aws-amplify/auth";
import { startNetwork, endNetwork } from "../actions/network";
import * as actionTypes from "../actionTypes/index";

import apiUtils from "../../utils/api";

const apiMiddleware = ({ dispatch }) => next => async action => {
  if (action.type !== actionTypes.API_REQUEST) {
    return next(action);
  }
  const currentAWSSession = await Auth.currentSession();
  const token = currentAWSSession.idToken.jwtToken;

  const { payload } = action;
  const {
    url,
    onSuccess,
    onFailure,
    label,
    data,
    method = "GET",
    outServer = false,
    headers = {
      Authorization: `Bearer ${token}`
    }
  } =
    payload || {};

  dispatch(startNetwork(label));

  apiUtils
    .request({
      method,
      url,
      data,
      headers,
      outServer
    })
    .then(({ body }) => {
      dispatch(endNetwork(label));

      if (onSuccess) onSuccess(body, dispatch);
    })
    .catch(error => {
      console.error("API error", error, action);
      dispatch(endNetwork(label));

      if (onFailure) onFailure(error, dispatch);
    });

  return next(action);
};

export default apiMiddleware;
