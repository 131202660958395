import {
  getAnnotationQuotas,
  loadPerformanceAPI,
  updatePerformanceAPI,
  getAppsListAPI,
  loadSignatureVideosAPI,
  saveAnnotatedEventsAPI,
  getDeviceModelsAPI,
  anonymizeVideoAPI,
  getAnonymizationQueueAPI
} from "../actions/performancePage";
import * as AT from "../actionTypes/index";

const l = console.log;
const performanceMiddleware = ({ dispatch }) => next => async action => {
  // l("in performance middleware. action=",action);
  switch (action.type) {
    case AT.SEND_SAMPLE_FOR_ANONYMIZATION: {
      dispatch(anonymizeVideoAPI(action.payload.guid, action.payload.rects));
      return next(action);
    }
    case AT.LOAD_PERFORMANCE: {
      dispatch(loadPerformanceAPI(action.payload));
      return next(action);
    }
    case AT.LOAD_ANNOTATION_QUOTAS: {
      dispatch(getAnnotationQuotas(action.payload));
      return next(action);
    }
    case AT.UPDATE_PERFORMANCE: {
      dispatch(updatePerformanceAPI(action.payload));
      return next(action);
    }
    case AT.LOAD_SIGNATURE_VIDEOS: {
      dispatch(loadSignatureVideosAPI(action.payload));
      return next(action);
    }
    case AT.SAVE_ANNOTATED_EVENTS: {
      dispatch(
        saveAnnotatedEventsAPI(
          action.payload.annotatedEvents,
          action.payload.check
        )
      );
      return next(action);
    }
    case AT.GET_ANONYMIZATION_QUEUE: {
      dispatch(getAnonymizationQueueAPI());
      return next(action);
    }
    case AT.GET_APPS_LIST: {
      dispatch(getAppsListAPI());
      return next(action);
    }
    case AT.GET_DEVICE_MODELS: {
      dispatch(getDeviceModelsAPI());
      return next(action);
    }
    default:
      return next(action);
  }
};

export default performanceMiddleware;
