import * as actionTypes from "../actionTypes";

const INIT_STATE = {
  files: [],
  isLoading: {},
  error: null
};

export default function(state = INIT_STATE, action) {
  switch (action.type) {
    case actionTypes.API_REQUEST_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        error: error.response.body && error.response.body.error
      };
    }
    case actionTypes.GET_CONTENT_DATA_FILES: {
      return {
        ...state,
        isLoading: true
      };
    }
    case actionTypes.GET_CONTENT_DATA_FILES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        files: action.payload.files
      };
    }
    case actionTypes.GET_CONTENT_DATA_LINK: {
      const { dir } = action.payload;
      return {
        ...state,
        files: state.files.map(
          x =>
            x.key === dir
              ? {
                  ...x,
                  downloadLink: null
                }
              : x
        )
      };
    }
    case actionTypes.GET_CONTENT_DATA_LINK_SUCCESS: {
      const { dir, downloadLink } = action.payload;
      return {
        ...state,
        error: null,
        files: state.files.map(
          x =>
            x.key === dir
              ? {
                  ...x,
                  downloadLink: downloadLink
                }
              : x
        )
      };
    }
    default: {
      return state;
    }
  }
}
