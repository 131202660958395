import React from "react";
import { Button, Table } from "antd";

export default ({ files, isLoading, onChangeFolder, onGetDownloadLink }) => {
  const formatBytes = (bytes, decimals) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024,
      dm = decimals || 2,
      sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (value, record) => (
        <React.Fragment>
          {record.type === "dir" ? (
            <Button
              title={record.key}
              onClick={() => onChangeFolder(record.key)}
            >
              {record.name}
            </Button>
          ) : (
            <div title={record.key}>{record.name}</div>
          )}
        </React.Fragment>
      )
    },
    {
      title: "Size",
      dataIndex: "size",
      render: (value, record) => record.type === "file" && formatBytes(value)
    },
    {
      title: "Download",
      dataIndex: "key",
      render: (value, record) =>
        record.name !== ".." &&
        (record.downloadLink ? (
          <a href={record.downloadLink}>Download</a>
        ) : (
          <Button
            type="small"
            icon="download"
            title="Get Link"
            loading={record.downloadLink === null}
            onClick={() => onGetDownloadLink(record.key)}
          >
            Get Download Link
          </Button>
        ))
    }
  ];
  return (
    <div>
      <Table
        loading={isLoading}
        dataSource={files}
        columns={columns}
        rowKey={record => record.id}
        pagination={false}
      />
    </div>
  );
};
