import * as actionTypes from "../actionTypes/index";

const INIT_STATE = {
  validateTabData: [],
  errorsTabData: [],
  signatureCreatorTabData: [],
  nonStrictAnnotatorTabData: {},
  status: "",
  appsList: [],
  loadingData: false,
  updatingPerformance: false,
  deviceModels: [],
  anonymizedSample: null,
  anonymizedSamplesStatus: {},
  setBadVidSampleCount: 0,
  isValidating: false,
  validationError: null,
  goToNextSample: false,
  apiRequestError: null
};

const l = console.log;

export default function(state = INIT_STATE, action) {
  switch (action.type) {
    case actionTypes.LOAD_PERFORMANCE: {
      return {
        ...state,
        loadingData: true
      };
    }
    case actionTypes.LOAD_PERFORMANCE_SUCCESS: {
      const { error, data } = action.payload;
      if (!error) {
        return {
          ...state,
          validateTabData: data,
          loadingData: false
        };
      }
      return {
        ...state,
        errorsTabData: data,
        loadingData: false
      };
    }
    case actionTypes.LOAD_SIGNATURE_VIDEOS: {
      return { ...state, loadingData: true };
    }
    case actionTypes.LOAD_SIGNATURE_VIDEOS_SUCCESS: {
      if (action.payload.nonStrictMode) {
        return {
          ...state,
          nonStrictAnnotatorTabData: action.payload.data,
          loadingData: false
        };
      }
      return {
        ...state,
        signatureCreatorTabData:
          (action.payload.data && action.payload.data.samples) || [],
        sampleStats: action.payload.data && action.payload.data.query_stats,
        loadingData: false
      };
    }
    case actionTypes.SET_PERFORMANCE: {
      const { type, data } = action.payload;
      if (type === "validate") {
        return {
          ...state,
          dataValidate: data
        };
      }
      return {
        ...state,
        dataErrors: data
      };
    }
    case actionTypes.UPDATE_PERFORMANCE: {
      return {
        ...state,
        updatingPerformance: true
      };
    }
    case actionTypes.UPDATE_PERFORMANCE_SUCCESS: {
      return {
        ...state,
        status: action.payload.message,
        updatingPerformance: false
      };
    }
    case actionTypes.UPDATE_PERFORMANCE_FAILED: {
      return {
        ...state,
        status: action.payload.message,
        updatingPerformance: false
      };
    }
    case actionTypes.INIT_PERFORMANCE_STATUS: {
      return {
        ...state,
        status: ""
      };
    }
    case actionTypes.SET_VALIDATE_FORM: {
      return {
        ...state,
        validateForm: action.payload.form
      };
    }
    case actionTypes.SET_ERRORS_FORM: {
      return {
        ...state,
        errorsForm: action.payload.form
      };
    }
    case actionTypes.GET_APPS_LIST_SUCCESS: {
      return {
        ...state,
        appsList: action.payload.list.sort()
      };
    }
    case actionTypes.GET_DEVICE_MODELS_SUCCESS: {
      return {
        ...state,
        deviceModels: action.payload.deviceModels
      };
    }
    case actionTypes.VERIFY_PENDING_ANNOTATION_SUCCESS: {
      let tds = state.nonStrictAnnotatorTabData.samples;
      tds = tds.map(s => ({
        ...s,
        _state:
          action.sampleDocID === s._id
            ? "pending_events_metrics_enrichment"
            : s._state
      }));
      return {
        ...state,
        nonStrictAnnotatorTabData: {
          ...state.nonStrictAnnotatorTabData,
          samples: tds
        }
      };
    }
    case actionTypes.SET_BAD_VIDEO_FOR_SAMPLE_SUCCESS:
    case actionTypes.UNSET_BAD_VIDEO_FOR_SAMPLE_SUCCESS: {
      return {
        ...state,
        setBadVidSampleCount: state.setBadVidSampleCount + 1
      };
    }
    case actionTypes.LOAD_ANNOTATION_QUOTAS_SUCCESS: {
      let key = [
        action.payload.data.args.dateFrom,
        action.payload.data.args.dateTo
      ].join("-");
      let naq = { ...state.annotationQuotas };
      naq[key] = action.payload.data;
      let rt = {
        ...state,
        annotationQuotas: naq
      };
      return rt;
    }
    case actionTypes.LOAD_ANNOTATIONS_SUCCESS: {
      return {
        ...state,
        annotations: action.payload.data
      };
    }
    case actionTypes.SEND_SAMPLE_FOR_ANONYMIZATION: {
      return {
        ...state,
        anonymizedSamplesStatus: {
          ...state.anonymizedSamplesStatus,
          [action.payload.guid]: 0
        }
      };
    }
    case actionTypes.GET_ANONYMIZED_SAMPLE_SUCCESS: {
      return {
        ...state,
        anonymizedSample: action.payload.data
      };
    }
    case actionTypes.GET_ANONYMIZATION_QUEUE: {
      return {
        ...state,
        anonymizedSamplesStatus: null
      };
    }
    case actionTypes.GET_ANONYMIZATION_QUEUE_SUCCESS: {
      return {
        ...state,
        anonymizedSamplesStatus: action.payload.data
      };
    }
    case actionTypes.LOAD_AUTOTAG_STATUS_SUCCESS: {
      let rt = {
        ...state
      };
      let rtkey;
      if (action.payload.request.groupByEv) rtkey = "autoTagStatusByEv";
      else rtkey = "autoTagStatus";
      rt[rtkey] = action.payload.data;
      return rt;
    }
    case actionTypes.GET_EVENTS_SUCCESS: {
      return {
        ...state,
        events: action.payload.data
      };
    }
    case actionTypes.SAVE_ANNOTATED_EVENTS: {
      return {
        ...state,
        isValidating: true,
        validationError: null,
        goToNextSample: false
      };
    }
    case actionTypes.SAVE_ANNOTATED_EVENTS_SUCCESS: {
      return {
        ...state,
        isValidating: false,
        validationError: null,
        goToNextSample: false
      };
    }
    case actionTypes.CHECK_ANNOTATED_EVENTS_SUCCESS: {
      return {
        ...state,
        isValidating: false,
        validationError: null,
        goToNextSample: true
      };
    }
    case actionTypes.CHECK_ANNOTATED_EVENTS_FAILED: {
      return {
        ...state,
        isValidating: false,
        validationError: action.payload.error,
        goToNextSample: true
      };
    }
    case actionTypes.API_REQUEST_FAILED: {
      return {
        ...state,
        apiRequestError: action.payload.error
      };
    }
    case actionTypes.API_REQUEST_RESET_ERROR: {
      return {
        ...state,
        apiRequestError: null,
        isValidating: false
      };
    }
    default: {
      return state;
    }
  }
}
