import React, { Component } from "react";
import { connect } from "react-redux";
import Auth from "@aws-amplify/auth";
import Nav from "./common/Nav";
import Performance from "./performance/Performance";
import CurrentToken from "./currentToken/CurrentToken";
import { Route, Switch, Redirect, withRouter } from "react-router";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      types: [
        {
          label: "Performance",
          path: "performance/:tab?",
          url: "performance/quotas",
          component: Performance
        }
      ]
    };
  }

  renderRoute = ({ url, path, component }) => {
    if (!path) {
      path = url;
    }
    return <Route path={`/${path}`} component={component} key={url} />;
  };

  componentDidMount() {
    Auth.currentAuthenticatedUser({
      // Optional, By default is false.
      // If set to true, this call will send a request to Cognito to get the latest user data
      bypassCache: false
    })
      .then(user => {
        var cg = user.signInUserSession.idToken.payload["cognito:groups"];
        var logoutCnd =
          !cg || (!cg.includes("Admin") && !cg.includes("Annotators"));
        if (logoutCnd) {
          Auth.signOut({ global: true })
            .then(data => console.log(data))
            .catch(err => console.log(err));
        }
      })
      .catch(err => console.log(err));
  }

  render() {
    const { types } = this.state;
    return (
      <div style={{ height: "calc(100% - 67px)" }}>
        <Nav types={types} />
        <Switch>
          {types.map(this.renderRoute)}
          <Redirect to="performance/quotas" />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = ({ loginData }) => ({
  status: loginData.status
});

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(Home)
);
