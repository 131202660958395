/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/no-find-dom-node */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import { connect } from "react-redux";
import { isEqual, cloneDeep, isEmpty } from "lodash";
import { parse } from "qs";
import {
  Slider,
  Select,
  Button,
  Tooltip,
  message,
  Steps,
  Popover,
  Spin,
  Icon
} from "antd";
import Img from "react-image";
import WatchfulThumbnail from "../../../assets/icons/default_thumbnail.png";
import {
  getAppsList,
  getDeviceModels,
  loadSignatureVideos,
  loadSignatureVideosSuccess,
  saveAnnotatedEvents,
  setBadVideoForSampleAPI,
  verifyPendingSampleAPI
} from "../../../redux/actions/performancePage";
import { withRouter, Route } from "react-router-dom";
import AnnotatorHeader from "../AnnotatorHeader";
import PerformanceToolbar from "../performanceToolbar/PerformanceToolbar";
import Auth from "@aws-amplify/auth";

const l = console.log;
const TEST_VIDEO_URL =
  "https://watchful-app-record.s3.amazonaws.com/com.ebay.mobile/2019/6/fe427812-1d7a-4acc-911b-e355b959eb68/c12ec4c53c4242f4bad23aac726f62a2.mp4";

const VIDEO_FRAMERATE = 30;

const ARROW_KEYS_CODE = {
  LEFT: 37,
  RIGHT: 39,
  DOWN: 40
};

const KEY_CODES = {
  N: 78,
  S: 83
};

const ARROW_KEY_STEP_SIZE = 1;
const SHIFT_ARROW_KEY_STEP_SIZE = 20;

const { Step } = Steps;

class SignatureCreation extends Component {
  constructor(props) {
    super(props);

    this.mainVidRef;
    this.behindVidRef;
    this.mainFrameCanvas;
    this.behindFrameCanvas;
    this.sliderRef = React.createRef();

    this.state = {
      videoReady: false,
      framesDiffBase64: {},
      frameDiffPopupVisible: false,
      scrubberInFocus: false,
      videoDurationInFrames: undefined,
      scrubberFrame: 0,
      currentSignatureStep: 0,
      currentSampleIndex: 0,
      sampleChanges: {},
      shouldRecaptureCanvasImages: false,
      stepsContent: []
    };
  }

  setupRefShots = () => {
    const dimensions = this.mainVidRef.getBoundingClientRect();
    const referenceShot = document.getElementById("referenceShot");
    const behindReferenceShot = document.getElementById("behindReferenceShot");
    referenceShot.setAttribute(
      "style",
      "object-fit: cover; visibility: visible;"
    );
    behindReferenceShot.setAttribute(
      "style",
      "object-fit: cover; visibility: visible;"
    );
    referenceShot.width = dimensions.width / 2;
    referenceShot.height = dimensions.height / 2;
    behindReferenceShot.width = dimensions.width / 2;
    behindReferenceShot.height = dimensions.height / 2;
  };

  setupVideosAndCanvas = () => {
    this.mainVidRef = document.getElementById("main-video");
    this.behindVidRef = document.getElementById("behind-video");
    this.mainVidRef.load();
    this.behindVidRef.load();
    const dimensions = this.mainVidRef.getBoundingClientRect();

    this.mainVidRef.onloadedmetadata = e => {
      this.setState(
        prevState => ({
          videoReady: true,
          videoDurationInFrames: Math.floor(
            e.target.duration * VIDEO_FRAMERATE
          ),
          scrubberFrame: Math.floor(
            this.props.signatureCreatorTabData[prevState.currentSampleIndex]
              .events[prevState.currentSignatureStep].start_ts_s *
              VIDEO_FRAMERATE
          )
        }),
        () => {
          // console.log(this.state);

          this.mainFrameCanvas = document.getElementById("mainFrameCanvas");
          this.behindFrameCanvas = document.getElementById("behindFrameCanvas");
          if (!this.mainFrameCanvas) {
            return;
          }
          this.mainFrameCanvas.width = dimensions.width / 2;
          this.mainFrameCanvas.height = dimensions.height / 2;
          this.behindFrameCanvas.width = dimensions.width / 2;
          this.behindFrameCanvas.height = dimensions.height / 2;
        }
      );
    };

    this.mainVidRef.onseeking = () => {
      // console.log('seeking');
      this.mainVidRef.style.opacity = 0.3;
      this.behindVidRef.style.opacity = 0.3;
    };
    this.behindVidRef.oncanplay = () => {
      // const stepsContent = this.getStepsContent();
      const { stepsContent } = this.state;
      // console.log('oncanplay');
      this.mainVidRef.style.opacity = 1;
      this.behindVidRef.style.opacity = 1;
      if (this.state.shouldRecaptureCanvasImages) {
        if (
          true ||
          this.state.sampleChanges[
            this.props.signatureCreatorTabData[this.state.currentSampleIndex]
              .guid
          ][
            stepsContent[this.state.currentSignatureStep].eventData.parentEvent
              .name
          ][stepsContent[this.state.currentSignatureStep].eventData.name]
        ) {
          this.captureVideoSnapshot("", "", "", "", "", true);
          this.setState({ shouldRecaptureCanvasImages: false });
        } else {
          this.mainFrameCanvas
            .getContext("2d")
            .clearRect(
              0,
              0,
              this.mainFrameCanvas.width,
              this.mainFrameCanvas.height
            );
          this.behindFrameCanvas
            .getContext("2d")
            .clearRect(
              0,
              0,
              this.behindFrameCanvas.width,
              this.behindFrameCanvas.height
            );
          const thumbnail = document.createElement("img");
          thumbnail.src = WatchfulThumbnail;
          thumbnail.style.objectFit = "cover";
          this.mainFrameCanvas
            .getContext("2d")
            .drawImage(
              thumbnail,
              -25,
              0,
              thumbnail.width / 2,
              thumbnail.height / 2
            );
          this.behindFrameCanvas
            .getContext("2d")
            .drawImage(
              thumbnail,
              -25,
              0,
              thumbnail.width / 2,
              thumbnail.height / 2
            );
        }
      }
    };

    document.onkeydown = e => {
      // check arrow key press
      let stepSize = 0;
      let stepSizeReduceSize = 0;
      if (
        e.keyCode === ARROW_KEYS_CODE.LEFT ||
        e.keyCode === ARROW_KEYS_CODE.RIGHT
      ) {
        if (this.state.scrubberInFocus) {
          // in that case, the slider component controlls the actual key press event,
          // so im setting the tep size to 1 in order to not move 2 frmaes at once
          stepSizeReduceSize = 1;
        } else {
          stepSizeReduceSize = 0;
        }
      }
      const {
        scrubberFrame,
        videoDurationInFrames,
        currentSampleIndex,
        currentSignatureStep,
        sampleChanges
      } = this.state;
      const stepsContent = this.getStepsContent();
      const currentSample = this.props.signatureCreatorTabData[
        currentSampleIndex
      ];
      const currentStep = stepsContent[currentSignatureStep];
      switch (e.keyCode) {
        case ARROW_KEYS_CODE.LEFT:
          if (scrubberFrame > 0) {
            if (e.shiftKey) {
              stepSize -= SHIFT_ARROW_KEY_STEP_SIZE - stepSizeReduceSize;
            } else {
              stepSize -= ARROW_KEY_STEP_SIZE - stepSizeReduceSize;
            }
            this.handleScrubberChange(scrubberFrame + stepSize);
          }
          break;

        case ARROW_KEYS_CODE.RIGHT:
          if (scrubberFrame < videoDurationInFrames) {
            if (e.shiftKey) {
              stepSize += SHIFT_ARROW_KEY_STEP_SIZE - stepSizeReduceSize;
            } else {
              stepSize += ARROW_KEY_STEP_SIZE - stepSizeReduceSize;
            }
            this.handleScrubberChange(scrubberFrame + stepSize);
          }
          break;

        case ARROW_KEYS_CODE.DOWN:
          // when the slider component in focus it handles keypresses by its own
          // we need to compensate this by adding one frame (down arrow goes one step back in the slider)
          if (this.state.scrubberInFocus) {
            this.handleScrubberChange(scrubberFrame + 1);
          }
          setTimeout(() => {
            this.captureVideoSnapshot(
              currentSample.guid,
              currentStep.eventData.parentEvent.name,
              currentStep.eventData.name,
              currentStep.eventData.title,
              scrubberFrame
            );
          }, 150);
          break;

        case KEY_CODES.N:
          if (
            sampleChanges[currentSample.guid] &&
            sampleChanges[currentSample.guid][
              stepsContent[currentSignatureStep].eventData.parentEvent.name
            ] &&
            sampleChanges[currentSample.guid][
              stepsContent[currentSignatureStep].eventData.parentEvent.name
            ][stepsContent[currentSignatureStep].eventData.name] &&
            sampleChanges[currentSample.guid][
              stepsContent[currentSignatureStep].eventData.parentEvent.name
            ][stepsContent[currentSignatureStep].eventData.name].end_frame_index
          ) {
            if (currentSignatureStep < stepsContent.length - 1) {
              this.handleSignatureNextStep();
            }
          }
          break;
        case KEY_CODES.S:
          if (currentSignatureStep === stepsContent.length - 1) {
            this.handleSaveAndNext({ nextOnly: false });
          }
          break;
        default:
          break;
      }
    };
  };
  componentDidMount() {
    Auth.currentAuthenticatedUser().then(user => {
      this.setState({ user: user });
    });

    if (this.props.appsList.length === 0) {
      this.props.getAppsList();
    }

    if (this.props.deviceModels.length === 0) {
      this.props.getDeviceModels();
    }

    if (document.getElementById("main-video")) {
      this.setupVideosAndCanvas();
    }

    if (document.getElementById("referenceShot") && this.mainVidRef) {
      this.setupRefShots();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.signatureCreatorTabData.length > 0) {
      if (document.getElementById("referenceShot") && this.mainVidRef) {
        this.setupRefShots();
      }

      if (!isEqual(this.state.stepsContent, this.getStepsContent())) {
        this.setState({ stepsContent: this.getStepsContent() });
      }

      if (
        document.getElementById("main-video") &&
        (!isEqual(
          this.props.signatureCreatorTabData,
          prevProps.signatureCreatorTabData
        ) ||
          !isEqual(this.state.currentSampleIndex, prevState.currentSampleIndex))
      ) {
        this.setupVideosAndCanvas();
      }
    }

    if (
      this.state.frameDiffPopupVisible &&
      !isEqual(this.state.scrubberFrame, prevState.scrubberFrame)
    ) {
      this.fetchDiffImageForCurrentFrame();
    }
  }

  componentWillUnmount() {
    this.props.loadSignatureVideosSuccess([], false, undefined);
    document.onkeydown = null;
  }

  handleScrubberChange = scrubberValue => {
    // const { currentSampleIndex, currentSignatureStep } = this.state;
    // const { signatureCreatorTabData } = this.props;
    // const stepsContent = this.getStepsContent();
    // if (currentSignatureStep <= stepsContent.length - 1) {
    //   let parentEventData = {};
    //   let nextParentEventData = {};
    //   signatureCreatorTabData[currentSampleIndex].events.forEach((event, index, arr) => {
    //     if (event.name === stepsContent[currentSignatureStep].eventData.parentEvent.name) {
    //       parentEventData = event;
    //       if (index === arr.length - 1) {
    //         nextParentEventData = arr[index];
    //       } else {
    //         nextParentEventData = arr[index + 1];
    //       }
    //     }
    //   });
    //   if (
    //     scrubberValue < Math.floor(parentEventData.start_ts_s * VIDEO_FRAMERATE) ||
    //     (currentSignatureStep !== stepsContent.length - 1 &&
    //       scrubberValue > Math.floor(nextParentEventData.start_ts_s * VIDEO_FRAMERATE))
    //   ) {
    //     console.log('Cant set event ending after another events beginning!');
    //     return;
    //   }
    // }
    this.mainVidRef.currentTime = scrubberValue / VIDEO_FRAMERATE;
    this.behindVidRef.currentTime = (scrubberValue - 1) / VIDEO_FRAMERATE;
    this.setState({ scrubberFrame: scrubberValue });
  };

  handleSignatureNextStep = () => {
    // const wizardSteps = this.getStepsContent();
    const wizardSteps = this.state.stepsContent;
    if (this.state.currentSignatureStep < wizardSteps.length - 1) {
      const moveScrubber =
        wizardSteps[this.state.currentSignatureStep].eventData.parentEvent
          .name !==
        wizardSteps[this.state.currentSignatureStep + 1].eventData.parentEvent
          .name;
      const nextParentEvent = this.props.signatureCreatorTabData[
        this.state.currentSampleIndex
      ].events.find(
        event =>
          event.name ===
          wizardSteps[this.state.currentSignatureStep + 1].eventData.parentEvent
            .name
      );
      const nextEventStartFrame = nextParentEvent.start_frame_index;
      if (moveScrubber) {
        this.handleScrubberChange(nextEventStartFrame);
      }
      this.setState(
        prevState => ({
          currentSignatureStep: prevState.currentSignatureStep + 1,
          shouldRecaptureCanvasImages: true
        }),
        () => {
          const currentWizardStep =
            wizardSteps[this.state.currentSignatureStep];
          const currentSampleGuid = this.props.signatureCreatorTabData[
            this.state.currentSampleIndex
          ].guid;
          if (
            this.state.sampleChanges[currentSampleGuid] &&
            this.state.sampleChanges[currentSampleGuid][
              currentWizardStep.eventData.parentEvent.name
            ] &&
            this.state.sampleChanges[currentSampleGuid][
              currentWizardStep.eventData.parentEvent.name
            ][currentWizardStep.eventData.name]
          ) {
            // this step has a captured frame already
            this.handleScrubberChange(
              this.state.sampleChanges[currentSampleGuid][
                currentWizardStep.eventData.parentEvent.name
              ][currentWizardStep.eventData.name].end_frame_index
            );
          } else if (
            this.state.sampleChanges[currentSampleGuid] &&
            !this.state.sampleChanges[currentSampleGuid][
              currentWizardStep.eventData.parentEvent.name
            ]
          ) {
            // this event is new
            const nextEventData = Math.floor(
              this.props.signatureCreatorTabData[
                this.state.currentSampleIndex
              ].events.find(
                event =>
                  event.name === currentWizardStep.eventData.parentEvent.name
              )
            );
            if (nextEventData) {
              this.handleScrubberChange(
                nextEventData.start_ts_s * VIDEO_FRAMERATE
              );
            }
          }
        }
      );
    }
  };

  handleSignaturePreviousStep = () => {
    // const wizardSteps = this.getStepsContent();
    const wizardSteps = this.state.stepsContent;
    if (this.state.currentSignatureStep > 0) {
      this.setState(
        prevState => ({
          currentSignatureStep: prevState.currentSignatureStep - 1,
          shouldRecaptureCanvasImages: true
        }),
        () => {
          const currentWizardStep =
            wizardSteps[this.state.currentSignatureStep];
          const currentSampleGuid = this.props.signatureCreatorTabData[
            this.state.currentSampleIndex
          ].guid;
          if (
            this.state.sampleChanges[currentSampleGuid] &&
            this.state.sampleChanges[currentSampleGuid][
              currentWizardStep.eventData.parentEvent.name
            ] &&
            this.state.sampleChanges[currentSampleGuid][
              currentWizardStep.eventData.parentEvent.name
            ][currentWizardStep.eventData.name]
          ) {
            // this step has a captured frame already
            this.handleScrubberChange(
              this.state.sampleChanges[currentSampleGuid][
                currentWizardStep.eventData.parentEvent.name
              ][currentWizardStep.eventData.name].end_frame_index
            );
          } else if (
            !this.state.sampleChanges[currentSampleGuid] &&
            this.state.sampleChanges[currentSampleGuid][
              currentWizardStep.eventData.parentEvent.name
            ]
          ) {
            // this event is new
            this.handleScrubberChange(
              Math.floor(
                currentSampleGuid.eventData.parentEvent.start_ts_s *
                  VIDEO_FRAMERATE
              )
            );
          }
        }
      );
    }
  };

  getStepsContent = () => {
    l("getStepsContent()");
    const dimensions =
      this.mainVidRef && this.mainVidRef.getBoundingClientRect();
    const currentURLQry = parse(
      this.props.history.location.search.replace(/^\?/, "")
    );
    const selectedApp = this.props.appsList.find(
      app => app.package === currentURLQry.app
    );
    let mainEventObj = {};
    const eventList = [];
    if (!selectedApp) {
      l("noselectedapp");
      return [];
    }

    selectedApp.performance_events.forEach(event => {
      mainEventObj = cloneDeep(event);
      let lastSubeventIndex = 0;
      const eventObj = cloneDeep(event);
      eventObj.type = "event";
      if (
        !this.state.annotateSubevents ||
        Object.keys(event.subevents).length === 0
      ) {
        delete eventObj.subevents;
        eventObj.title = eventObj.name;
        eventObj.parentEvent = mainEventObj;
        eventList.push(eventObj);
      }
      lastSubeventIndex =
        (eventObj.subevents && eventObj.subevents.length - 1) || 0;
      if (this.state.annotateSubevents) {
        event.subevents.forEach((subEvent, subEventIndex) => {
          const subEventObj = cloneDeep(subEvent);
          subEventObj.type = "subevent";
          subEventObj.parentEvent = mainEventObj;
          if (subEventIndex === 0 || true) {
            // first subevent
            subEventObj.title = `${event.title}: ${subEvent.title}`;
          }
          if (subEventIndex === lastSubeventIndex) {
            subEventObj.ref_frame_before_url =
              subEventObj.ref_frame_before_url === null
                ? eventObj.ref_frame_before_url
                : subEventObj.ref_frame_before_url;
            subEventObj.ref_frame_after_url =
              subEventObj.ref_frame_after_url === null
                ? eventObj.ref_frame_after_url
                : subEventObj.ref_frame_after_url;
          }
          eventList.push(subEventObj);
        });
      }
    });
    const orderedEventList = [];
    // order the steps in the correct order (the one arrived with the sample)
    this.props.signatureCreatorTabData[
      this.state.currentSampleIndex
    ].events.forEach(event => {
      orderedEventList.push(
        ...eventList.filter(
          e => e.name === event.name || e.parentEvent.name === event.name
        )
      );
    });
    return orderedEventList.map(event => ({
      // title: `${event.title}`,
      eventData: event,
      content: (
        <React.Fragment>
          <div>
            <h3 style={{ marginTop: 5 }}>{event.title}</h3>
            <h4>{event.description || ""}</h4>
            <h4>{event.name || ""}</h4>
          </div>
          <div className="signature-creator-content__right-steps-step-content-top">
            <Img
              id="behindReferenceShot"
              src={[event.ref_frame_before_url, WatchfulThumbnail]}
              style={{ objectFit: "cover" }}
              width={dimensions ? dimensions.width / 2 : 177}
              height={dimensions ? dimensions.height / 2 : 315}
            />
            <Img
              id="referenceShot"
              src={[event.ref_frame_after_url, WatchfulThumbnail]}
              style={{ objectFit: "cover" }}
              width={dimensions ? dimensions.width / 2 : 177}
              height={dimensions ? dimensions.height / 2 : 315}
            />
          </div>
          <div className="signature-creator-content__right-steps-step-content-bottom">
            <canvas id="behindFrameCanvas" />
            <canvas id="mainFrameCanvas" />
          </div>
        </React.Fragment>
      )
    }));
  };

  captureVideoSnapshot = (
    sampleGuid,
    parentEventName,
    eventName,
    eventTitle,
    currentFrame,
    canvasOnly = false
  ) => {
    // const canvas = document.createElement('canvas');
    const mainCtx = this.mainFrameCanvas.getContext("2d");
    const behindCtx = this.behindFrameCanvas.getContext("2d");
    mainCtx.drawImage(
      this.mainVidRef,
      0,
      0,
      this.mainFrameCanvas.width,
      this.mainFrameCanvas.height
    );
    behindCtx.drawImage(
      this.behindVidRef,
      0,
      0,
      this.behindFrameCanvas.width,
      this.behindFrameCanvas.height
    );

    if (!canvasOnly) {
      message.info("Frames copied.");
      this.setState(
        prevState => {
          const modifiedOldSampleChanges = cloneDeep(prevState.sampleChanges);
          if (
            modifiedOldSampleChanges[sampleGuid] &&
            modifiedOldSampleChanges[sampleGuid][parentEventName]
          ) {
            modifiedOldSampleChanges[sampleGuid][parentEventName][eventName] = {
              end_frame_index: currentFrame,
              end_ts_s: currentFrame / VIDEO_FRAMERATE,
              title: eventTitle
            };
          } else if (modifiedOldSampleChanges[sampleGuid]) {
            // this should happen only for the first subevent in an event
            modifiedOldSampleChanges[sampleGuid][parentEventName] = {
              [eventName]: {
                end_frame_index: currentFrame,
                end_ts_s: currentFrame / VIDEO_FRAMERATE,
                title: eventTitle
              }
            };
          } else {
            // this should happen only for the first event in a sample
            modifiedOldSampleChanges[sampleGuid] = {
              [parentEventName]: {
                [eventName]: {
                  end_frame_index: currentFrame,
                  end_ts_s: currentFrame / VIDEO_FRAMERATE,
                  title: eventTitle
                }
              }
            };
          }
          return {
            sampleChanges: modifiedOldSampleChanges
          };
        },
        () => {
          console.log(this.state);
        }
      );
    }
  };

  handleSaveAndNext = ({ nextOnly = false }) => {
    if (!nextOnly) {
      const sampleGuid = this.props.signatureCreatorTabData[
        this.state.currentSampleIndex
      ].guid;
      const originalEventsArr = [
        ...this.props.signatureCreatorTabData[this.state.currentSampleIndex]
          .events
      ];
      const sampleChanges = cloneDeep(this.state.sampleChanges);
      const arr = originalEventsArr.map(originalEventObj => {
        const eventsSubevents =
          Object.entries(sampleChanges[sampleGuid][originalEventObj.name]).map(
            ([subeventName, subeventValue]) => {
              const obj = cloneDeep(subeventValue);
              delete obj.title;
              return {
                name: subeventName,
                ...obj
              };
            }
          ) || [];
        let includeSubevents = true;
        if (
          eventsSubevents.length === 1 &&
          eventsSubevents[0].name === originalEventObj.name
        ) {
          includeSubevents = false;
        }
        return {
          ...originalEventObj,
          end_frame_index:
            eventsSubevents[eventsSubevents.length - 1].end_frame_index || 0,
          end_ts_s: eventsSubevents[eventsSubevents.length - 1].end_ts_s || 0,
          subevents: includeSubevents ? eventsSubevents : []
        };
      });

      const returnArr = [{ [sampleGuid]: { events: arr } }];
      console.log(returnArr);
      this.props.saveAnnotatedEvents(returnArr);
    }

    let moreSamples = false;
    if (
      this.state.currentSampleIndex <
      this.props.signatureCreatorTabData.length - 1
    ) {
      // theres are more samples to show
      moreSamples = true;
    }
    if (!moreSamples) {
      // reset the page data
      this.props.loadSignatureVideosSuccess([], false, undefined);
    }
    this.setState(
      prevState => ({
        currentSampleIndex: moreSamples ? prevState.currentSampleIndex + 1 : 0,
        currentSignatureStep: 0,
        videoReady: false,
        scrubberInFocus: false,
        videoDurationInFrames: undefined,
        scrubberFrame: 0,
        framesDiffBase64: {},
        frameDiffPopupVisible: false,
        sampleChanges: {},
        shouldRecaptureCanvasImages: false,
        stepsContent: moreSamples ? prevState.stepsContent : []
      }),
      () => {}
    );
  };

  fetchDiffImageForCurrentFrame = (forceFetch = false) => {
    const currentSample = this.props.signatureCreatorTabData[
      this.state.currentSampleIndex
    ];
    const baseURL =
      "https://thbijpd9lf.execute-api.us-east-1.amazonaws.com/backoffice_dev/utils/cv/diff_seq_video_frames";
    const vidURL = currentSample.video_constant_fps.replace("https", "http");
    const tempFrame =
      this.state.scrubberFrame === 0 ? 0 : this.state.scrubberFrame - 1;
    const frames = tFrame => `${tFrame === 0 ? 0 : tFrame - 1},${tFrame}`;
    // const frames = `${tempFrame === 0 ? 0 : tempFrame - 1},${tempFrame}`;
    if (
      forceFetch ||
      isEmpty(this.state.framesDiffBase64) ||
      !this.state.framesDiffBase64[frames(tempFrame + 3)] ||
      !this.state.framesDiffBase64[frames(tempFrame - 3)]
    ) {
      // if we dont have current frame or the available context size is smaller than 3
      let baseFrame = tempFrame;
      if (!this.state.framesDiffBase64[frames(tempFrame + 3)]) {
        baseFrame += 3;
      } else if (!this.state.framesDiffBase64[frames(tempFrame - 3)]) {
        baseFrame -= 3;
      }

      if (forceFetch) {
        const currentFramesDiff = { ...this.state.framesDiffBase64 };
        currentFramesDiff[frames] = null;
        this.setState({ framesDiffBase64: currentFramesDiff });
      }
      // currentFramesDiff[frames(tempFrame)] = null;
      fetch(
        `${baseURL}?video_url=${vidURL}&frame_index=${baseFrame}&context_size=5`
      ).then(response => {
        response.json().then(json => {
          const currentFramesDiff = { ...this.state.framesDiffBase64, ...json };
          // currentFramesDiff[frames] = `data:image/png;base64,${text}`;
          this.setState({ framesDiffBase64: currentFramesDiff }, () => {
            console.log(this.state.framesDiffBase64);
          });
        });
      });
      // this.setState({ framesDiffBase64: currentFramesDiff }, () => {
      // });
    }
  };

  render() {
    const {
      videoDurationInFrames,
      scrubberFrame,
      currentSignatureStep,
      currentSampleIndex,
      sampleChanges,
      videoReady,
      framesDiffBase64
    } = this.state;
    const stateStepsContent = this.state.stepsContent;
    const {
      appsList,
      loadingData,
      signatureCreatorTabData,
      deviceModels
    } = this.props;

    if (!appsList || appsList.length == 0) {
      return null;
    }

    const currentSample = signatureCreatorTabData[currentSampleIndex];
    let stepsContent = [];
    let currentStep;
    const scrubberMarks = {};
    if (signatureCreatorTabData.length > 0) {
      stepsContent =
        stateStepsContent.length > 0
          ? stateStepsContent
          : this.getStepsContent();
      currentStep = stepsContent[currentSignatureStep];
      stepsContent.forEach(step => {
        currentSample.events.forEach(performanceEvent => {
          if (performanceEvent.name === step.eventData.parentEvent.name) {
            scrubberMarks[
              Math.floor(performanceEvent.start_ts_s * VIDEO_FRAMERATE)
            ] =
              step.eventData.parentEvent.title;
          }
        });
      });
      if (sampleChanges[signatureCreatorTabData[currentSampleIndex].guid]) {
        Object.values(
          sampleChanges[signatureCreatorTabData[currentSampleIndex].guid]
        ).forEach(eventValue => {
          Object.entries(eventValue).forEach(
            ([subeventName, subeventValue]) => {
              scrubberMarks[subeventValue.end_frame_index] = {
                style: { visibility: "hidden" },
                label: subeventName
              };
            }
          );
        });
      }
    }

    const { sampleStats } = this.props;
    if (sampleStats) {
      if (
        currentSample &&
        currentSample["device_info"] &&
        sampleStats[currentSample["device_info"]] &&
        sampleStats[currentSample["device_info"]["product_model"]]
      ) {
        const deviceSpecificStats =
          sampleStats[currentSample["device_info"]["product_model"]];
        const currentTaggedEventName =
          stepsContent[currentSignatureStep].eventData["name"];
        if (
          deviceSpecificStats[currentTaggedEventName] &&
          deviceSpecificStats[currentTaggedEventName]["end_time"]
        ) {
          const specificEventStats =
            deviceSpecificStats[currentTaggedEventName]["end_time"];
          scrubberMarks[specificEventStats.end_frame_index_average] = (
            <div className="scrubber-avg-marker">
              <div className="dial" />
            </div>
          );
          scrubberMarks[
            specificEventStats.end_frame_index_average -
              Math.floor(specificEventStats.end_frame_index_std_dev / 2)
          ] = (
            <div
              className="scrubber-stddev-marker-before"
              subeventname={currentTaggedEventName}
            >
              <div className="dial" />
            </div>
          );
          scrubberMarks[
            specificEventStats.end_frame_index_average +
              Math.floor(specificEventStats.end_frame_index_std_dev / 2)
          ] = (
            <div
              className="scrubber-stddev-marker-after"
              subeventname={currentTaggedEventName}
            >
              <div className="dial" />
            </div>
          );
        }
        delete scrubberMarks["NaN"];
      }
    }
    // diff popover
    const tempFrame =
      this.state.scrubberFrame === 0 ? 0 : this.state.scrubberFrame - 1;
    const frames = `${tempFrame === 0 ? 0 : tempFrame - 1},${tempFrame}`;
    // console.log('hello', framesDiffBase64[frames]);

    return (
      <div className="performance-tab">
        <PerformanceToolbar
          history={this.props.history}
          appsList={appsList}
          loadingData={loadingData}
          allDeviceModels={deviceModels}
          numberOfSamples={signatureCreatorTabData.length}
          loadSignatureVideos={this.props.loadSignatureVideos}
          nonStrictMode={false}
          resetPageData={() =>
            this.props.loadSignatureVideosSuccess([], false, undefined)
          }
        />
        {(signatureCreatorTabData.length > 0 &&
          appsList.length > 0 &&
          typeof currentSampleIndex == "number" &&
          currentSample.events && (
            <div style={{ visibility: videoReady ? "visible" : "hidden" }}>
              <AnnotatorHeader
                user={this.state.user}
                currentSampleIndex={currentSampleIndex}
                currentSample={currentSample}
                dataLength={signatureCreatorTabData.length}
                setBadVideoForSampleAPI={this.props.setBadVideoForSampleAPI}
                verifyPendingSampleAPI={this.props.verifyPendingSampleAPI}
                nextSample={() => this.handleSaveAndNext({ nextOnly: true })}
              />
              <div className="signature-creator-content">
                <div className="signature-creator-content__left">
                  <div className="signature-creator-content__left-videos">
                    <div className="signature-creator-content__left-video-wrapper">
                      <div>
                        Frame Number:{" "}
                        <em>
                          {this.state.scrubberFrame === 0
                            ? 0
                            : this.state.scrubberFrame - 1}
                        </em>
                      </div>
                      <video
                        id="behind-video"
                        className="signature-creator-content__left-videos-behind"
                      >
                        <source
                          src={
                            signatureCreatorTabData.length > 0
                              ? currentSample.video_constant_fps
                              : ""
                          }
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                    <div className="signature-creator-content__left-video-wrapper">
                      <div>
                        Frame Number: <em>{this.state.scrubberFrame}</em>
                      </div>
                      <video
                        id="main-video"
                        className="signature-creator-content__left-videos-main"
                        preload="auto"
                      >
                        <source
                          src={
                            signatureCreatorTabData.length > 0
                              ? currentSample.video_constant_fps
                              : ""
                          }
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </div>
                  <div className="signature-creator-content__left-scrubber">
                    <Slider
                      className="scrubber"
                      ref={this.sliderRef}
                      value={scrubberFrame}
                      marks={scrubberMarks}
                      tooltipVisible
                      max={videoDurationInFrames}
                      step={1}
                      onChange={this.handleScrubberChange}
                      onFocus={() => this.setState({ scrubberInFocus: true })}
                      onBlur={() => this.setState({ scrubberInFocus: false })}
                      // onAfterChange={() => this.sliderRef.current.blur()}
                      tipFormatter={value =>
                        `Time: ${(value / VIDEO_FRAMERATE).toFixed(3)} seconds`
                      }
                    />
                  </div>
                </div>
                <div className="signature-creator-content__center">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "120px",
                      justifyContent: "space-between"
                    }}
                  >
                    <Popover
                      placement="right"
                      title="Frames Diff"
                      content={
                        <div>
                          {!framesDiffBase64[frames] && <Icon type="loading" />}
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              justifyContent: "center"
                            }}
                          >
                            {framesDiffBase64[frames] && (
                              <img
                                src={`data:image/png;base64,${
                                  framesDiffBase64[frames]
                                }`}
                                alt="diff"
                                style={{
                                  width: "350px",
                                  height: "700px",
                                  objectFit: "scale-down"
                                }}
                              />
                            )}
                            <Button
                              style={{ flex: "0 0 50%" }}
                              type="primary"
                              size="large"
                              onClick={() => {
                                this.fetchDiffImageForCurrentFrame(true);
                              }}
                            >
                              Re-fetch
                            </Button>
                            <Button
                              style={{ flex: "0 0 50%" }}
                              type="danger"
                              size="large"
                              onClick={() => {
                                this.setState({ framesDiffBase64: {} });
                              }}
                            >
                              Clean all diff imgs
                            </Button>
                          </div>
                        </div>
                      }
                      trigger="click"
                      onVisibleChange={visible => {
                        if (!visible) {
                          this.setState(prevState => ({
                            framesDiffBase64: { ...prevState.framesDiffBase64 },
                            frameDiffPopupVisible: false
                          }));
                        } else {
                          this.setState({ frameDiffPopupVisible: true });
                        }
                      }}
                    >
                      <Button
                        shape="circle"
                        type={framesDiffBase64[frames] ? "primary" : "default"}
                        icon="diff"
                        size="large"
                        onClick={() => {
                          this.fetchDiffImageForCurrentFrame();
                        }}
                      />
                    </Popover>
                    <Tooltip placement="top" title="Copy Frames">
                      <Button
                        shape="circle"
                        type="primary"
                        icon="double-right"
                        size="large"
                        onClick={() =>
                          this.captureVideoSnapshot(
                            currentSample.guid,
                            currentStep.eventData.parentEvent.name,
                            currentStep.eventData.name,
                            currentStep.eventData.title,
                            scrubberFrame
                          )
                        }
                      />
                    </Tooltip>
                  </div>
                </div>
                <div className="signature-creator-content__right">
                  <Button
                    shape="circle"
                    type="primary"
                    icon="up"
                    size="large"
                    onClick={this.handleSignaturePreviousStep}
                  />
                  <div className="signature-creator-content__right-steps">
                    <Steps current={currentSignatureStep}>
                      {stepsContent.map((step, index) => (
                        <Step
                          key={`${step.title}-${index}`}
                          title={step.title}
                        />
                      ))}
                    </Steps>
                    <div className="signature-creator-content__right-steps-step-content">
                      {stepsContent[currentSignatureStep]
                        ? stepsContent[currentSignatureStep].content
                        : null}
                    </div>
                  </div>
                  {/* <Button
                shape="circle"
                type="primary"
                icon="down"
                size="large"
                onClick={this.handleSignatureNextStep}
                /> */}
                </div>
              </div>
              {currentSignatureStep < stepsContent.length - 1 && (
                <Button
                  block
                  type="primary"
                  size="large"
                  style={{ marginTop: 10 }}
                  onClick={this.handleSignatureNextStep}
                  disabled={
                    !(
                      sampleChanges[currentSample.guid] &&
                      sampleChanges[currentSample.guid][
                        stepsContent[currentSignatureStep].eventData.parentEvent
                          .name
                      ] &&
                      sampleChanges[currentSample.guid][
                        stepsContent[currentSignatureStep].eventData.parentEvent
                          .name
                      ][stepsContent[currentSignatureStep].eventData.name] &&
                      sampleChanges[currentSample.guid][
                        stepsContent[currentSignatureStep].eventData.parentEvent
                          .name
                      ][stepsContent[currentSignatureStep].eventData.name]
                        .end_frame_index
                    )
                  }
                >
                  Next Event
                </Button>
              )}
              {currentSignatureStep === stepsContent.length - 1 && (
                <Button
                  block
                  type="primary"
                  icon="save"
                  size="large"
                  style={{ marginTop: 10 }}
                  onClick={this.handleSaveAndNext}
                  disabled={
                    !(
                      sampleChanges[currentSample.guid] &&
                      sampleChanges[currentSample.guid][
                        stepsContent[currentSignatureStep].eventData.parentEvent
                          .name
                      ] &&
                      sampleChanges[currentSample.guid][
                        stepsContent[currentSignatureStep].eventData.parentEvent
                          .name
                      ][stepsContent[currentSignatureStep].eventData.name] &&
                      sampleChanges[currentSample.guid][
                        stepsContent[currentSignatureStep].eventData.parentEvent
                          .name
                      ][stepsContent[currentSignatureStep].eventData.name]
                        .end_frame_index
                    )
                  }
                >
                  {signatureCreatorTabData.length > 1 &&
                  currentSampleIndex < signatureCreatorTabData.length - 1
                    ? "Save & Next Sample"
                    : "Save"}
                </Button>
              )}
            </div>
          )) ||
          (this.state.fetch && <Spin />)}
      </div>
    );
  }
}

const mapStateToProps = ({ performancePage }) => ({
  appsList: performancePage.appsList,
  deviceModels: performancePage.deviceModels,
  signatureCreatorTabData: performancePage.signatureCreatorTabData,
  sampleStats: performancePage.sampleStats,
  loadingData: performancePage.loadingData,
  updatingPerformance: performancePage.updatingPerformance
});

const mapDispatchToProps = {
  getAppsList,
  loadSignatureVideos,
  loadSignatureVideosSuccess,
  saveAnnotatedEvents,
  setBadVideoForSampleAPI,
  verifyPendingSampleAPI,
  getDeviceModels
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SignatureCreation)
);
