/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import Img from "react-image";
import { Radio, Button } from "antd";
import Files from "react-files";

const METADATA_FIELDS = [
  "guid",
  "asl_version",
  "device_id",
  "package_name",
  "version_code",
  "started_at",
  "timezone",
  "document_version",
  "signature_name",
  "ts_s",
  "recording_files"
];

class SignatureValidate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      jsonFile: [],
      showResults: false,
      sampleChanges: {},
      currentFilename: ""
    };

    this.fileReader = new FileReader();
    this.fileReader.onload = event => {
      // console.log(event.target);

      this.setState(
        {
          jsonFile: JSON.parse(event.target.result),
          showResults: false,
          sampleChanges: {}
        },
        () => {
          // console.log(this.state.jsonFile);
        }
      );
    };
  }

  handleSampleManualVerification = (guid, signatureName, oldVal, newVal) => {
    this.setState(prevState => {
      const prevSampleChanges = { ...prevState.sampleChanges };
      if (!prevSampleChanges[signatureName]) {
        prevSampleChanges[signatureName] = {};
      }
      prevSampleChanges[signatureName][guid] = { oldVal, newVal };
      return { sampleChanges: prevSampleChanges };
    });
  };

  toggleResultsView = () => {
    const finalSampleChanges = { ...this.state.sampleChanges };
    const preparedData = this.prepareData(
      this.state.jsonFile.map(object => object && object.dry_run)
    );
    const originalPreparedData = this.prepareData(
      this.state.jsonFile.map(object => object && object.orig)
    );
    console.log(preparedData, originalPreparedData);
    Object.entries(originalPreparedData).forEach(
      ([eventName, signatureSamplesObject]) => {
        Object.entries(signatureSamplesObject).forEach(
          ([signatureName, signatureSamplesData]) => {
            signatureSamplesData.forEach(signatureData => {
              if (
                preparedData[eventName] &&
                preparedData[eventName][signatureName]
              ) {
                if (!finalSampleChanges[signatureName]) {
                  finalSampleChanges[signatureName] = {};
                }
                if (
                  finalSampleChanges[signatureName][signatureData.guid] ===
                  undefined
                ) {
                  finalSampleChanges[signatureName][signatureData.guid] = {
                    oldVal: signatureData.manual_verification.toString(),
                    newVal: signatureData.manual_verification.toString()
                  };
                }
              }
            });
          }
        );
      }
    );

    // this.state.jsonFile.map(object => object && object.orig).forEach((dataSample) => {
    //   dataSample.events.forEach((event) => {
    //     event.subevents.forEach((subevent) => {
    //       if (!finalSampleChanges[subevent.signature_name]) {
    //         finalSampleChanges[subevent.signature_name] = {};
    //       }
    //       if (finalSampleChanges[subevent.signature_name][dataSample.guid] === undefined) {
    //         finalSampleChanges[subevent.signature_name][dataSample.guid] = {
    //           oldVal: subevent.manual_verification.toString(),
    //           newVal: subevent.manual_verification.toString(),
    //         };
    //       }
    //     });
    //   });
    // });
    this.setState(
      prevState => ({
        showResults: !prevState.showResults,
        sampleChanges: finalSampleChanges
      }),
      () => {
        console.log(this.state);
      }
    );
  };

  prepareData = data => {
    const manipulatedEventsData = {};
    data.forEach(dataSample => {
      dataSample.events.forEach(event => {
        if (!manipulatedEventsData[event.name]) {
          manipulatedEventsData[event.name] = {};
        }

        const sampleMetdata = {};
        METADATA_FIELDS.forEach(metadataField => {
          if (dataSample[metadataField]) {
            sampleMetdata[metadataField] = dataSample[metadataField];
            if (metadataField === "recording_files") {
              sampleMetdata[metadataField] = dataSample.recording_files.video;
            }
          }
        });
        event.subevents.forEach(subevent => {
          if (!manipulatedEventsData[event.name][subevent.signature_name]) {
            manipulatedEventsData[event.name][subevent.signature_name] = [];
          }
          manipulatedEventsData[event.name][subevent.signature_name].push({
            ...subevent,
            ...sampleMetdata,
            dataSampleDocID: dataSample._id
          });
        });
      });
    });
    return manipulatedEventsData;
  };

  render() {
    const {
      appsList,
      validateTabData,
      loadingData,
      updatingPerformance
    } = this.props;
    const preparedData = this.prepareData(
      this.state.jsonFile.map(object => object && object.dry_run)
    );
    const originalPreparedData = this.prepareData(
      this.state.jsonFile.map(object => object && object.orig)
    );
    console.log(originalPreparedData, preparedData);
    if (this.state.showResults) {
      return (
        <div className="performance-tab">
          <h1>Results Summary</h1>
          {Object.entries(this.state.sampleChanges).map(
            ([signatureName, signatureData]) => {
              let wasTrue = 0;
              let nowTrue = 0;
              let wasFalse = 0;
              let nowFalse = 0;
              let wasFalseNowtrue = 0;
              Object.entries(signatureData).forEach(([guid, dataValues]) => {
                dataValues.oldVal === "true" ? (wasTrue += 1) : (wasFalse += 1);
                dataValues.newVal === "true" ? (nowTrue += 1) : (nowFalse += 1);
                if (
                  dataValues.oldVal === "false" &&
                  dataValues.newVal === "true"
                ) {
                  wasFalseNowtrue += 1;
                }
              });
              return (
                <div>
                  <h1>{`Signature Name: ${signatureName}`}</h1>
                  <h3>
                    {`true -> true: ${
                      isNaN(nowTrue / wasTrue) ? 0 : (nowTrue / wasTrue) * 100
                    }%`}
                  </h3>
                  <h3>
                    {`false -> true: ${
                      isNaN(wasFalseNowtrue / wasFalse)
                        ? 0
                        : (wasFalseNowtrue / wasFalse) * 100
                    }%`}
                  </h3>
                </div>
              );
            }
          )}
          <Button onClick={this.toggleResultsView}>Back to the data</Button>
        </div>
      );
    }
    return (
      <div className="performance-tab">
        <div className="validate-controls">
          <Files
            style={{ cursor: "pointer" }}
            className="files-dropzone control-element"
            onChange={file => {
              // console.log(file);
              this.setState({ currentFilename: file[0].name });
              this.fileReader.readAsText(file[0]);
            }}
            onError={err => console.log(err)}
            accepts={[".json"]}
            // multiple
            maxFiles={1}
            maxFileSize={10000000}
            minFileSize={0}
            clickable
          >
            <span>
              {this.state.currentFilename !== ""
                ? `Opened filename: '${
                    this.state.currentFilename
                  }', drop file here or click to open`
                : "Drop file here or click to open"}
            </span>
          </Files>
        </div>
        {/* The actual data display is after this line */}
        {Object.entries(preparedData).map(([eventKey, eventData]) => (
          <div
            key={eventKey}
            className={`event-section ${loadingData ? "loading" : ""}`}
          >
            {Object.entries(eventData).map(([subEventKey, subEventData]) => (
              <div key={subEventKey} className="subevent-section">
                <h1 className="event-name">{`${eventKey}: ${subEventKey}`}</h1>
                {subEventData.map((subevent, subeventIndex) => (
                  <div
                    key={`${subeventIndex}_${subevent.current_index}`}
                    className="event-card"
                  >
                    <h3 className="event-card-title">
                      Original manual verification was:{" "}
                      {originalPreparedData[eventKey][subEventKey][
                        subeventIndex
                      ].manual_verification.toString()}
                    </h3>
                    <div className="event-card-content">
                      <div className="event-card-images">
                        <div className="images-row">
                          <h3>XOR:</h3>
                          {subevent.xor_frames_url.map(xorImgURL => (
                            <Img key={xorImgURL} src={xorImgURL} />
                          ))}
                        </div>
                        <div className="images-row">
                          <h3>Frames:</h3>
                          {subevent.before_frames_url.map(beforeImgURL => (
                            <Img key={beforeImgURL} src={beforeImgURL} />
                          ))}
                          <Img
                            className="image-frame"
                            src={subevent.frame_url}
                          />
                          {subevent.after_frames_url.map(afterImgURL => (
                            <Img key={afterImgURL} src={afterImgURL} />
                          ))}
                        </div>
                      </div>
                      <div className="event-card-metadata">
                        <Img src={subevent.mean_graph_url} />
                        {METADATA_FIELDS.map(metadataField => (
                          <h3 key={metadataField}>
                            {metadataField === "recording_files" ? (
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={subevent[metadataField]}
                              >
                                Download Video
                              </a>
                            ) : (
                              `${metadataField}: ${subevent[metadataField]}`
                            )}
                          </h3>
                        ))}
                        <Radio.Group
                          // Sorry about those nested ternary expression, it's not that complicated...
                          defaultValue={
                            this.state.sampleChanges[subEventKey] &&
                            this.state.sampleChanges[subEventKey][
                              subevent.guid
                            ] &&
                            this.state.sampleChanges[subEventKey][subevent.guid]
                              .newVal
                              ? this.state.sampleChanges[subEventKey][
                                  subevent.guid
                                ].newVal === "true"
                                ? 1
                                : 0
                              : originalPreparedData[eventKey][subEventKey][
                                  subeventIndex
                                ].manual_verification.toString() === "null"
                                ? 2
                                : originalPreparedData[eventKey][subEventKey][
                                    subeventIndex
                                  ].manual_verification
                                  ? 1
                                  : 0
                          }
                          buttonStyle="solid"
                          size="large"
                          onChange={e => {
                            let newVal = "";
                            if (e.target.value === 0) {
                              newVal = "false";
                            } else if (e.target.value === 1) {
                              newVal = "true";
                            } else {
                              newVal = "null";
                            }
                            this.handleSampleManualVerification(
                              subevent.guid,
                              subevent.signature_name,
                              originalPreparedData[eventKey][subEventKey][
                                subeventIndex
                              ].manual_verification.toString(),
                              newVal
                            );
                          }}
                        >
                          <Radio.Button value={1}>True</Radio.Button>
                          <Radio.Button value={0}>False</Radio.Button>
                        </Radio.Group>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        ))}
        <Button
          className="control-element"
          onClick={this.toggleResultsView}
          size="large"
          type="primary"
        >
          Results
        </Button>
        <Button
          className="control-element"
          onClick={() => this.setState({ jsonFile: [] })}
          size="large"
          type="danger"
        >
          Clear Data
        </Button>
      </div>
    );
  }
}

export default SignatureValidate;
