/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { Stage, Layer, Rect, Transformer } from "react-konva";
import {
  Slider,
  Button,
  Input,
  Divider,
  Drawer,
  Icon,
  Badge,
  Checkbox,
  Popconfirm,
  Spin
} from "antd";
import { v4 as UUID } from "uuid";
import RepresentativeVideoRectTable from "./RepresentativeVideoRectTable";
import {
  getAnonymizedSample,
  resetAnonymizedSample,
  anonymizeVideo,
  setSampleRepresentativeState,
  getAnonymizationQueue
} from "../../../redux/actions/performancePage";

const Rectangle = ({
  shapeProps,
  isSelected,
  onSelect,
  onChange,
  videoWidth,
  videoHeight
}) => {
  const shapeRef = React.useRef();
  const trRef = React.useRef();

  useEffect(
    () => {
      if (isSelected) {
        // we need to attach transformer manually
        trRef.current.setNode(shapeRef.current);
        trRef.current.getLayer().batchDraw();
      }
    },
    [isSelected]
  );

  return (
    <React.Fragment>
      <Rect
        onClick={onSelect}
        ref={shapeRef}
        {...shapeProps}
        draggable
        onDragEnd={e => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y()
          });
        }}
        onTransformEnd={e => {
          // transformer is changing scale
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          // we will reset it back
          node.scaleX(1);
          node.scaleY(1);
          onChange({
            ...shapeProps,
            x: node.x(),
            y: node.y(),
            width: node.width() * scaleX,
            height: node.height() * scaleY
          });
        }}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          rotateEnabled={false}
          keepRatio={false}
          boundBoxFunc={(oldBoundBox, newBoundBox) => {
            if (
              Math.abs(newBoundBox.x + newBoundBox.width) > videoWidth ||
              newBoundBox.x + newBoundBox.width < 0 ||
              newBoundBox.x < 0
            ) {
              return oldBoundBox;
            }
            if (
              Math.abs(newBoundBox.y + newBoundBox.height) > videoHeight ||
              newBoundBox.y + newBoundBox.height < 0 ||
              newBoundBox.y < 0
            ) {
              return oldBoundBox;
            }
            return newBoundBox;
          }}
        />
      )}
    </React.Fragment>
  );
};

const TEST_VIDEO_URL =
  "https://watchful-app-record.s3.amazonaws.com/com.google.android.youtube/2019/9/d6bb2d8f-48eb-428b-baa1-1c65e467e269/ebd024fb53874224b19842b1e02b4e89_constant.mp4";

const VIDEO_FPS = 30;

const rectObject = currentFrame => ({
  x: 10,
  y: 10,
  width: 100,
  height: 100,
  stroke: "blue",
  strokeWidth: 5,
  name: UUID(),
  startFrame: currentFrame,
  endFrame: currentFrame
});

function RepresentativeVideo({
  anonymizedSample,
  getAnonymizedSample,
  anonymizedSamplesStatus,
  anonymizeVideo,
  resetAnonymizedSample,
  setSampleRepresentativeState,
  getAnonymizationQueue
}) {
  const [videoWidth, setVideoWidth] = useState(0);
  const [videoHeight, setVideoHeight] = useState(0);
  const [videoRef, setVideoRef] = useState(null);
  const [currentVideoFrame, setCurrentVideoFrame] = useState(0);
  const [videoLengthInFrames, setVideoLengthInFrames] = useState(0);
  const konvaLayerRef = React.createRef();
  const [sampleRects, setSampleRects] = useState([]);
  const [selectedRect, setSelectedRect] = useState(null);
  const [currentSampleGuid, setCurrentSampleGuid] = useState(null);
  const [isSampleRepresentative, setIsSampleRepresentative] = useState(false);
  const [queueDrawerOpen, setQueueDrawerOpen] = useState(false);
  useEffect(
    () => {
      if (anonymizedSample) {
        setIsSampleRepresentative(anonymizedSample.is_representative);
        setCurrentSampleGuid(anonymizedSample.guid);
      }
    },
    [anonymizedSample]
  );
  useEffect(() => {
    const video = document.getElementById("repr-vid");
    if (video) {
      setVideoRef(video);
      video.onloadedmetadata = () => {
        if (video.duration > 0 && (videoHeight === 0 || videoWidth === 0)) {
          setVideoWidth(video.offsetWidth);
          setVideoHeight(video.offsetHeight);
          setVideoLengthInFrames(Math.floor(video.duration * VIDEO_FPS));
          if (konvaLayerRef && konvaLayerRef.current) {
            konvaLayerRef.current.batchDraw();
          }
        }
      };
    }
  });

  const cancel = () => {
    setVideoWidth(0);
    setVideoHeight(0);
    setVideoRef(null);
    setCurrentVideoFrame(0);
    setVideoLengthInFrames(0);
    setSampleRects([]);
    setSelectedRect(null);
    setCurrentSampleGuid(null);
    resetAnonymizedSample();
  };

  const handleCurrentVideoFrameChange = videoFrame => {
    setCurrentVideoFrame(videoFrame);
    setSelectedRect(null);
    if (videoRef) {
      videoRef.currentTime = videoFrame / VIDEO_FPS;
    }
  };

  const prepareRectForBE = rect => {
    const rectCopy = { ...rect };
    rectCopy.start = rectCopy.startFrame / VIDEO_FPS;
    rectCopy.end = rectCopy.endFrame / VIDEO_FPS;
    delete rectCopy.startFrame;
    delete rectCopy.endFrame;
    // eslint-disable-next-line operator-assignment
    rectCopy.x = rectCopy.x / (videoWidth / videoRef.videoWidth);
    // eslint-disable-next-line operator-assignment
    rectCopy.y = rectCopy.y / (videoHeight / videoRef.videoHeight);
    rectCopy.w = rectCopy.width / (videoWidth / videoRef.videoWidth);
    rectCopy.h = rectCopy.height / (videoHeight / videoRef.videoHeight);
    delete rectCopy.width;
    delete rectCopy.height;

    if (rectCopy.x < 0) {
      rectCopy.x = 0;
    }

    if (rectCopy.x < 0) {
      rectCopy.x = 0;
    }

    return rectCopy;
  };

  const prepareReceivedBlurRect = rect => {
    const rectCopy = { ...rect };
    rectCopy.startFrame = rect.start * VIDEO_FPS;
    rectCopy.endFrame = rect.end * VIDEO_FPS;
    delete rectCopy.start;
    delete rectCopy.end;
    // eslint-disable-next-line operator-assignment
    rectCopy.x = rectCopy.x * (videoWidth / videoRef.videoWidth);
    // eslint-disable-next-line operator-assignment
    rectCopy.y = rectCopy.y * (videoHeight / videoRef.videoHeight);
    rectCopy.width = rectCopy.w * (videoWidth / videoRef.videoWidth);
    rectCopy.height = rectCopy.h * (videoHeight / videoRef.videoHeight);
    delete rectCopy.w;
    delete rectCopy.h;
    return rectCopy;
  };

  const renderQueueItem = queueItem => {
    let icon = <Icon type="file" />;
    switch (queueItem.state) {
      case "pending":
        icon = <Icon type="file" />;
        break;

      case "in_progress":
        icon = <Icon type="loading" />;
        break;
      case "done":
        icon = (
          <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
        );
        break;
      case "error":
        icon = <Icon type="warning" />;
        break;
      default:
        break;
    }
    return (
      <p>
        <span>
          {icon} {queueItem.state_text} <br />
          {queueItem.state === "done" ? (
            <a href={queueItem.res} target="_blank" rel="noopener noreferrer">
              {queueItem.guid}
            </a>
          ) : (
            queueItem.guid
          )}
        </span>
      </p>
    );
  };

  useEffect(
    () => {
      if (
        videoRef &&
        isEmpty(sampleRects) &&
        videoHeight !== 0 &&
        videoWidth !== 0 &&
        anonymizedSample &&
        anonymizedSample.blur_rects &&
        !isEmpty(anonymizedSample.blur_rects)
      ) {
        // x and y values that return from the BE are normalized, de normalize them so they will show up in the correct spot
        setSampleRects(
          anonymizedSample.blur_rects.map(rect => prepareReceivedBlurRect(rect))
        );
      }
    },
    [anonymizedSample, videoRef, videoHeight, videoWidth]
  );

  const handleRectChange = newAttributes => {
    setSampleRects(
      sampleRects.map(rect => {
        if (rect.name === newAttributes.name) {
          rect = newAttributes;
        }
        return rect;
      })
    );
  };

  if (!anonymizedSample || !anonymizedSample.video) {
    return (
      <div>
        <Input
          placeholder="Enter sample guid"
          value={currentSampleGuid}
          onChange={e => setCurrentSampleGuid(e.target.value)}
        />
        <Button
          onClick={() => {
            getAnonymizedSample(currentSampleGuid);
            console.log(`fetch sample with guid${currentSampleGuid}`);
          }}
        >
          Fetch Sample!
        </Button>
        {/* <Badge
          count={Object.values(anonymizedSamplesStatus || {}).reduce((acc, curr) => {
            if (curr !== 0) {
              acc += 1;
            }
            return acc;
          }, 0)}
        >
        </Badge> */}
        <Button
          onClick={() => {
            getAnonymizationQueue();
            setQueueDrawerOpen(true);
          }}
        >
          Queue
        </Button>
        <Drawer
          width={350}
          title={
            <div>
              Anonymization Queue{" "}
              <Button icon="sync" onClick={() => getAnonymizationQueue()} />
            </div>
          }
          placement="right"
          closable
          onClose={() => setQueueDrawerOpen(false)}
          visible={queueDrawerOpen}
        >
          {!anonymizedSamplesStatus && <Spin />}
          {anonymizedSamplesStatus &&
            Object.values(anonymizedSamplesStatus).map(sample =>
              renderQueueItem(sample)
            )}
        </Drawer>
      </div>
    );
  }
  return (
    <div className="representative-video-tab">
      <div className="representative-video-tab__current-frame">
        {currentVideoFrame} / {videoLengthInFrames}
      </div>
      <Button
        onClick={() =>
          setSampleRects([...sampleRects, rectObject(currentVideoFrame)])
        }
      >
        Add A Rect
      </Button>
      <Divider type="vertical" />
      <Button
        disabled={isEmpty(anonymizedSample.guid) || isEmpty(sampleRects)}
        onClick={() => {
          anonymizeVideo(
            anonymizedSample.guid,
            sampleRects.map(rect => prepareRectForBE(rect))
          );
          cancel();
        }}
      >
        Anonymize Video!
      </Button>
      <Divider type="vertical" />
      <Checkbox
        checked={isSampleRepresentative}
        onChange={e => {
          setIsSampleRepresentative(e.target.checked);
          setSampleRepresentativeState(currentSampleGuid, e.target.checked);
        }}
      >
        Representative Sample?
      </Checkbox>
      <Divider type="vertical" />
      <Popconfirm title="Are you sure?" onConfirm={cancel}>
        <Button type="danger">Cancel</Button>
      </Popconfirm>
      <Divider type="vertical" />
      <a
        disabled={!anonymizedSample.anonymized_video}
        href={anonymizedSample.anonymized_video}
        target="blank"
        rel="noopener noreferrer"
      >
        Anonymized Video
      </a>
      <div className="representative-video-tab__canvas-and-table">
        <div className="representative-video-tab__canvas-wrapper">
          <Stage
            width={videoWidth}
            height={videoHeight}
            className="representative-video-tab__canvas"
          >
            <Layer ref={konvaLayerRef}>
              <Rect
                x={0}
                y={0}
                width={videoWidth}
                height={videoHeight}
                fill="transparent"
                onClick={() => setSelectedRect(null)}
              />
              {sampleRects
                .filter(
                  rect =>
                    currentVideoFrame >= rect.startFrame &&
                    currentVideoFrame <= rect.endFrame
                )
                .map((rect, i) => (
                  <Rectangle
                    key={rect.name}
                    videoHeight={videoHeight}
                    videoWidth={videoWidth}
                    shapeProps={{
                      ...rect,
                      dragBoundFunc: pos => {
                        const normalizedPosition = { ...pos };
                        if (pos.x + rect.width >= videoWidth) {
                          normalizedPosition.x = videoWidth - rect.width;
                        } else if (pos.x <= 0) {
                          normalizedPosition.x = 0;
                        }

                        if (pos.y + rect.height >= videoHeight) {
                          normalizedPosition.y = videoHeight - rect.height;
                        } else if (pos.y <= 0) {
                          normalizedPosition.y = 0;
                        }
                        return normalizedPosition;
                      }
                    }}
                    isSelected={rect.name === selectedRect}
                    onSelect={() => {
                      setSelectedRect(rect.name);
                    }}
                    onChange={handleRectChange}
                  />
                ))}
            </Layer>
          </Stage>
          <video
            id="repr-vid"
            className="representative-video-tab__video"
            preload="auto"
          >
            <source src={anonymizedSample.video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <RepresentativeVideoRectTable
          rects={sampleRects}
          videoHeight={videoHeight}
          selectedRect={selectedRect}
          setCurrentVideoFrame={handleCurrentVideoFrameChange}
          setSelectedRect={setSelectedRect}
          setRectStartFrame={rectName =>
            setSampleRects(
              sampleRects.map(rect => {
                if (rect.name === rectName) {
                  rect.startFrame = currentVideoFrame;
                }
                return rect;
              })
            )
          }
          setRectEndFrame={rectName =>
            setSampleRects(
              sampleRects.map(rect => {
                if (rect.name === rectName) {
                  rect.endFrame = currentVideoFrame;
                }
                return rect;
              })
            )
          }
          removeRect={rectName =>
            setSampleRects(sampleRects.filter(rect => rect.name !== rectName))
          }
        />
      </div>
      <Slider
        value={currentVideoFrame}
        max={videoLengthInFrames}
        onChange={value => {
          handleCurrentVideoFrameChange(value);
        }}
      />
    </div>
  );
}

const mapStateToProps = ({ performancePage }) => ({
  anonymizedSample: performancePage.anonymizedSample,
  anonymizedSamplesStatus: performancePage.anonymizedSamplesStatus
});

const mapDispatchToProps = {
  getAnonymizedSample,
  resetAnonymizedSample,
  anonymizeVideo,
  setSampleRepresentativeState,
  getAnonymizationQueue
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RepresentativeVideo);
