/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { connect } from "react-redux";
import Img from "react-image";
import { Select, Button, InputNumber, Checkbox } from "antd";
import {
  getAppsList,
  loadPerformance,
  updatePerformance
} from "../../../redux/actions/performancePage";

const { Option } = Select;
const METADATA_FIELDS = [
  "guid",
  "asl_version",
  "device_id",
  "package_name",
  "version_code",
  "started_at",
  "timezone",
  "document_version",
  "signature_name",
  "ts_s",
  "manual_verification",
  "recording_files"
];

class Errors extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectValue: undefined,
      resultsLimit: 5, // This is also the default number,
      sampleChanges: {}
    };
  }

  componentDidMount() {
    this.props.getAppsList();
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentVisibleTab !== prevProps.currentVisibleTab) {
      // Tab change reset state
      this.setState({
        selectValue: undefined,
        resultsLimit: 5, // This is also the default number,
        sampleChanges: {}
      });
    }
  }

  onSelectChange = value => {
    this.setState({ selectValue: value });
  };

  onResultsLimitChange = value => {
    this.setState({ resultsLimit: value });
  };

  handleSampleManualVerification = (e, dataSampleDocID, signatureName) => {
    // console.log(e.target.value, dataSampleDocID, signatureName);
    this.setState(prevState => {
      const prevSampleChanges = { ...prevState.sampleChanges };
      if (!prevSampleChanges[dataSampleDocID]) {
        prevSampleChanges[dataSampleDocID] = {};
      }
      prevSampleChanges[dataSampleDocID][signatureName] = e.target.value;
      return { sampleChanges: prevSampleChanges };
    });
  };

  fetchData = () => {
    this.props.loadPerformance({
      app: this.state.selectValue,
      limit: this.state.resultsLimit,
      error: true
    });
  };

  prepareData = data => {
    const manipulatedEventsData = {};
    data.forEach(dataSample => {
      dataSample.events.forEach(event => {
        if (!manipulatedEventsData[event.name]) {
          manipulatedEventsData[event.name] = {};
        }

        const sampleMetdata = {};
        METADATA_FIELDS.forEach(metadataField => {
          if (dataSample[metadataField]) {
            sampleMetdata[metadataField] = dataSample[metadataField];
            if (metadataField === "recording_files") {
              sampleMetdata[metadataField] = dataSample.recording_files.video;
            }
          }
        });
        event.subevents.forEach(subevent => {
          if (!manipulatedEventsData[event.name][subevent.signature_name]) {
            manipulatedEventsData[event.name][subevent.signature_name] = [];
          }
          manipulatedEventsData[event.name][subevent.signature_name].push({
            ...subevent,
            ...sampleMetdata,
            dataSampleDocID: dataSample._id
          });
        });
      });
    });
    return manipulatedEventsData;
  };

  render() {
    const {
      appsList,
      errorsTabData,
      loadingData,
      updatingPerformance
    } = this.props;
    const preparedData = this.prepareData(errorsTabData);
    console.log(preparedData);

    return (
      <div className="performance-tab">
        <div className="validate-controls">
          <Select
            className="control-element"
            showSearch
            style={{ width: 200 }}
            placeholder="Select an app"
            value={this.state.selectValue}
            optionFilterProp="children"
            onChange={this.onSelectChange}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {appsList.map(app => (
              <Option value={app.package} key={app.name}>
                {app.name}
              </Option>
            ))}
          </Select>
          <InputNumber
            className="control-element"
            min={1}
            value={this.state.resultsLimit}
            onChange={this.onResultsLimitChange}
          />
          <Button
            disabled={!this.state.selectValue}
            className="control-element"
            onClick={this.fetchData}
            loading={loadingData}
          >
            Fetch Data
          </Button>
        </div>
        {/* The actual data display is after this line */}
        {Object.entries(preparedData).map(([eventKey, eventData]) => (
          <div
            key={eventKey}
            className={`event-section ${loadingData ? "loading" : ""}`}
          >
            {Object.entries(eventData).map(([subEventKey, subEventData]) => (
              // debugger;
              <div key={subEventKey} className="subevent-section">
                <h1 className="event-name">
                  {`${eventKey}: ${subEventKey} (${subEventData[0].false_percentage.toFixed(
                    3
                  )}% false out of sig total)`}
                </h1>
                {subEventData.map((subevent, subeventIndex) => (
                  <div
                    key={`${subeventIndex}_${subevent.current_index}`}
                    className="event-card"
                  >
                    <h3 className="event-card-title">
                      Data sample doc id: {subevent.dataSampleDocID}
                    </h3>
                    <h3 className="event-card-title">
                      Manual Verification: {subevent.manual_verification}
                    </h3>
                    <div className="event-card-content">
                      <div className="event-card-images">
                        <div className="images-row">
                          <h3>XOR:</h3>
                          {subevent.xor_frames_url.map(xorImgURL => (
                            <Img key={xorImgURL} src={xorImgURL} />
                          ))}
                        </div>
                        <div className="images-row">
                          <h3>Frames:</h3>
                          {subevent.before_frames_url.map(beforeImgURL => (
                            <Img key={beforeImgURL} src={beforeImgURL} />
                          ))}
                          <Img
                            className="image-frame"
                            src={subevent.frame_url}
                          />
                          {subevent.after_frames_url.map(afterImgURL => (
                            <Img key={afterImgURL} src={afterImgURL} />
                          ))}
                        </div>
                      </div>
                      <div className="event-card-metadata">
                        <Img src={subevent.mean_graph_url} />
                        {METADATA_FIELDS.map(metadataField => (
                          <h3 key={metadataField}>
                            {metadataField === "recording_files" ? (
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={subevent[metadataField]}
                              >
                                Download Video
                              </a>
                            ) : (
                              `${metadataField}: ${subevent[metadataField]}`
                            )}
                          </h3>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  }
}

const mapStateToProps = ({ performancePage }) => ({
  appsList: performancePage.appsList,
  errorsTabData: performancePage.errorsTabData,
  loadingData: performancePage.loadingData,
  updatingPerformance: performancePage.updatingPerformance
});

const mapDispatchToProps = {
  getAppsList,
  loadPerformance,
  updatePerformance
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Errors);
