/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Tabs } from "antd";
import SignatureValidateTab from "./SignatureValidateTab/SignatureValidate";
import ValidateTab from "./ValidateTab/Validate";
import ErrorsTab from "./ErrorsTab/Errors";
import ContentDataGatewayTab from "./contentDataGatewayTab/ContentDataGateway";
import SignatureCreationTab from "./signatureCreationTab/SignatureCreation";
import NonStrictAnnotator from "./nonStrictAnnotator/NonStrictAnnotator";
import SequentialEventsAnnotator from "./SequentialEventsAnnotator/SequentialEventsAnnotator";
import RepresentativeVideo from "./representativeVideoTab/RepresentativeVideo";
import AutotagStatusTab from "./autotagStatusTab/AutotagStatus";
import AnnotationQuotasTab from "./annotationQuotasTab/AnnotationQuotas";
import AnnotationsGraphTab from "./annotationsGraph/AnnotationsGraph";
import Auth from "@aws-amplify/auth";
import {
  loadPerformanceSuccess,
  getAnnotationQuotas
} from "../../redux/actions/performancePage";
import { Route, Switch, withRouter } from "react-router";

const { TabPane } = Tabs;
const l = console.log;

class Performance extends Component {
  constructor(props) {
    super(props);
    this.state = { userGroups: [] };
    let curvistab = this.props.match.params.tab;
    if (!curvistab) {
      curvistab = "annotator";
    }
    l("Performance.constructor", this.props.match);
  }
  componentDidMount() {
    Auth.currentAuthenticatedUser().then(user => {
      var cg = user.signInUserSession.idToken.payload["cognito:groups"];
      this.setState({ userGroups: cg });
    });
  }

  callback = key => {
    l("Performance.callback(", key, ")");
    this.props.history.push(`/performance/${key}`);
  };
  tabsRender(match, history) {
    const amAdmin = this.state.userGroups.includes("Admin");
    return (
      <Switch>
        <Tabs
          destroyInactiveTabPane // unmounts each tab when moving between tabs
          activeKey={this.props.match.params.tab}
          onChange={this.callback}
          animated={false}
          className="performance-tabs"
        >
          <TabPane tab="Quotas" key="quotas">
            <AnnotationQuotasTab />
          </TabPane>
          <TabPane tab="Non Strict Events Annotator" key="nonstrict-annotator">
            <NonStrictAnnotator />
          </TabPane>
          <TabPane
            tab="Sequential Events Annotator"
            key="sequential-annotator"
            disabled
          >
            <SequentialEventsAnnotator />
          </TabPane>
          <TabPane tab="Representative Video" key="representative-video">
            <RepresentativeVideo />
          </TabPane>
          <TabPane tab="Events Annotator" key="annotator" disabled>
            <SignatureCreationTab />
          </TabPane>
          <TabPane
            tab="Automatic Signature Validator"
            key="auto-sig-validator"
            disabled
          >
            <ValidateTab />
          </TabPane>
          <TabPane
            tab="Manual Signature Validator"
            key="man-sig-validator"
            disabled
          >
            <SignatureValidateTab />
          </TabPane>
          <TabPane tab="Errors" key="errors" disabled>
            <ErrorsTab />
          </TabPane>
          <TabPane tab="Content Data Gateway" key="content-data-gateway">
            <ContentDataGatewayTab />
          </TabPane>

          {false &&
            amAdmin && (
              <TabPane tab="Graphs" key="graphs">
                <AnnotationsGraphTab />
              </TabPane>
            )}

          {false &&
            amAdmin && (
              <TabPane tab="Autotag Status" key="auto-tag">
                <AutotagStatusTab />
              </TabPane>
            )}
        </Tabs>
      </Switch>
    );
  }
  render() {
    return <Route path="/performance/" render={this.tabsRender.bind(this)} />;
  }
}

const mapDispatchToProps = {
  loadPerformanceSuccess,
  getAnnotationQuotas
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(Performance)
);
