import React, { useEffect, useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import {
  getContentDataFiles,
  getContentDataLink
} from "../../../redux/actions/contentDataGateway";
import FilesTable from "./FilesTable";
import { Alert, Select } from "antd";

const { Option } = Select;

const ContentDataGateway = ({
  isLoading,
  error,
  files,
  getContentDataFiles,
  getContentDataLink
}) => {
  const [currentDir, setCurrentDir] = useState("");
  const [curMonth, setCurMonth] = useState(moment().format("YYYY-MM"));
  const lastMonths = Object.fromEntries(
    [
      moment(),
      moment().subtract(1, "months"),
      moment().subtract(2, "months")
    ].map(x => [x.format("YYYY-MM"), x.format("MMMM, YYYY")])
  );

  const getBackwardPath = dir =>
    dir.substring(
      0,
      dir.substring(0, dir.lastIndexOf("/")).lastIndexOf("/") + 1
    );

  useEffect(() => {
    getContentDataFiles(curMonth);
  }, []);

  const handleChangeCurMonth = value => {
    setCurMonth(value);
    setCurrentDir("");
    getContentDataFiles(value);
  };

  const handleChangeDir = dir => {
    const newDir = dir === ".." ? getBackwardPath(currentDir) : dir;
    setCurrentDir(newDir);
    getContentDataFiles(newDir || curMonth);
  };

  return (
    <div className="performance-tab">
      {error && (
        <Alert
          message="API response error"
          description={error}
          type="error"
          showIcon
        />
      )}
      <h3>Current dir /{currentDir}</h3>
      <div>
        <Select
          value={curMonth}
          style={{ width: "10rem" }}
          onChange={handleChangeCurMonth}
        >
          {Object.entries(lastMonths).map(x => (
            <Option key={x[0]}>{x[1]}</Option>
          ))}
        </Select>
      </div>
      <FilesTable
        isLoading={isLoading}
        files={[
          ...(currentDir
            ? [
                {
                  name: "..",
                  key: "..",
                  type: "dir"
                }
              ]
            : []),
          ...files
        ]}
        onChangeFolder={handleChangeDir}
        onGetDownloadLink={getContentDataLink}
      />
    </div>
  );
};

const mapStateToProps = ({ contentDataGateway }) => ({
  isLoading: contentDataGateway.isLoading,
  error: contentDataGateway.error,
  files: contentDataGateway.files
});

const mapDispatchToProps = {
  getContentDataFiles,
  getContentDataLink
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentDataGateway);
