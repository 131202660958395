import { Spin } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getAnnotationQuotas,
  getAnnotationQuotasSuccess,
  getAutoTagStatus,
  getAutoTagStatusSuccess,
  getAppsList,
  getEvents,
  getEventsSuccess,
  getDeviceModels
} from "../../../redux/actions/performancePage";
import { LOCATIONS } from "../../../constants/annotationConsts";
import isEqual from "lodash/isEqual";
import { stringify, parse } from "qs";
import { Route, withRouter, Link } from "react-router-dom";
import { diffString, diff } from "json-diff";
import { Button, DatePicker, Select, Modal } from "antd";
import moment from "moment";

const { Option } = Select;

const l = console.log;

const R = "#F8696B";
const G = "#63BE7B";
const P = "#ffc0cb";
const O = "rgb(252, 170, 120)";
const borderRight = "2px solid black";

// from https://stackoverflow.com/questions/7128675/from-green-to-red-color-depend-on-percentage/7128796
var percentColors = [
  { pct: 0.0, color: { r: 0xf8, g: 0x69, b: 0x6b } },
  { pct: 0.5, color: { r: 0xff, g: 0xff, b: 0x00 } },
  { pct: 1.0, color: { r: 0x63, g: 0xbe, b: 0x7b } }
];

var perc2color = function(pct) {
  for (var i = 1; i < percentColors.length - 1; i++) {
    if (pct < percentColors[i].pct) {
      break;
    }
  }
  var lower = percentColors[i - 1];
  var upper = percentColors[i];
  var range = upper.pct - lower.pct;
  var rangePct = (pct - lower.pct) / range;
  var pctLower = 1 - rangePct;
  var pctUpper = rangePct;
  var color = {
    r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
    g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
    b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
  };
  return "rgb(" + [color.r, color.g, color.b].join(",") + ")";
  // or output as hex if preferred
};

const evSort = (a, b) => {
  if (a === "all") return -1;
  else if (b === "all") return 1;
  else if (a > b) return 1;
  else if (b < a) return -1;
  else return 0;
};
const numfmt = { textAlign: "right", minWidth: "2em" };
const thSubcol = (subcol, tooltips, date, fieldset) => (
  <th
    title={tooltips[subcol] + " for " + date + "," + fieldset}
    style={{ borderRight, ...numfmt }}
    key={"date-" + date + "subcol" + subcol}
  >
    {subcol}
  </th>
);

const thDatecol = (colfields, date, numfmt, sticky) => (
  <th
    colSpan={colfields.length}
    key={"date-" + date}
    style={{
      borderRight,
      textAlign: "center",
      ...numfmt,
      ...sticky
    }}
  >
    {date.split("-")[2]}
  </th>
);

const cellRender = ({
  colfields,
  fieldset,
  app,
  dev,
  os_ver,
  ev,
  date,
  cf,
  cfi,
  colstyle,
  cellkey,
  cellcontents,
  data,
  dateidx,
  nodates,
  noPerEvent
}) => {
  let st = {},
    cont = null;
  if (dateidx === 0 && cfi === 0) st = { ...st, borderLeft: borderRight };
  if (cfi === colfields.length - 1) st.borderRight = borderRight;
  const perEvDisplay = !noPerEvent.includes(cf) || ev === "all";
  if (perEvDisplay) {
    st = { ...st, ...colstyle() };
    cont = cellcontents[cf] ? cellcontents[cf] : data[cf];
  }
  if (cont === undefined && perEvDisplay) st.backgroundColor = P;
  const tooltip = () => {
    const rt =
      "data for " +
      (fieldset ? "fieldset " + fieldset : " N/A fieldset") +
      (app ? ", app " + app : " NO APP") +
      ",dev " +
      dev +
      ", event " +
      ev +
      ", date " +
      date +
      ", cf " +
      cf +
      (cf === "MA" ? " , " + data.PE + " pending" : "");
    return rt;
  };
  return (
    <td title={tooltip()} style={st} key={cellkey}>
      {cont}
    </td>
  );
};
const percentFields = ["TP", "N"];
const noPerEvent = ["ER", "RE", "DO"];
const nodates = ["RE", "TP", "N"];
const cellPreprocess = ({
  colfields,
  cf,
  cfi,
  htable,
  app,
  dev,
  os_ver,
  ev,
  date,
  keys,
  buildLink,
  cellRendered,
  fieldset,
  dateidx,
  openErrorsDetailModal
}) => {
  let data;
  if (nodates.includes(cf)) {
    const dataFirstRow = Object.values(htable[app][dev][os_ver][ev])[0];
    data = { FIELDS: dataFirstRow.FIELDS, PL_guids: dataFirstRow.PL_guids };
    let htb = initsegment(htable, app, dev, os_ver, ev);
    if (htb[cf] !== undefined) data[cf] = htb[cf];
    else {
      const oef = Object.entries(htb).filter(([k, v]) => k.startsWith("20"));
      data[cf] = oef.map(([k, x]) => x[cf]).filter(x => x);
      if (!percentFields.includes(cf)) {
        const red = data[cf].reduce((a, b) => a + b, 0);
        data[cf] = red;
      } else {
        let pcnt = data[cf].length
          ? data[cf].reduce((a, b) => a + b, 0) / data[cf].length
          : null;
        data[cf] = pcnt;
      }
    }
  } else {
    data = initsegment(htable, app, dev, os_ver, ev, date);
  }

  let nodata = false;

  if (noPerEvent.includes(cf) && ev !== "all") {
    nodata = true;
  }

  if (!data) {
    data = {};
    nodata = true;
  }
  if (nodata) {
    //l("no data for", app, dev, date);
  }
  let fieldvalues = {};
  if (!nodata && !nodates.includes(cf)) {
    for (let k of keys) {
      //if (!data.FIELDS) l('data',data,'has no FIELDS for',app,dev,ev,date);
      if (data.FIELDS) fieldvalues[k] = data.FIELDS[keys.indexOf(k)];
    }
    if (date != fieldvalues.sample_date && fieldvalues.sample_date) {
      l("date=", date, "fieldvalues.sample_date=", fieldvalues.sample_date);
      throw "date mismatch between column and data";
    }
  }
  let totcount = data.T; //data.PE+data.ER+data.C+data.M;
  let tota = data.MA + data.AT;
  let dpcol = perc2color(100);

  let apcnt = Math.min((data.MA / 10) * 100, 100) / 100;
  if (data.PE === 0) apcnt = 1;
  let acol = perc2color(apcnt);

  let atcol = perc2color(Math.min((data.AT / totcount) * 100, 100));

  const colsettings = {
    ER: {
      min: 0.1,
      absmin: 0,
      max: 0.4,
      tot: data.DO,
      rev: true
    },
    DO: {
      absmin: 4,
      absmax: 10,
      tot: 10,
      min: 0,
      max: 1,
      rev: false
    },
    PL: {
      min: 0,
      absmin: 0,
      max: 1,
      tot: 10,
      rev: false
    },
    PV: {
      rev: true,
      min: 0,
      max: 0.1,
      tot: 5,
      absmin: 0
    }
  };

  let colors = {};
  for (const [cc, ccs] of Object.entries(colsettings)) {
    let eratio;
    if (data[cc] === undefined) {
      colors[cc] = P;
      continue;
    }
    if (!ccs.tot) eratio = undefined;
    else eratio = data[cc] / ccs.tot;
    if (data[cc] < ccs.absmin) eratio = 0;
    else if (data[cc] >= ccs.absmax) eratio = 1;
    if (eratio > 1) eratio = 1;
    let eratioRng, ecolor;
    if (eratio) {
      eratioRng = (eratio - ccs.min) / (ccs.max - ccs.min);
      if (eratioRng > 1) eratioRng = 1;
      if (eratioRng < 0) eratioRng = 0;
      colors[cc] = perc2color(ccs.rev ? 1 - eratioRng : eratioRng);
    } else colors[cc] = ccs.rev ? G : R;
    //if (ev==='all' && cc==='ER') l(cc,'ratio=',eratio,'which is',data[cc],'/',ccs.tot,'leads to eratioRng of',eratioRng,'cecolor:','%c'+ecolor,'background: '+ecolor);
  }

  let colstyles = {
    PE: { backgroundColor: acol, ...numfmt },
    MA: { backgroundColor: acol, ...numfmt },
    RE: { ...numfmt, backgroundColor: data.RE > 3 ? R : null },
    TP: {
      ...numfmt,
      backgroundColor: typeof data.TP === "number" && perc2color(data.TP / 100)
    },
    N: {
      ...numfmt,
      backgroundColor:
        typeof data.N === "number" && perc2color(1 - data.N / 100)
    }
  };
  const colstyle = () =>
    colstyles[cf] ? colstyles[cf] : { ...numfmt, backgroundColor: colors[cf] };
  const cellkey = [app, dev, ev, date, cf].join(":");
  let cellcontents = {
    PE: !nodata &&
      data.FIELDS && (
        <Link replace target="_blank" to={buildLink(data)}>
          {data.PE}
        </Link>
      ),
    PV: !nodata &&
      data.FIELDS && (
        <Link
          replace
          target="_blank"
          to={buildLink({ ...data }, "pending_annotation_verification")}
        >
          {data.PV}
        </Link>
      ),
    PL: !nodata &&
      data.FIELDS && (
        <Link
          replace
          target="_blank"
          to={buildLink(data, "events_metrics_enrichment_done", "PL_guids")}
        >
          {data.PL}
        </Link>
      ),
    ER: (
      <a
        onClick={() => {
          l("opening errors with data", data);
          openErrorsDetailModal(data);
        }}
      >
        {data.ER}
      </a>
    ),
    TP: data.TP && data.TP.toFixed(2),
    N: data.N && data.N.toFixed(2)
  };
  if (typeof cellRendered[cellkey] === "undefined")
    cellRendered[cellkey] = data[cf];
  else {
    l("WAS:", cellRendered);
    l("old value=", cellRendered[cellkey], "new value=", data[cf]);
    l("WE HAVE ALREADY DISPLAYED " + cellkey);
  }
  const crargs = {
    colfields,
    fieldset,
    app,
    dev,
    os_ver,
    ev,
    date,
    cf,
    cfi,
    colstyle,
    cellkey,
    cellcontents,
    data,
    nodates,
    noPerEvent,
    dateidx
  };
  return cellRender(crargs);
};

const rowRender = ({
  st,
  app,
  dev,
  os_ver,
  ev,
  rowspan,
  key,
  htable,
  keys,
  toggleRowExpand,
  state,
  setState,
  allDates,
  buildLink,
  fieldsets,
  cellRendered,
  updrouter,
  openErrorsDetailModal,
  nodates,
  percentFields,
  noPerEvent
}) => (
  <tr key={"app-" + app + "-dev-" + dev + "-ev-" + ev} style={st}>
    <td rowSpan={rowspan}>{ev === "all" ? app : ""}</td>
    <td style={{ whiteSpace: "nowrap" }}>
      {dev} {os_ver}
    </td>
    <td>
      {ev === "all" && (
        <a
          onClick={() => {
            toggleRowExpand(key, state, setState, updrouter);
          }}
        >
          {state.expandedRows.includes(key) ? (
            <React.Fragment>▼</React.Fragment>
          ) : (
            <React.Fragment>&#9658;</React.Fragment>
          )}
        </a>
      )}
    </td>
    <td>{ev}</td>
    {Object.entries(fieldsets).map(([fieldset, datecolfields]) =>
      Object.entries(datecolfields).map(([date, colfields], dateidx) =>
        colfields.map((cf, cfi) =>
          cellPreprocess({
            colfields,
            cf,
            cfi,
            htable,
            app,
            dev,
            os_ver,
            ev,
            date,
            keys,
            buildLink,
            cellRendered,
            dateidx,
            openErrorsDetailModal
          })
        )
      )
    )}
  </tr>
);

const initsegment = (
  htable,
  app,
  dev,
  os_ver,
  ev,
  date = null,
  doinit = true
) => {
  if (!htable[app]) {
    if (!doinit) return;
    htable[app] = {};
  }
  if (!htable[app][dev]) {
    if (!doinit) return;
    htable[app][dev] = {};
  }
  if (!htable[app][dev][os_ver]) {
    if (!doinit) return;
    htable[app][dev][os_ver] = {};
  }
  if (!htable[app][dev][os_ver][ev]) {
    if (!doinit) return;
    htable[app][dev][os_ver][ev] = {};
  }
  if (!date) return htable[app][dev][os_ver][ev];
  if (!htable[app][dev][os_ver][ev][date]) {
    if (!doinit) return;
    htable[app][dev][os_ver][ev][date] = {};
  }
  return htable[app][dev][os_ver][ev][date];
};

const preprocess = (
  quotas,
  autoTag,
  autoTagByEv,
  allDates,
  fieldsets,
  filterApps = new Set(),
  platformLessThanThreshold,
  errGreaterThanThreshold,
  pendLessThanThreshold,
  verificationGreaterThanThreshold
) => {
  let cnt = 0;
  let htable = {};
  //l('preprocessing quotas',quotas);
  for (let date of allDates) {
    let statKey = date + "-" + date;
    let stat, keys;
    if (!quotas || !quotas[statKey] || !(stat = quotas[statKey].stats))
      continue;
    keys = quotas[statKey].keys;
    for (let r of stat) {
      let app = r.FIELDS[keys.indexOf("app")];

      // Filter out apps that are not chosen in multi select
      if (filterApps.size > 0 && !filterApps.has(app)) {
        continue;
      }
      // Filter out entries with more than threshold num of samples in PL state
      if (
        platformLessThanThreshold &&
        platformLessThanThreshold > 0 &&
        r["PL_guids"].length >= platformLessThanThreshold
      ) {
        continue;
      }

      if (
        errGreaterThanThreshold &&
        errGreaterThanThreshold > 0 &&
        r["ER_guids"].length < errGreaterThanThreshold
      ) {
        continue;
      }

      if (
        pendLessThanThreshold &&
        pendLessThanThreshold > 0 &&
        r["PE_guids"].length >= pendLessThanThreshold
      ) {
        continue;
      }

      if (
        verificationGreaterThanThreshold &&
        verificationGreaterThanThreshold > 0 &&
        r["PV_guids"].length < verificationGreaterThanThreshold
      ) {
        continue;
      }

      let date = r.FIELDS[keys.indexOf("sample_date")];
      if (allDates.indexOf(date) === -1) {
        l("exdates", allDates, "nonexistent date:", date);
      }
      let dev = r.FIELDS[keys.indexOf("device")];
      let os_ver = r.FIELDS[keys.indexOf("os_version")];
      let ev = r.FIELDS[keys.indexOf("event")];
      let htb = initsegment(htable, app, dev, os_ver, ev, date);
      (htb.errors_breakdown = r.errors_breakdown),
        (htb.error_guids = r.error_guids),
        (htb.FIELDS = r.FIELDS);

      for (let [fieldset, datecolfields] of Object.entries(fieldsets)) {
        for (let [_date, colfields] of Object.entries(datecolfields))
          for (let colfield of colfields.concat(["DO", "PE"])) {
            if (ev === "all" && r[colfield + "_guids"]) {
              htb[colfield] = r[colfield + "_guids"].length;
              htb[colfield + "_guids"] = r[colfield + "_guids"];
              //l(colfield,'ev',ev,'is to be counted with guids =>',htb[colfield]);
            }
            // count guids rather than events when dealing with 'all' rows.
            else {
              if (colfield === "MA" && ev === "all")
                throw new Error(
                  'should not be here, "all" for A must be a samples count!'
                );
              htb[colfield] = r[colfield];
              //l(colfield,ev,'is raw count.');
            }
            cnt++;
          }
      }
    }
  }
  for (const atObj of [autoTag, autoTagByEv]) {
    for (const [atKey, atStats] of Object.entries(atObj)) {
      let [app, event, dev] = atKey.split(":");
      if (app === "Summary") continue;
      for (let ev of [event]) {
        if (!htable[app] || !htable[app][dev] || !htable[app][dev][ev])
          continue;
        let htb = htable[app][dev][ev];
        htb.ATS = atStats;
        let times = 1;
        for (const [cf, lbl] of Object.entries(ASFieldsMap)) {
          if (!htb[cf]) htb[cf] = [];
          let hta = htable[app][dev][event];

          for (let i = 0; i < times; i++) {
            htb[cf] = atStats[lbl];
          }
        }
      }
    }
  }
  return htable;
};
const tableHeader = (sticky, fieldsets, tooltips) => (
  <thead>
    <tr>
      <th style={sticky}>App</th>
      <th style={sticky}>Device</th>
      <th title="expand" />
      <th style={sticky}>Event</th>
      {Object.entries(fieldsets).map(([fieldset, datescolfields]) => {
        const colSpan =
          Object.values(datescolfields).reduce(
            (a, b) => Object.values(a).length + Object.values(b).length,
            0
          ) * Object.keys(datescolfields).length;
        return (
          <th
            style={{
              borderRight,
              textAlign: "center"
            }}
            colSpan={colSpan}
            key={"fieldset-" + fieldset}
          >
            {fieldset}
          </th>
        );
      })}
    </tr>
    <tr>
      <th colSpan={4} style={{ borderRight }} />
      {Object.entries(fieldsets).map(([fieldset, datecolfields]) =>
        Object.entries(datecolfields).map(([date, colfields]) => {
          return thDatecol(colfields, date, numfmt, sticky);
        })
      )}
    </tr>
    <tr>
      <th colSpan={4} style={{ ...sticky, borderRight }} />
      {Object.entries(fieldsets).map(([fieldset, datecolfields]) =>
        Object.entries(datecolfields).map(([date, colfields]) =>
          colfields.map(subcol => thSubcol(subcol, tooltips, date, fieldset))
        )
      )}
    </tr>
  </thead>
);

const ASFieldsMap = { TP: "Error (percent)", N: "Negative (percent)" };

class AnnotationQuotas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedRows: [],
      autoRefreshSeconds: null,
      sample_date_from: moment
        .utc()
        .subtract(7, "days")
        .format("YYYY-MM-DD"),
      sample_date_to: moment.utc().format("YYYY-MM-DD"),
      location: LOCATIONS[Object.keys(LOCATIONS)[0]],
      events: [],
      fetch: true // are we fetching data
    };

    const query = parse(this.props.history.location.search.replace(/^\?/, ""));
    if (query.sample_date) {
      query.sample_date = moment.utc(query.sample_date);
    }
    if (query.expandedRows) query.expandedRows = query.expandedRows.split(",");
    for (const k in query) {
      if (!query[k]) {
        delete query[k];
      }
    }
    this.state = Object.assign(this.state, query);
  }
  async toggleRowExpand(token, state, setState, updrouter) {
    let er = state.expandedRows;
    if (!er.includes(token)) er.push(token);
    else er = er.filter(tok => tok !== token);
    await setState({ expandedRows: er });
    updrouter();
  }
  async setRefreshTimer(val) {
    l("setting refresh timer to", val);
    await this.setState({ autoRefreshSeconds: val });
    if (this.state.autoRefreshSeconds) this.refreshData();
  }

  refreshData() {
    l("in refreshData");
    this.fetchData();
    if (this.state.autoRefreshSeconds) {
      l("setting timeout for", this.state.autoRefreshSeconds, "seconds");
      setTimeout(
        this.refreshData.bind(this),
        this.state.autoRefreshSeconds * 1000
      );
    }
  }
  quotas() {
    return this.props.annotationQuotas !== undefined
      ? this.props.annotationQuotas.stats
      : {};
  }

  keys() {
    let rt = [];
    for (let [key, stats] of Object.entries({
      ...this.props.annotationQuotas
    })) {
      rt = stats.keys;
      break;
    }
    return rt;
  }
  updrouter = () => {
    let sample_date_from = this.state.sample_date_from;
    if (sample_date_from && typeof sample_date === "object") {
      sample_date_from = sample_date.format("YYYY-MM-DD");
    }
    let sample_date_to = this.state.sample_date_to;
    if (sample_date_to && typeof sample_date === "object") {
      sample_date_to = sample_date.format("YYYY-MM-DD");
    }

    const formvals = {
      filteredApps: this.state.filteredApps,
      sample_date_from: sample_date_from,
      expandedRows: this.state.expandedRows.join(","),
      sample_date_to: sample_date_to,
      deviceModels: this.state.deviceModels,
      location: this.state.location,
      events: this.state.events,
      fetch: this.state.fetch,
      errorModalSegment: this.state.errorModalSegment
    };
    if (this.state.errorModalSegment) {
      formvals.errorModalSegment = this.state.errorModalSegment;
    }
    for (const k in formvals) {
      if (!formvals[k]) {
        delete formvals[k];
      }
    }

    const qry = stringify(formvals);
    const newurl = `/performance/quotas?${qry}`;
    this.props.history.push(newurl);
  };
  calcDays(fromdate, todate) {
    let rt = [];
    let fr = moment.utc(fromdate); //.format('YYYY-MM-DD');
    let to = moment.utc(todate); //.format('YYYY-MM-DD');
    let i = to;
    while (fr <= i) {
      rt.push(i.format("YYYY-MM-DD"));
      //l('i=',i,'fr=',fr);
      i = i.subtract(1, "days");
    }
    return rt;
  }
  async fetchData() {
    this.setState({ fetch: true });
    const allDates = this.calcDays(
      this.state.sample_date_from,
      this.state.sample_date_to
    );
    const args = {
      filteredApps: this.state.filteredApps,
      deviceModels: this.state.deviceModels,
      location: this.state.location,
      events: this.state.events
    };

    for (let date of allDates) {
      this.props.getAnnotationQuotas({ dateFrom: date, dateTo: date, ...args });
    }
    this.props.getAutoTagStatus({
      ...args,
      groupByDay: false,
      groupByDev: true,
      groupByEv: true
    });
    this.props.getAutoTagStatus({
      ...args,
      groupByDay: false,
      groupByDev: true,
      groupByEv: false
    });
    this.updrouter();
  }
  componentDidMount() {
    if (!this.props.appsList.length) {
      this.props.getAppsList();
    }
    if (!this.props.deviceModels.length) {
      this.props.getDeviceModels();
    }
    if (!this.props.events) {
      this.props.getEvents();
    }
    if (this.state.fetch) {
      this.fetchData();
    }
  }
  onLocationChange = value => {
    this.setState({ location: value });
  };
  onEventsChange = value => {
    l("settings events=", value);
    this.setState({ events: value });
  };

  onDeviceModelsChange = value => {
    this.setState({
      deviceModels: value
    });
  };
  onAppChange = value => {
    let filteredApps;
    if (value) {
      filteredApps = value.map(x => x.split(" ")[0]);
    } else {
      filteredApps = null;
    }
    this.setState({ filteredApps: filteredApps });
  };
  onPlatformLessThanFilterChange = value => {
    this.setState({ platformLessThanThreshold: parseInt(value, 10) });
  };
  onErrGreaterThanFilterChange = value => {
    this.setState({ errGreaterThanThreshold: parseInt(value, 10) });
  };
  onPendLessThanFilterChange = value => {
    this.setState({ pendLessThanThreshold: parseInt(value, 10) });
  };
  onVerificationGreaterThanFilterChange = value => {
    this.setState({ verificationGreaterThanThreshold: parseInt(value, 10) });
  };
  onStartDateChange = date => {
    this.setState({ sample_date_from: date.format("YYYY-MM-DD") });
  };
  onEndDateChange = date => {
    this.setState({ sample_date_to: date.format("YYYY-MM-DD") });
  };

  getStartDate = () => {
    if (this.state.sample_date_from) {
      return moment(this.state.sample_date_from);
    }
    return null;
  };
  getEndDate = () => {
    if (this.state.sample_date_to) {
      return moment(this.state.sample_date_to);
    }
    return null;
  };
  renderForm() {
    return (
      <form>
        App:{" "}
        <Select
          allowClear
          label="App"
          className="control-element"
          mode="tags"
          tokenSeparators={[","]}
          showSearch
          style={{ width: 250 }}
          placeholder="Select an app"
          value={this.state.filteredApps}
          optionFilterProp="value"
          onChange={this.onAppChange}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {this.props.appsList.map(app => {
            return (
              <Option
                value={`${app.package} ${app.platform}`}
                key={`${app.package} ${app.platform}`}
              >
                {`${app.package} ${app.platform}`}
              </Option>
            );
          })}
        </Select>
        {" From: "}
        <DatePicker
          value={this.getStartDate()}
          onChange={this.onStartDateChange}
          format="DD/MM/YYYY"
        />
        {" To: "}
        <DatePicker
          value={this.getEndDate()}
          onChange={this.onEndDateChange}
          format="DD/MM/YYYY"
        />
        {" PL < "}
        <input
          type="number"
          min="0"
          max="9"
          step="1"
          value={
            this.state.platformLessThanThreshold
              ? this.state.platformLessThanThreshold
              : ""
          }
          onChange={e => {
            this.onPlatformLessThanFilterChange(e.target.value);
          }}
        />
        {" ER > "}
        <input
          type="number"
          min="0"
          max="9"
          step="1"
          value={
            this.state.errGreaterThanThreshold
              ? this.state.errGreaterThanThreshold
              : ""
          }
          onChange={e => {
            this.onErrGreaterThanFilterChange(e.target.value);
          }}
        />
        {" PE < "}
        <input
          type="number"
          min="0"
          max="9"
          step="1"
          value={
            this.state.pendLessThanThreshold
              ? this.state.pendLessThanThreshold
              : ""
          }
          onChange={e => {
            this.onPendLessThanFilterChange(e.target.value);
          }}
        />
        {" PV > "}
        <input
          type="number"
          min="0"
          max="9"
          step="1"
          value={
            this.state.verificationGreaterThanThreshold
              ? this.state.verificationGreaterThanThreshold
              : ""
          }
          onChange={e => {
            this.onVerificationGreaterThanFilterChange(e.target.value);
          }}
        />
        Device Models:
        <Select
          allowClear
          label="Devices"
          mode="tags"
          dropdownMatchSelectWidth={false}
          className="control-element"
          onChange={this.onDeviceModelsChange}
          style={{ width: 200 }}
          value={this.state.deviceModels}
          filterOption={(input, option) =>
            option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {this.props.deviceModels.map((model, index) => (
            <Option key={index} value={`${model.model} ${model.os_version}`}>
              {model.model} {model.os_version}
            </Option>
          ))}
        </Select>
        Geo Loc:
        <Select
          allowClear
          label="Location"
          className="control-element"
          showSearch
          style={{ width: 100 }}
          placeholder="Select a location"
          value={this.state.location}
          optionFilterProp="children"
          onChange={this.onLocationChange}
        >
          {Object.keys(LOCATIONS).map(loc => (
            <Option value={LOCATIONS[loc]} key={loc}>
              {loc}
            </Option>
          ))}
        </Select>
        Events:
        <Select
          allowClear
          label="Events"
          mode="tags"
          className="control-element"
          showSearch
          style={{ width: 100 }}
          placeholder="Enter events"
          value={this.state.events}
          dropdownMatchSelectWidth={true}
          onChange={this.onEventsChange}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {[].map(ev => (
            <Option value={ev} key={ev}>
              {ev}
            </Option>
          ))}
        </Select>
        <Button className="control-element" onClick={this.fetchData.bind(this)}>
          Retrieve data
        </Button>
        {/* <input
          type="number"
          min="0"
          step="1"
          disabled
          value={
            this.state.autoRefreshSeconds ? this.state.autoRefreshSeconds : ""
          }
          onChange={e => {
            // this.setRefreshTimer(e.target.value);
          }}
        />
        Auto-refresh interval */}
      </form>
    );
  }
  buildLink(row, sampleState, guids_key) {
    const keys = this.keys();
    const nkeys = keys.reduce((acc, cur, i) => {
      acc[cur] = i;
      return acc;
    }, {});

    const args = {
      fromDate: row.FIELDS[nkeys.sample_date],
      toDate: row.FIELDS[nkeys.sample_date],
      app: row.FIELDS[nkeys.app],
      // state
      // event
      location: row.FIELDS[nkeys.location],
      deviceModel: row.FIELDS[nkeys.device],
      osVersion: row.FIELDS[nkeys.os_version],
      guids: row[guids_key] || undefined,
      //
      fetch: true // do not submit as a temporary workaround to loading problem
    };
    if (sampleState) {
      args.sampleState = sampleState;
    }
    const path = "/performance/nonstrict-annotator";
    const search = `?${stringify(args)}`;
    return {
      pathname: path,
      search
    };
  }
  openErrorsDetailModal = data => {
    l("modal invoked on", data);
    let modalSegment = data.FIELDS;
    this.setState({ errorModalSegment: data.FIELDS.join(";") });
  };
  renderErrors = ([err, guids]) => {
    let renderRow = (o, i) => {
      let rt = (
        <tr key={"err-guids-" + err + "-" + i}>
          <td width="20%">{o.guid}</td>
          <td width="20%">{o.device_id}</td>
          <td width="20%">
            {moment.utc(o.started_at).format("YYYY-MM-DDTHH:mm:ss")}
          </td>
          <td width="20%">{o.jenkins_build_url}</td>
          <td width="7%">
            {o.logcat && (
              <a href={o.logcat} target="_blank">
                link
              </a>
            )}
          </td>
          <td width="7%">
            {o.video && (
              <a href={o.video} target="_blank">
                video
              </a>
            )}
          </td>
          <td width="6%">
            {o.do_log && (
              <a href={o.do_log} target="_blank">
                do_log
              </a>
            )}
          </td>
        </tr>
      );
      return rt;
    };
    //l('err',err,'guids',guids);
    let rt = (
      <div key={"err-" + err}>
        <h3>
          {guids.length} errors of type "{err}
          ":
        </h3>
        <table>
          <thead>
            <tr>
              <th>guid</th>
              <th>Device ID</th>
              <th>Started At</th>
              <th>Jenkins Build</th>
              <th>logcat</th>
              <th>video</th>
              <th>D-O log</th>
            </tr>
          </thead>
          <tbody>{guids.map(renderRow)}</tbody>
        </table>
        <br />
      </div>
    );
    return rt;
  };

  render() {
    let rowcount = 0;
    let cellcount = 0;
    let allDates = this.calcDays(
      this.state.sample_date_from,
      this.state.sample_date_to
    );

    let fieldsets = {
      "\u1229 Dates": Object.fromEntries(
        allDates.map(date => [date, ["PL", "ER", "PE", "PV", "MA", "DO"]])
      ),
      RE: [["RE"]]
    };

    let tooltips = {
      PL: "Platform",
      ER: "Error",
      MA: "Manual Annotation",
      DO: "Device Orchestrator",
      RE: "pending_events_metrics_enrichment + pending_start_events_enrichment"
    };
    let coltitles = {
      D: fieldvalues => JSON.stringify(fieldvalues),
      E: data =>
        !nodata
          ? Object.entries(data.errors_breakdown)
              .map(([k, v]) => {
                return k + ": " + v + " times.";
              })
              .join("\n")
          : ""
    };

    const keys = this.keys();
    if (this.props.annotationQuotas) {
      let keys = this.keys();
      let apps = [];
      let filterApps;
      if (this.state.filteredApps) {
        filterApps = new Set(this.state.filteredApps);
      }
      let htable = preprocess(
        this.props.annotationQuotas,
        this.props.autoTagStatus ? this.props.autoTagStatus.Packages : {},
        this.props.autoTagStatusByEv
          ? this.props.autoTagStatusByEv.Packages
          : {},
        allDates,
        fieldsets,
        filterApps,
        this.state.platformLessThanThreshold,
        this.state.errGreaterThanThreshold,
        this.state.pendLessThanThreshold,
        this.state.verificationGreaterThanThreshold
      );
      let sticky = { position: "sticky" };
      let errorModalSegment = {},
        errorData; //={};
      if (this.state.errorModalSegment) {
        let ems = this.state.errorModalSegment.split(";");
        let ks = keys;
        for (let ki in ks) {
          errorModalSegment[ks[ki]] = ems[ki];
        }
        //l('errorModalSegment=',errorModalSegment);
        errorData =
          htable[errorModalSegment.app][errorModalSegment.device][
            errorModalSegment.os_version
          ][errorModalSegment.event][errorModalSegment.sample_date].error_guids;
      }
      //l('htable=',htable);
      let prevapp;
      let cellRendered = {};
      return (
        <div>
          {this.renderForm()}
          {this.state.errorModalSegment && (
            <Modal
              footer={null}
              onCancel={ev => {
                this.setState({ errorModalSegment: null });
              }}
              destroyOnClose="1"
              visible={true}
              width={1024}
              title={"Errors for " + this.state.errorModalSegment}
            >
              {Object.entries(errorData).map(this.renderErrors)}
            </Modal>
          )}
          <table style={{ border: "solid 1px" }}>
            {tableHeader(sticky, fieldsets, tooltips)}
            <tbody>
              {Object.keys(htable).map((app, idx) => {
                let cnt = 0;
                let rowspan = 1; //Object.keys(htable[app]).length;
                let rt = Object.keys(htable[app]).map(dev => {
                  let rt = Object.keys(htable[app][dev]).map(os_ver => {
                    let rt = Object.keys(htable[app][dev][os_ver])
                      .sort(evSort)
                      .map(ev => {
                        let newapp = false; //cnt++ % rowspan == 0;
                        let st = {};
                        if (ev === "all") {
                          st.borderTop = "1px solid black";
                          st.borderBottom = "1px solid dashed";
                        } else delete st.borderTop;
                        prevapp = app;
                        let disp =
                          ev === "all" ||
                          this.state.expandedRows.includes(app + dev + os_ver);
                        const key = app + dev + os_ver;
                        return (
                          disp &&
                          rowRender({
                            st,
                            app,
                            dev,
                            os_ver,
                            ev,
                            rowspan,
                            key,
                            htable,
                            keys,
                            toggleRowExpand: this.toggleRowExpand,
                            state: this.state,
                            allDates,
                            buildLink: this.buildLink.bind(this),
                            openErrorsDetailModal: this.openErrorsDetailModal,
                            fieldsets,
                            cellRendered,
                            setState: this.setState.bind(this),
                            updrouter: this.updrouter.bind(this),
                            nodates,
                            percentFields,
                            noPerEvent
                          })
                        );
                      });
                    return rt;
                  });
                  return rt;
                });
                return rt;
              })}
            </tbody>
          </table>
        </div>
      );
    } else {
      var rt = (
        <div>
          {this.renderForm()}
          {this.state.fetch && <Spin />}
        </div>
      );
    }
    return rt;
  }
}
const mapStateToProps = ({ performancePage }) => ({
  annotationQuotas: performancePage.annotationQuotas,
  autoTagStatusByEv: performancePage.autoTagStatusByEv,
  autoTagStatus: performancePage.autoTagStatus,
  appsList: performancePage.appsList,
  loadingData: performancePage.loadingData,
  deviceModels: performancePage.deviceModels,
  events: performancePage.events
});

const mapDispatchToProps = {
  getAnnotationQuotas,
  getAutoTagStatus,
  getAutoTagStatusSuccess,
  getAnnotationQuotasSuccess,
  getEvents,
  getEventsSuccess,
  getDeviceModels,
  getAppsList
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AnnotationQuotas)
);
