export const EXPECTED_SUBEVENTS_ORDERED = [
  "first_paint",
  "first_contentful_paint",
  "first_meaningful_paint",
  "end_time"
];

export const LOCATIONS = {
  JP: "GodzillaJP-1",
  US: "Monster-SF",
  IL: "MonsterBenchTLV",
  DE: "KoboldDE-1",
  IN: "BrahmaIN-1"
};
