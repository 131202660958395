import * as AT from "../actionTypes";

export const startNetwork = label => ({
  type: AT.START_NETWORK,
  payload: { label }
});

export const endNetwork = label => ({
  type: AT.END_NETWORK,
  payload: { label }
});
