/* eslint-disable no-case-declarations */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/no-find-dom-node */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import { connect } from "react-redux";
import { isEqual, cloneDeep, merge } from "lodash";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { message, Button, Spin } from "antd";
import {
  getAppsList,
  getDeviceModels,
  loadSignatureVideos,
  loadSignatureVideosSuccess,
  saveAnnotatedEvents,
  setBadVideoForSampleAPI,
  verifyPendingSampleAPI
} from "../../../redux/actions/performancePage";
import { withRouter, Route } from "react-router-dom";
import AnnotatorHeader from "../AnnotatorHeader";
import AnnotatorEventsSubeventsBoxes from "../AnnotatorEventsSubeventsBoxes";
import AnnotatorVideoDisplay from "../AnnotatorVideoDisplay";
import NonStrictAnnotatorScrubber from "../NonStrictAnnotatorScrubber";
import PerformanceToolbar from "../performanceToolbar/PerformanceToolbar";
import Auth from "@aws-amplify/auth";

const Swal2 = withReactContent(Swal);

const TEST_VIDEO_URL =
  "https://watchful-app-record.s3.amazonaws.com/com.ebay.mobile/2019/6/fe427812-1d7a-4acc-911b-e355b959eb68/c12ec4c53c4242f4bad23aac726f62a2.mp4";

const VIDEO_FRAMERATE = 30;
const END_FRAME_SCRUBBER_BUFFER = 120;
const SAMPLE_STATUS_PENDING = "pending_events_metrics_enrichment";
const ARROW_KEYS_CODE = {
  LEFT: 37,
  RIGHT: 39,
  DOWN: 40
};

const KEY_CODES = {
  D: 68,
  N: 78,
  S: 83
};

const ARROW_KEY_STEP_SIZE = 1;
const SHIFT_ARROW_KEY_STEP_SIZE = 20;

class SequentialEventsAnnotator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedEvent: undefined,
      selectedSubevent: undefined,
      scrubberMinValue: undefined,
      scrubberMaxValue: undefined,
      videoDurationInFrames: undefined,
      currentSampleEventsArray: undefined,
      scrubberFrame: 0,
      currentSampleIndex: 0,
      scrubberInFocus: undefined
    };
  }

  componentDidMount() {
    Auth.currentAuthenticatedUser().then(user => {
      this.setState({ user: user });
    });

    if (this.props.appsList.length === 0) {
      this.props.getAppsList();
    }

    if (this.props.deviceModels.length === 0) {
      this.props.getDeviceModels();
    }

    document.onkeydown = e => {
      // check arrow key press
      let stepSize = 0;
      let stepSizeReduceSize = 0;
      if (
        e.keyCode === ARROW_KEYS_CODE.LEFT ||
        e.keyCode === ARROW_KEYS_CODE.RIGHT
      ) {
        if (this.state.scrubberInFocus) {
          // in that case, the slider component controlls the actual key press event,
          // so im setting the tep size to 1 in order to not move 2 frmaes at once
          stepSizeReduceSize = 1;
        } else {
          stepSizeReduceSize = 0;
        }
      }
      const {
        scrubberFrame,
        videoDurationInFrames,
        selectedEvent,
        selectedSubevent
      } = this.state;
      switch (e.keyCode) {
        case ARROW_KEYS_CODE.LEFT:
          if (scrubberFrame > 0) {
            if (e.shiftKey) {
              stepSize -= SHIFT_ARROW_KEY_STEP_SIZE - stepSizeReduceSize;
            } else {
              stepSize -= ARROW_KEY_STEP_SIZE - stepSizeReduceSize;
            }
            this.handleScrubberChange(scrubberFrame + stepSize);
          }
          break;

        case ARROW_KEYS_CODE.RIGHT:
          if (scrubberFrame < videoDurationInFrames) {
            if (e.shiftKey) {
              stepSize += SHIFT_ARROW_KEY_STEP_SIZE - stepSizeReduceSize;
            } else {
              stepSize += ARROW_KEY_STEP_SIZE - stepSizeReduceSize;
            }
            this.handleScrubberChange(scrubberFrame + stepSize);
          }
          break;

        case ARROW_KEYS_CODE.DOWN:
          e.preventDefault(); // prevents the window scroll when presseing the down arrow
          // when the slider component in focus it handles keypresses by its own
          // we need to compensate this by adding one frame (down arrow goes one step back in the slider)
          if (this.state.scrubberInFocus) {
            this.handleScrubberChange(scrubberFrame + 1);
          }
          this.handleFrameCapture(selectedEvent, selectedSubevent);
          break;

        case KEY_CODES.N:
          const sampleEvents = this.state.currentSampleEventsArray;
          const selectedEventIndex = sampleEvents.findIndex(
            event => event.name == this.state.selectedEvent.name
          );

          if (!this.isEventCaptured(sampleEvents[selectedEventIndex])) {
            message.error(
              "Can't select next event before tagging the current event!"
            );
            return;
          }

          if (
            selectedEventIndex >= 0 &&
            selectedEventIndex + 1 < sampleEvents.length
          ) {
            this.handleSubeventSelection(
              sampleEvents[selectedEventIndex + 1].subevents.find(
                subevent => subevent.name === "end_time"
              )
            );
            this.handleEventSelection(
              sampleEvents[selectedEventIndex + 1],
              selectedEventIndex + 2 < sampleEvents.length
                ? sampleEvents[selectedEventIndex + 2]
                : sampleEvents[selectedEventIndex + 1]
            );
          }
          break;
        case KEY_CODES.S:
          const canSave = this.state.currentSampleEventsArray.every(
            event => event.end_frame_index && event.end_ts_s
          );
          if (canSave) {
            this.handleSaveSample();
          } else {
            message.error(
              "Can't save sample without tagging all its events..."
            );
          }
          break;
        case KEY_CODES.D:
          this.handleAnnotationDeletion(selectedEvent, selectedSubevent);
          break;
        default:
          break;
      }
    };
  }

  isEventCaptured = event => {
    return event.end_frame_index && event.end_ts_s;
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.appsList.length > 0 &&
      this.props.nonStrictAnnotatorTabData.samples &&
      this.props.nonStrictAnnotatorTabData.samples.length > 0 &&
      (!isEqual(
        prevProps.nonStrictAnnotatorTabData,
        this.props.nonStrictAnnotatorTabData
      ) ||
        !isEqual(prevProps.appsList, this.props.appsList) ||
        !isEqual(prevState.currentSampleIndex, this.state.currentSampleIndex))
    ) {
      const currentSample = this.props.nonStrictAnnotatorTabData.samples[
        this.state.currentSampleIndex
      ];
      const sampleEventsArray = currentSample.events;
      // the next few lines creates an array of the sample events enriched with the event/subevent metadata
      if (sampleEventsArray.length > 0) {
        const eventsMetadataObj = {};
        const subeventsMetadataObj = {};
        this.props.appsList.forEach(app => {
          app.performance_events.forEach(event => {
            eventsMetadataObj[event.id] = {
              ...event,
              package_name: app.package,
              app_id: app.id
            };
            event.subevents.forEach(subevent => {
              subeventsMetadataObj[subevent.id] = { ...subevent };
            });
          });
        });

        const enrichedSampleEvents = sampleEventsArray.map(sampleEvent => {
          return {
            ...cloneDeep(
              Object.values(eventsMetadataObj).find(
                event =>
                  event.name == sampleEvent.name &&
                  event.package_name == currentSample.package_name
              )
            ),
            ...cloneDeep(sampleEvent)
          };
        });

        enrichedSampleEvents.forEach(enrichedSampleEvent => {
          if (
            enrichedSampleEvent.subevents &&
            enrichedSampleEvent.subevents.length == 0
          ) {
            const eventMetadata = Object.values(eventsMetadataObj).find(
              eventMetadata => eventMetadata.id == enrichedSampleEvent.id
            );
            if (eventMetadata && eventMetadata.subevents) {
              enrichedSampleEvent.subevents = eventMetadata.subevents;
            } else {
              enrichedSampleEvent.subevents = [];
            }
          }
          if (!enrichedSampleEvent.subevents) {
            enrichedSampleEvent.subevents = [];
          }
          enrichedSampleEvent.subevents = enrichedSampleEvent.subevents.map(
            enrichedSampleSubevent => ({
              ...enrichedSampleSubevent,
              ...Object.values(subeventsMetadataObj).find(
                subevent => subevent.name == enrichedSampleSubevent.name
              )
            })
          );
          const sampleEndTimeFakeSubevent = enrichedSampleEvent.subevents.find(
            subevent => subevent.name == "end_time"
          );
          const fakeEndSubevent = {
            id: 0,
            name: "end_time",
            title: "End Time",
            description:
              "this is a fake event that make sure you tag the events end time",
            end_frame_index: enrichedSampleEvent.end_frame_index,
            end_ts_s: enrichedSampleEvent.end_ts_s
          };
          if (!sampleEndTimeFakeSubevent) {
            // insert the "fake" event end time subevent
            enrichedSampleEvent.subevents.push(fakeEndSubevent);
          } else {
            enrichedSampleEvent.subevents = enrichedSampleEvent.subevents.map(
              subevent => {
                if (subevent.name == "end_time") {
                  return merge(fakeEndSubevent, subevent);
                }
                return subevent;
              }
            );
          }
        });
        this.setState({
          // selectedEvent: enrichedSampleEvents[0],
          // selectedSubEvent:
          //   enrichedSampleEvents[0].subevents &&
          //   enrichedSampleEvents[0].subevents[0],
          currentSampleEventsArray: enrichedSampleEvents
        });
      }
    }
  }

  componentWillUnmount() {
    this.resetPage();
    document.onkeydown = null;
  }

  handleScrubberChange = scrubberValue => {
    this.setState({ scrubberFrame: scrubberValue });
  };

  handleEventSelection = (event, nextEvent) => {
    const endFrameIndex = event.end_frame_index
      ? event.end_frame_index + END_FRAME_SCRUBBER_BUFFER
      : event.start_frame_index == nextEvent.start_frame_index
        ? this.state.videoDurationInFrames
        : nextEvent.start_frame_index;
    this.setState({
      selectedEvent: event,
      scrubberFrame: event.end_frame_index || event.start_frame_index,
      scrubberMinValue: event.start_frame_index,
      scrubberMaxValue:
        endFrameIndex > event.start_frame_index
          ? endFrameIndex
          : nextEvent.start_frame_index
    });
  };

  handleSubeventSelection = subevent => {
    this.setState(prevState => ({
      selectedSubevent: subevent,
      scrubberFrame: subevent.end_frame_index
        ? subevent.end_frame_index
        : prevState.scrubberFrame
    }));
  };

  handleAnnotationDeletion = (selectedEvent, selectedSubevent) => {
    const { currentSampleEventsArray } = this.state;
    const currentSampleEventsArrClone = currentSampleEventsArray.map(event => {
      const clonedEventObj = { ...event };
      if (clonedEventObj.name == selectedEvent.name) {
        if (selectedSubevent.name == "end_time") {
          clonedEventObj.end_frame_index = undefined;
          clonedEventObj.end_ts_s = undefined;
        }
      }
      clonedEventObj.subevents = clonedEventObj.subevents.map(subevent => {
        const clonedSubeventObj = { ...subevent };
        if (clonedSubeventObj.id == selectedSubevent.id) {
          clonedSubeventObj.end_frame_index = undefined;
          clonedSubeventObj.end_ts_s = undefined;
        }

        return clonedSubeventObj;
      });
      return clonedEventObj;
    });

    this.setState(prevState => ({
      currentSampleEventsArray: currentSampleEventsArrClone
    }));
  };

  handleFrameCapture = (selectedEvent, selectedSubevent) => {
    const { currentSampleEventsArray, scrubberFrame } = this.state;
    const computedEndTS = scrubberFrame / VIDEO_FRAMERATE;
    let newScrubberMaxValue;
    let hasErrored = false;

    const currentSampleEventsArrClone = currentSampleEventsArray.map(event => {
      const clonedEventObj = { ...event };
      if (clonedEventObj.name == selectedEvent.name) {
        if (selectedSubevent.name == "end_time") {
          if (scrubberFrame <= clonedEventObj.start_frame_index) {
            message.error(
              <h1
              >{`ERROR! CANT CHOOSE A FRAME SMALLER THAN THE START OF THE EVENT: CHOSE: 
            ${scrubberFrame} IS NOT BIGGER THAN ${
                clonedEventObj.start_frame_index
              }`}</h1>,
              5
            );
            hasErrored = true;
          } else {
            clonedEventObj.end_frame_index = scrubberFrame;
            clonedEventObj.end_ts_s = computedEndTS;
            newScrubberMaxValue = scrubberFrame + END_FRAME_SCRUBBER_BUFFER;
          }
        }
        const enrichedSelectedEvent = currentSampleEventsArray.find(
          enrichedEvent => enrichedEvent.id == selectedEvent.id
        );
        if (enrichedSelectedEvent) {
          if (!clonedEventObj.subevents) {
            // this event has no tagged subevents at all
            clonedEventObj.subevents = enrichedSelectedEvent.subevents.filter(
              subevent => subevent.id != 0
            );
          }
          clonedEventObj.subevents = clonedEventObj.subevents.map(subevent => {
            const clonedSubeventObj = { ...subevent };
            if (clonedSubeventObj.id == selectedSubevent.id) {
              if (scrubberFrame <= clonedEventObj.start_frame_index) {
                message.error(
                  <h1
                  >{`ERROR! CANT CHOOSE A FRAME SMALLER THAN THE START OF THE EVENT: CHOSE: 
                ${scrubberFrame} IS NOT BIGGER THAN ${
                    clonedEventObj.start_frame_index
                  }`}</h1>,
                  5
                );
                hasErrored = true;
              } else {
                clonedSubeventObj.end_frame_index = scrubberFrame;
                clonedSubeventObj.end_ts_s = computedEndTS;
              }
            }
            return clonedSubeventObj;
          });
        }
      }
      return clonedEventObj;
    });

    if (!hasErrored) {
      this.setState(prevState => ({
        currentSampleEventsArray: currentSampleEventsArrClone,
        scrubberMaxValue: newScrubberMaxValue
          ? newScrubberMaxValue
          : prevState.scrubberMaxValue
      }));
    }
  };

  handleSaveSample = () => {
    const { nonStrictAnnotatorTabData } = this.props;
    const { currentSampleIndex } = this.state;
    const cleanObj = obj => {
      const allowedSubeventKeys = [
        "name",
        "title",
        "end_frame_index",
        "end_ts_s",
        "subevents",
        "start_ts_s",
        "start_frame_index"
      ];
      for (const [key, val] of Object.entries(obj)) {
        if (!allowedSubeventKeys.includes(key)) {
          delete obj[key];
        }
      }
      return obj;
    };
    const beFilteredEventsArray = cloneDeep(
      this.state.currentSampleEventsArray
    ).map(event => {
      event.subevents.map(subevent => {
        return cleanObj(subevent);
      });
      return cleanObj(event);
    });

    /** if one or more of the event end times changed, chnage the sample state */
    let resetSampleState = false;
    for (let i = 0; i < beFilteredEventsArray.length; i++) {
      if (
        beFilteredEventsArray[i].end_frame_index !=
        nonStrictAnnotatorTabData.samples[currentSampleIndex].events[i]
          .end_frame_index
      ) {
        // this is enough to reset sample the state
        resetSampleState = true;
      }
    }
    // TODO: POST TO THE BACKEND
    const sampleData = [
      {
        [nonStrictAnnotatorTabData.samples[currentSampleIndex].guid]: {
          events: beFilteredEventsArray,
          state: resetSampleState
            ? SAMPLE_STATUS_PENDING
            : nonStrictAnnotatorTabData.samples[currentSampleIndex]._state
        }
      }
    ];
    console.log(sampleData);
    this.props.saveAnnotatedEvents(sampleData);
    this.handleNextSample();
  };

  handlePreviousSample = () => {
    const { currentSampleIndex } = this.state;
    if (currentSampleIndex - 1 >= 0) {
      this.setState(prevState => ({
        currentSampleIndex: prevState.currentSampleIndex - 1
      }));
    } else {
      this.resetPageAlert().then(() => {
        this.resetPage();
      });
    }
  };

  handleNextSample = () => {
    const { nonStrictAnnotatorTabData } = this.props;
    const { currentSampleIndex } = this.state;
    if (currentSampleIndex + 1 < nonStrictAnnotatorTabData.samples.length) {
      this.setState(prevState => ({
        currentSampleIndex: prevState.currentSampleIndex + 1
      }));
    } else {
      this.resetPageAlert().then(() => {
        this.resetPage();
      });
    }
  };

  resetPageAlert = () => {
    return new Promise((resolve, reject) => {
      Swal2.fire({
        title: "No more samples",
        text: "Reset page?",
        type: "question"
      }).then(value => {
        if (!value.value) {
          reject();
        }
        resolve(value);
      });
    });
  };

  resetPage = () => {
    this.props.loadSignatureVideosSuccess({}, true, undefined);
    this.setState({
      selectedEvent: undefined,
      selectedSubevent: undefined,
      scrubberMinValue: undefined,
      scrubberMaxValue: undefined,
      videoDurationInFrames: undefined,
      currentSampleEventsArray: undefined,
      scrubberFrame: 0,
      currentSampleIndex: 0,
      scrubberInFocus: undefined
    });
  };

  render() {
    const {
      videoDurationInFrames,
      scrubberFrame,
      currentSampleIndex,
      currentSampleEventsArray,
      selectedEvent,
      selectedSubevent
    } = this.state;
    const {
      appsList,
      loadingData,
      nonStrictAnnotatorTabData,
      deviceModels
    } = this.props;

    // Dont allow annotation of apps expecting subevents in sequential annotator
    const filteredAppList = appsList.filter(
      app =>
        (app.platform === "Android" &&
          [
            "com.facebook.katana",
            "com.facebook.lite",
            "com.twitter.android",
            "com.snapchat.android",
            "com.zhiliaoapp.musically",
            "com.instagram.android",
            // Y!JP
            "jp.gocro.smartnews.android",
            "jp.or.nhk.news",
            "jp.co.yahoo.android.sports.sportsnavi",
            "jp.co.yahoo.android.yshopping",
            "com.amazon.mShop.android.shopping",
            "jp.co.yahoo.android.news",
            "jp.co.yahoo.android.emg",
            "com.undotsushin"
          ].indexOf(app.package) === -1) ||
        (app.platform === "iOS" &&
          [
            "com.facebook.Facebook",
            "com.burbn.instagram",
            // Y!JP
            "jp.co.yahoo.Shopping",
            "com.amazon.AmazonJP",
            "jp.co.yahoo.ios.sports.sportsnavi",
            "jp.co.yahoo.yjtrend01",
            "jp.gocro.SmartNews",
            "jp.or.nhk.news",
            "jp.co.yahoo.emg.alert",
            "com.limret.undotsushin"
          ].indexOf(app.package) === -1)
    );

    if (!filteredAppList || filteredAppList.length == 0) {
      return <Spin />;
    }

    let currentSample = {};
    let scrubberAnnotationMarkers = {};
    let scrubberAverageAnnotationMarkers = {};

    if (
      nonStrictAnnotatorTabData.samples &&
      nonStrictAnnotatorTabData.samples.length > 0 &&
      selectedEvent &&
      selectedSubevent
    ) {
      currentSample = nonStrictAnnotatorTabData.samples[currentSampleIndex];

      if (currentSampleEventsArray) {
        currentSampleEventsArray.forEach(event => {
          // if (event.end_frame_index && event.name == selectedEvent.name) {
          //   scrubberAnnotationMarkers[event.end_frame_index] = "End Time";
          // }
          event.subevents.forEach(subevent => {
            if (
              subevent.end_frame_index &&
              event.name == selectedEvent.name &&
              subevent.name == selectedSubevent.name
            ) {
              scrubberAnnotationMarkers[subevent.end_frame_index] = (
                <div className="scrubber-marker">
                  <div className="dial" />
                </div>
              );
            }
            if (
              nonStrictAnnotatorTabData.query_stats &&
              nonStrictAnnotatorTabData.query_stats[
                currentSample.device_info.product_model
              ] &&
              nonStrictAnnotatorTabData.query_stats[
                currentSample.device_info.product_model
              ][currentSample.version_info.version_name] &&
              nonStrictAnnotatorTabData.query_stats[
                currentSample.device_info.product_model
              ][currentSample.version_info.version_name][event.name] &&
              nonStrictAnnotatorTabData.query_stats[
                currentSample.device_info.product_model
              ][currentSample.version_info.version_name][event.name][
                selectedSubevent.name
              ]
            ) {
              const statsForSample =
                nonStrictAnnotatorTabData.query_stats[
                  currentSample.device_info.product_model
                ][currentSample.version_info.version_name];

              const statsForEvent = statsForSample[event.name];
              const statsForSelectedSubevent =
                statsForEvent[selectedSubevent.name];
              if (!statsForSelectedSubevent.end_frame_index_average) {
                return;
              }
              scrubberAnnotationMarkers[
                statsForSelectedSubevent.end_frame_index_average
              ] = (
                <div className="scrubber-avg-marker">
                  <div className="dial" />
                </div>
              );
              scrubberAnnotationMarkers[
                statsForSelectedSubevent.end_frame_index_average -
                  Math.floor(
                    statsForSelectedSubevent.end_frame_index_std_dev / 2
                  )
              ] = (
                <div
                  className="scrubber-stddev-marker-before"
                  subeventname={subevent.name}
                >
                  <div className="dial" />
                </div>
              );
              scrubberAnnotationMarkers[
                statsForSelectedSubevent.end_frame_index_average +
                  Math.floor(
                    statsForSelectedSubevent.end_frame_index_std_dev / 2
                  )
              ] = (
                <div
                  className="scrubber-stddev-marker-after"
                  subeventname={subevent.name}
                >
                  <div className="dial" />
                </div>
              );
            }
          });
        });
      }
    }

    return (
      <div className="performance-tab">
        <PerformanceToolbar
          history={this.props.history}
          appsList={filteredAppList}
          loadingData={loadingData}
          allDeviceModels={deviceModels}
          numberOfSamples={
            (nonStrictAnnotatorTabData.samples &&
              nonStrictAnnotatorTabData.samples.length) ||
            0
          }
          loadSignatureVideos={this.props.loadSignatureVideos}
          nonStrictMode={true}
          resetPageData={() =>
            this.props.loadSignatureVideosSuccess({}, true, undefined)
          }
        />
        {!nonStrictAnnotatorTabData.samples ? null : nonStrictAnnotatorTabData
          .samples.length > 0 &&
        filteredAppList.length > 0 &&
        typeof currentSampleIndex == "number" &&
        currentSampleEventsArray ? (
          <div>
            <AnnotatorHeader
              user={this.state.user}
              currentSampleIndex={currentSampleIndex}
              currentSample={currentSample}
              dataLength={nonStrictAnnotatorTabData.samples.length}
              setBadVideoForSampleAPI={this.props.setBadVideoForSampleAPI}
              verifyPendingSampleAPI={this.props.verifyPendingSampleAPI}
              nextSample={this.handleNextSample}
              previousSample={this.handlePreviousSample}
            />
            {/** Actual page render */}
            <div className="nonstrict-annotator-content">
              <AnnotatorEventsSubeventsBoxes
                ignoreSubevents={true}
                sampleEventsArray={currentSampleEventsArray}
                rawSampleEventsArray={currentSample.events}
                currentScrubberFrame={scrubberFrame}
                handleEventSelection={this.handleEventSelection}
                handleSubeventSelection={this.handleSubeventSelection}
                handleFrameCapture={this.handleFrameCapture}
                selectedEvent={this.state.selectedEvent}
                selectedSubevent={this.state.selectedSubevent}
              />
              <h3
                style={{ textAlign: "center", marginTop: "1rem" }}
              >{`${selectedEvent &&
                (selectedEvent.title ||
                  selectedEvent.name)} - ${selectedSubevent &&
                (selectedSubevent.title || selectedSubevent.name)}`}</h3>
              <AnnotatorVideoDisplay
                videoURL={currentSample.video_constant_fps}
                currentVideoFrame={scrubberFrame}
                videoFrameRate={VIDEO_FRAMERATE}
                setVideoDurationInFrames={videoDurationInFrames =>
                  this.setState({ videoDurationInFrames })
                }
              />
              <NonStrictAnnotatorScrubber
                scrubberFrame={this.state.scrubberFrame}
                startFrame={this.state.scrubberMinValue}
                endFrame={this.state.scrubberMaxValue}
                handleScrubberChange={this.handleScrubberChange}
                videoDurationInFrames={videoDurationInFrames}
                scrubberAverageAnnotationMarkers={
                  scrubberAverageAnnotationMarkers
                }
                scrubberAnnotationMarkers={scrubberAnnotationMarkers}
                setScrubberInFocusParent={scrubberInFocus =>
                  this.setState({ scrubberInFocus })
                }
              />
              <div className="save-sample">
                <Button
                  block
                  type="primary"
                  onClick={this.handleSaveSample}
                  disabled={
                    !currentSampleEventsArray.every(
                      event => event.end_frame_index && event.end_ts_s
                    )
                  }
                >
                  {currentSampleIndex + 1 >=
                  nonStrictAnnotatorTabData.samples.length
                    ? "Save Sample"
                    : "Save & Next Sample"}
                </Button>
              </div>
            </div>
          </div>
        ) : (
          "No Data"
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ performancePage }) => ({
  appsList: performancePage.appsList,
  deviceModels: performancePage.deviceModels,
  nonStrictAnnotatorTabData: performancePage.nonStrictAnnotatorTabData,
  loadingData: performancePage.loadingData,
  updatingPerformance: performancePage.updatingPerformance
});

const mapDispatchToProps = {
  getAppsList,
  loadSignatureVideos,
  loadSignatureVideosSuccess,
  saveAnnotatedEvents,
  setBadVideoForSampleAPI,
  verifyPendingSampleAPI,
  getDeviceModels
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SequentialEventsAnnotator)
);
