// LOGIN
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";

// INSIGHTS
export const LOAD_INSIGHT = "LOAD_INSIGHT";
export const LOAD_APPS = "LOAD_APPS";
export const SET_APPS = "SET_APPS";
export const SELECT_APP = "SELECT_APP";
export const API_REQUEST = "API_REQUEST";
export const START_NETWORK = "START_NETWORK";
export const END_NETWORK = "END_NETWORK";
export const LOAD_INSIGHTS = "LOAD_INSIGHTS";
export const SET_INSIGHTS = "SET_INSIGHTS";
export const SET_LABELS = "SET_LABELS";
export const SELECT_INSIGHT = "SELECT_INSIGHT";
export const SET_APP_VERSIONS = "SET_APP_VERSIONS";
export const INSIGHT_SAVED = "INSIGHT_SAVED";
export const BEFORE_INSIGHT_SAVED = "BEFORE_INSIGHT_SAVED";
export const INSIGHT_SAVED_ERROR = "INSIGHT_SAVED_ERROR";
export const LOAD_FROM_STORAGE = "LOAD_FROM_STORAGE";
export const CLEAR_EMAIL_JSON_INSIGHTS = "CLEAR_EMAIL_JSON_INSIGHTS";
export const SET_INSIGHTS_IMPORTANCE = "SET_INSIGHTS_IMPORTANCE";
//
export const CREATE_INSIGHT = "CREATE_INSIGHT";
export const CREATE_INSIGHT_SUCCESS = "CREATE_INSIGHT_SUCCESS";
export const CREATE_INSIGHT_FAILED = "CREATE_INSIGHT_FAILED";
//
export const ADD_IMAGE = "ADD_IMAGE";
export const ADD_IMAGE_SUCCESS = "ADD_IMAGE_SUCCESS";
export const ADD_IMAGE_FAILED = "ADD_IMAGE_FAILED";

// PERFORMANCE
export const API_REQUEST_FAILED = "API_REQUEST_FAILED";
export const API_REQUEST_RESET_ERROR = "API_REQUEST_RESET_ERROR";
export const LOAD_AUTOTAG_STATUS_SUCCESS = "LOAD_AUTOTAG_STATUS_SUCCESS";
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";

export const LOAD_PERFORMANCE = "LOAD_PERFORMANCE";
export const LOAD_PERFORMANCE_SUCCESS = "LOAD_PERFORMANCE_SUCCESS";

export const LOAD_ANNOTATIONS = "LOAD_ANNOTATIONS";
export const LOAD_ANNOTATIONS_SUCCESS = "LOAD_ANNOTATIONS_SUCCESS";

export const LOAD_ANNOTATION_QUOTAS = "LOAD_ANNOTATION_QUOTAS";
export const LOAD_ANNOTATION_QUOTAS_SUCCESS = "LOAD_ANNOTATION_QUOTAS_SUCCESS";

export const SET_PERFORMANCE = "SET_PERFORMANCE";
export const UPDATE_PERFORMANCE = "UPDATE_PERFORMANCE";
export const UPDATE_PERFORMANCE_SUCCESS = "UPDATE_PERFORMANCE_SUCCESS";
export const UPDATE_PERFORMANCE_FAILED = "UPDATE_PERFORMANCE_FAILED";
export const INIT_PERFORMANCE_STATUS = "INIT_PERFORMANCE_STATUS";
export const SET_VALIDATE_FORM = "SET_VALIDATE_FORM";
export const SET_ERRORS_FORM = "SET_ERRORS_FORM";
export const GET_APPS_LIST = "GET_APPS_LIST";
export const GET_APPS_LIST_SUCCESS = "GET_APPS_LIST_SUCCESS";
export const VERIFY_PENDING_ANNOTATION_SUCCESS =
  "VERIFY_PENDING_ANNOTATION_SUCCESS";
export const SET_BAD_VIDEO_FOR_SAMPLE = "SET_BAD_VIDEO_FOR_SAMPLE";
export const SET_BAD_VIDEO_FOR_SAMPLE_SUCCESS =
  "SET_BAD_VIDEO_FOR_SAMPLE_SUCCESS";
export const UNSET_BAD_VIDEO_FOR_SAMPLE_SUCCESS =
  "UNSET_BAD_VIDEO_FOR_SAMPLE_SUCCESS";
export const LOAD_SIGNATURE_VIDEOS = "LOAD_SIGNATURE_VIDEOS";
export const LOAD_SIGNATURE_VIDEOS_SUCCESS = "LOAD_SIGNATURE_VIDEOS_SUCCESS";
export const LOAD_SIGNATURE_VIDEOS_FAILED = "LOAD_SIGNATURE_VIDEOS_FAILED";
export const SAVE_ANNOTATED_EVENTS = "SAVE_ANNOTATED_EVENTS";
export const SAVE_ANNOTATED_EVENTS_SUCCESS = "SAVE_ANNOTATED_EVENTS_SUCCESS";
export const CHECK_ANNOTATED_EVENTS_SUCCESS = "CHECK_ANNOTATED_EVENTS_SUCCESS";
export const CHECK_ANNOTATED_EVENTS_FAILED = "CHECK_ANNOTATED_EVENTS_FAILED";
export const GET_DEVICE_MODELS = "GET_DEVICE_MODELS";
export const GET_DEVICE_MODELS_SUCCESS = "GET_DEVICE_MODELS_SUCCESS";
export const GET_ANONYMIZED_SAMPLE = "GET_ANONYMIZED_SAMPLE";
export const GET_ANONYMIZED_SAMPLE_SUCCESS = "GET_ANONYMIZED_SAMPLE_SUCCESS";
export const SEND_SAMPLE_FOR_ANONYMIZATION = "SEND_SAMPLE_FOR_ANONYMIZATION";
export const SET_SAMPLE_AS_REPRESENTATIVE = "SET_SAMPLE_AS_REPRESENTATIVE";
export const GET_ANONYMIZATION_QUEUE = "GET_ANONYMIZATION_QUEUE";
export const GET_ANONYMIZATION_QUEUE_SUCCESS =
  "GET_ANONYMIZATION_QUEUE_SUCCESS";

// Compile & Deploy
export const LOAD_EMAILS = "LOAD_EMAILS";
export const LOAD_EMAILS_SUCCESS = "LOAD_EMAILS_SUCCESS";
export const SET_EMAILS_IN_QUEUE = "SET_EMAILS_IN_QUEUE";
export const GET_STATUS = "GET_STATUS";
export const GET_STATUS_SUCCESS = "GET_STATUS_SUCCESS";
export const GET_STATUS_FAILED = "GET_STATUS_FAILED";
export const INVALIDATE_CDN = "INVALIDATE_CDN";
export const DEPLOY = "DEPLOY";
export const DEPLOY_FINISH = "DEPLOY_FINISH";

// Users
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_FAILED = "GET_ALL_USERS_FAILED";
export const GET_ALL_APPS = "GET_ALL_APPS";
export const GET_ALL_APPS_SUCCESS = "GET_ALL_APPS_SUCCESS";
export const GET_ALL_APPS_FAILED = "GET_ALL_APPS_FAILED";
export const ADD_USER = "ADD_USER";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAILED = "ADD_USER_FAILED";
export const INIT_USER_MSG = "INIT_USER_MSG";
export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILED = "GET_USER_FAILED";
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILED = "DELETE_USER_FAILED";
export const DELETE_USER_APP = "DELETE_USER_APP";
export const DELETE_USER_APP_SUCCESS = "DELETE_USER_APP_SUCCESS";
export const DELETE_USER_APP_FAILED = "DELETE_USER_APP_FAILED";
export const REMOVE_USER_APP = "REMOVE_USER_APP";
export const ADD_USER_APP = "ADD_USER_APP";
export const EDIT_USER_APPS = "EDIT_USER_APPS";
export const EDIT_USER_APPS_SUCCESS = "EDIT_USER_APPS_SUCCESS";
export const EDIT_USER_APPS_FAILED = "EDIT_USER_APPS_FAILED";
export const EDIT_USER_DEATILS = "EDIT_USER_DEATILS";
export const EDIT_USER_DEATILS_SUCCESS = "EDIT_USER_DEATILS_SUCCESS";
export const EDIT_USER_DEATILS_FAILED = "EDIT_USER_DEATILS_FAILED";
export const GET_USER_APPS = "GET_USER_APPS";
export const GET_USER_APPS_SUCCESS = "GET_USER_APPS_SUCCESS";
export const GET_USER_APPS_FAILED = "GET_USER_APPS_FAILED";

// AppsReleases
export const GET_APPS_DATA = "GET_APPS_DATA";
export const GET_APPS_DATA_SUCCESS = "GET_APPS_DATA_SUCCESS";
export const GET_APPS_DATA_FAILED = "GET_APPS_DATA_FAILED";
export const GET_RELEASES = "GET_RELEASES";
export const GET_RELEASES_SUCCESS = "GET_RELEASES_SUCCESS";
export const GET_RELEASES_FAILED = "GET_RELEASES_FAILED";
export const SET_RELEASE_STATE = "SET_RELEASE_STATE";
export const SET_RELEASE_PARENT_ID = "SET_RELEASE_PARENT_ID";
export const ADD_NEW_APPS = "ADD_NEW_APPS";
export const ADD_NEW_APPS_SUCCESS = "ADD_NEW_APPS_SUCCESS";
export const ADD_NEW_APPS_FAILED = "ADD_NEW_APPS_FAILED";
export const UPDATE_APPS = "UPDATE_APPS";
export const UPDATE_APPS_SUCCESS = "UPDATE_APPS_SUCCESS";
export const UPDATE_APPS_FAILED = "UPDATE_APPS_FAILED";
export const UPDATE_RELEASES = "UPDATE_RELEASES";
export const UPDATE_RELEASES_SUCCESS = "UPDATE_RELEASES_SUCCESS";
export const UPDATE_RELEASES_FAILED = "UPDATE_RELEASES_FAILED";

// Content Data Gateway
export const GET_CONTENT_DATA_FILES = "GET_APPS_DATA";
export const GET_CONTENT_DATA_FILES_SUCCESS = "GET_CONTENT_DATA_FILES_SUCCESS";
export const GET_CONTENT_DATA_LINK = "GET_CONTENT_DATA_LINK";
export const GET_CONTENT_DATA_LINK_SUCCESS = "GET_CONTENT_DATA_LINK_SUCCESS";
