/* eslint-disable react/prop-types */
import React from "react";
import { Table, Button, Divider, Popconfirm } from "antd";

export default function RepresentativeVideoRectTable({
  rects,
  videoHeight,
  selectedRect,
  setCurrentVideoFrame,
  setSelectedRect,
  setRectStartFrame,
  setRectEndFrame,
  removeRect
}) {
  const columns = [
    {
      title: "Rect ID",
      dataIndex: "name",
      key: "name",
      width: 100
    },
    {
      title: "Start Frame",
      dataIndex: "startFrame",
      key: "startFrame",
      width: 200
    },
    {
      title: "End frame",
      dataIndex: "endFrame",
      key: "endFrame",
      width: 200
    },
    {
      title: "Operations",
      dataIndex: "operations",
      render: (text, record) => (
        <span>
          <Button type="default" onClick={() => setRectStartFrame(record.name)}>
            Set Start Frame
          </Button>
          <Divider type="vertical" />
          <Button type="default" onClick={() => setRectEndFrame(record.name)}>
            Set End Frame
          </Button>
          <Divider type="vertical" />
          <Popconfirm
            title="This will remove the anonymization rectangle!"
            onConfirm={() => removeRect(record.name)}
          >
            <Button type="danger" icon="delete" />
          </Popconfirm>
        </span>
      )
    }
  ];
  return (
    <Table
      className="representative-video-tab__rect-table"
      dataSource={rects}
      columns={columns}
      pagination={false}
      scroll={{ y: videoHeight }}
      onRow={(record, rowIndex) => ({
        onClick: event => {
          if (event.target.tagName !== "BUTTON") {
            setCurrentVideoFrame(record.startFrame);
            setSelectedRect(record.name);
          }
        }
      })}
      rowClassName={(record, index) => {
        if (record.name === selectedRect) {
          return "representative-video-tab__rect-table-row representative-video-tab__rect-table__highlight-row";
        }
        return "representative-video-tab__rect-table-row";
      }}
    />
  );
}
