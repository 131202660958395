import React from "react";
import { Modal, Form, Input } from "antd";

class MarkAsBadForm extends React.Component {
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Item>
          {getFieldDecorator("reason", {
            rules: [
              {
                required: true
              }
            ]
          })(<Input.TextArea placeholder="Reason" />)}
        </Form.Item>
      </Form>
    );
  }
}

const WrappedMarkAsBadForm = Form.create({ name: "mark-as-bad-form" })(
  MarkAsBadForm
);

export default function MarkAsBadModal({ handleHide, handleSumit }) {
  let formRef = null;

  return (
    <Modal
      // footer={null}
      onCancel={handleHide}
      onOk={e => {
        if (formRef) {
          formRef.props.form.validateFields((errors, values) => {
            if (errors) {
              return;
            }
            handleSumit(
              `video marked as bad. ${formRef.props.form.getFieldValue(
                "reason"
              )}`
            );
            handleHide();
          });
        }
      }}
      destroyOnClose={true}
      visible={true}
      title="Mark the sample as bad"
    >
      <WrappedMarkAsBadForm
        wrappedComponentRef={form => {
          formRef = form;
        }}
      />
    </Modal>
  );
}
