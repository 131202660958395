import * as AT from "../actionTypes";
import { NETWORK_LABELS } from "../../constants/networkLabels";

const ANON_BACKEND = "https://video-anonymize.watchful.ai";
//const ANON_BACKEND = "http://lvh.me:5000";

const l = console.log;

export const getAnnotationsSuccess = (data, error) => ({
  type: AT.LOAD_ANNOTATIONS_SUCCESS,
  payload: { data, error }
});

export const getAnnotationQuotasSuccess = (data, error) => ({
  type: AT.LOAD_ANNOTATION_QUOTAS_SUCCESS,
  payload: { data, error }
});

export const getAutoTagStatusSuccess = (filterArgs, data, error) => {
  const rt = {
    type: AT.LOAD_AUTOTAG_STATUS_SUCCESS,
    payload: {
      data,
      request: filterArgs,
      error
    }
  };
  return rt;
};

export const getAutoTagStatus = filterArgs => {
  const {
    app,
    deviceModels,
    location,
    dateFrom,
    dateTo,
    groupByDay,
    groupByDev,
    groupByEv
  } = filterArgs;
  let rt = {
    type: AT.API_REQUEST,
    payload: {
      url: `performance/annotations/autotag?sample_date_from=${dateFrom}&sample_date_to=${dateTo}&group_by_day=${groupByDay}&group_by_dev=${groupByDev}&group_by_ev=${groupByEv}&app=${app}&deviceModels=${deviceModels}&location=${location}`,
      onSuccess: (body, dispatch) => {
        dispatch(getAutoTagStatusSuccess(filterArgs, body));
      },
      onFailure: err => {
        console.log("failed to get autotag", err);
      }
    }
  };
  return rt;
};

export const getAnnotations = (
  date_from,
  date_to,
  deviceModels,
  app,
  annotators,
  events,
  states,
  types,
  showOutliers
) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `performance/annotations?date_from=${date_from}&date_to=${date_to}&device_models=${deviceModels}&app=${app}&annotators=${annotators}&events=${events}&states=${states}&types=${types}&show_outliers=${showOutliers}`,
    onSuccess: (body, dispatch) => {
      dispatch(getAnnotationsSuccess(body));
    },
    onFailure: error => console.log("failed to load raw annotations", error)
  }
});

export const getAnnotationQuotas = args => {
  const {
    filteredApps,
    dateFrom,
    dateTo,
    deviceModels,
    osVersions,
    location,
    events
  } = args;
  return {
    type: AT.API_REQUEST,
    payload: {
      url:
        `performance/annotations/stats?filtered_apps=${
          filteredApps ? filteredApps : ""
        }` +
        `&sample_date_from=${dateFrom}&sample_date_to=${dateTo}` +
        `&devices=${deviceModels}&os_versions=${osVersions}` +
        `&location=${location}&events=${events}`,
      onSuccess: (body, dispatch) =>
        dispatch(getAnnotationQuotasSuccess({ args: args, ...body })),
      onFailure: error =>
        console.log(
          `failed to load annotation quotas ${error.message ||
            error.response.text}`
        )
    }
  };
};

export const getAnonymizationQueue = () => ({
  type: AT.GET_ANONYMIZATION_QUEUE
});

export const getAnonymizationQueueSuccess = (data, error) => ({
  type: AT.GET_ANONYMIZATION_QUEUE_SUCCESS,
  payload: { data, error }
});

export const getAnonymizationQueueAPI = () => ({
  type: AT.API_REQUEST,
  payload: {
    url: ANON_BACKEND + "/anon/queue",
    outServer: true,
    onSuccess: (body, dispatch) => dispatch(getAnonymizationQueueSuccess(body)),
    onFailure: error =>
      console.log(`failed loading the anonymization queue, error: ${error}`)
  }
});

export const getAnonymizedSampleSuccess = (data, error) => ({
  type: AT.GET_ANONYMIZED_SAMPLE_SUCCESS,
  payload: { data, error }
});

export const getAnonymizedSample = sampleGuid => ({
  type: AT.API_REQUEST,
  payload: {
    url: ANON_BACKEND + `/anon/sample?guid=${sampleGuid}`,
    outServer: true,
    onSuccess: (body, dispatch) => dispatch(getAnonymizedSampleSuccess(body)),
    onFailure: error =>
      console.log(
        `failed to load anonymized sample ${error.message ||
          error.response.text}`
      )
  }
});

export const resetAnonymizedSample = () => ({
  type: AT.GET_ANONYMIZED_SAMPLE_SUCCESS,
  payload: { data: {} }
});

export const anonymizeVideo = (guid, rects) => ({
  type: AT.SEND_SAMPLE_FOR_ANONYMIZATION,
  payload: { guid, rects }
});

export const anonymizeVideoAPI = (guid, rects) => ({
  type: AT.API_REQUEST,
  payload: {
    url: ANON_BACKEND + "/anon/do_anonymize",
    outServer: true,
    method: "POST",
    data: {
      guid,
      rects,
      intensity: 30
    },
    onSuccess: (body, dispatch) =>
      console.log(
        `sample sent for anonymization. Guid: ${guid}, rects: ${rects}`
      ),
    onFailure: error =>
      console.log(
        `failed to load anonymized sample ${error.message ||
          error.response.text}`
      )
  }
});

export const setSampleRepresentativeState = (guid, isRepresentative) => ({
  type: AT.API_REQUEST,
  payload: {
    url:
      ANON_BACKEND +
      `/anon/representative?guid=${guid}&value=${Number(isRepresentative)}`,
    outServer: true,
    method: "GET",
    onSuccess: (body, dispatch) =>
      console.log(
        `successfully set anonymized sample representative state, guid: ${guid}, isRepresentative: ${isRepresentative}`
      ),
    onFailure: error =>
      console.log(
        `failed to set anonymized sample representative state ${error.message ||
          error.response.text}`
      )
  }
});

export const loadPerformance = ({ app, limit, error }) => ({
  type: AT.LOAD_PERFORMANCE,
  payload: { app, limit, error }
});

export const loadPerformanceAPI = ({ app, limit, error }) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `performance/list?packageName=${app}&limit=${limit}&error=${error}`,
    label: NETWORK_LABELS.LOAD_PERFORMANCE,
    onSuccess: (body, dispatch) =>
      dispatch(loadPerformanceSuccess(body, error)),
    onFailure: error =>
      console.log(
        `Failed to load performance: ${error.message || error.response.text}`
      )
  }
});

export const loadPerformanceSuccess = (data, error) => ({
  type: AT.LOAD_PERFORMANCE_SUCCESS,
  payload: { data, error }
});

export const loadSignatureVideos = ({
  app,
  location,
  guids,
  limit,
  fromDate,
  toDate,
  documentVersion,
  deviceIds,
  deviceModels,
  osVersions,
  nonStrictMode,
  sampleState
}) => ({
  type: AT.LOAD_SIGNATURE_VIDEOS,
  payload: {
    app,
    location,
    guids,
    limit,
    fromDate,
    toDate,
    documentVersion,
    deviceIds,
    deviceModels,
    osVersions,
    nonStrictMode,
    sampleState
  }
});

export const loadSignatureVideosAPI = ({
  app,
  location,
  guids,
  limit,
  fromDate,
  toDate,
  documentVersion,
  deviceIds,
  deviceModels,
  osVersions,
  nonStrictMode,
  sampleState
}) => {
  let requestURL = `performance/annotations/pending?limit=${limit}&package_name=${app}`;
  if (sampleState) {
    requestURL += `&sample_state=${sampleState}`;
  }
  if (fromDate) {
    requestURL += `&from_date=${fromDate.utc().toISOString()}`;
  }
  if (toDate) {
    requestURL += `&to_date=${toDate.utc().toISOString()}`;
  }
  if (documentVersion) {
    requestURL += `&document_version=${documentVersion}`;
  }
  if (deviceIds && deviceIds.length > 0) {
    requestURL += `&device_ids=${deviceIds.join(",")}`;
  }
  if (location) {
    requestURL += `&location=${location}`;
  }
  if (deviceModels && deviceModels.length > 0) {
    requestURL += `&device_models=${deviceModels
      .map(model => encodeURIComponent(model.trim()))
      .join(",")}`;
  }
  if (osVersions && osVersions.length > 0) {
    requestURL += `&os_versions=${osVersions
      .map(osVer => encodeURIComponent(osVer.trim()))
      .join(",")}`;
  }
  if (guids && guids.length > 0) {
    requestURL += `&guids=${guids
      .map(guid => guid.replace(/\s/g, ""))
      .join(",")}`;
  }
  return {
    type: AT.API_REQUEST,
    payload: {
      url: requestURL,
      label: NETWORK_LABELS.LOAD_SIGNATURE_VIDEOS,
      onSuccess: (body, dispatch) =>
        dispatch(loadSignatureVideosSuccess(body, nonStrictMode)),
      onFailure: (error, dispatch) => dispatch(apiRequestFailed(error))
    }
  };
};

export const loadSignatureVideosSuccess = (data, nonStrictMode, error) => ({
  type: AT.LOAD_SIGNATURE_VIDEOS_SUCCESS,
  payload: { data, nonStrictMode, error }
});

export const saveAnnotatedEvents = (annotatedEvents, check) => ({
  type: AT.SAVE_ANNOTATED_EVENTS,
  payload: { annotatedEvents, check }
});

export const saveAnnotatedEventsAPI = (annotatedEvents, check) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `performance/annotations?check=${check ? 1 : 0}`,
    data: annotatedEvents,
    method: "POST",
    label: NETWORK_LABELS.SAVE_ANNOTATED_EVENTS,
    onSuccess: (body, dispatch) => {
      dispatch(saveAnnotatedEventsSuccess(body));
      if (check) {
        dispatch(checkAnnotatedEventsSuccess(body));
      }
    },
    onFailure: (error, dispatch) => {
      console.log(
        `Failed to save annotated events: ${error.message ||
          error.response.text}`
      );
      if (check && error.response.statusCode !== 500) {
        dispatch(checkAnnotatedEventsFailed(error.response.body.information));
      } else {
        dispatch(apiRequestFailed(error));
      }
    }
  }
});

export const saveAnnotatedEventsSuccess = () => ({
  type: AT.SAVE_ANNOTATED_EVENTS_SUCCESS
});

export const checkAnnotatedEventsSuccess = () => ({
  type: AT.CHECK_ANNOTATED_EVENTS_SUCCESS
});

export const checkAnnotatedEventsFailed = error => ({
  type: AT.CHECK_ANNOTATED_EVENTS_FAILED,
  payload: { error }
});

export const setPerformance = (data, type) => ({
  type: AT.SET_PERFORMANCE,
  payload: { data, type }
});

export const updatePerformance = data => ({
  type: AT.UPDATE_PERFORMANCE,
  payload: { data }
});

export const updatePerformanceAPI = ({ data }) => ({
  type: AT.API_REQUEST,
  payload: {
    url: "performance/update",
    label: NETWORK_LABELS.SAVE_PERFORMANCE,
    method: "POST",
    data,
    onSuccess: (body, dispatch) => dispatch(updatePerformanceSuccess(body)),
    onFailure: (error, dispatch) =>
      dispatch(updatePerformanceFailed(error.message, error.response.text))
  }
});

export const updatePerformanceSuccess = () => ({
  type: AT.UPDATE_PERFORMANCE_SUCCESS,
  payload: { message: "Saved Successfully!" }
});

export const updatePerformanceFailed = (message, text) => ({
  type: AT.UPDATE_PERFORMANCE_FAILED,
  payload: { message, text }
});

export const initStatus = () => ({
  type: AT.INIT_PERFORMANCE_STATUS
});

export const setValidateForm = form => ({
  type: AT.SET_VALIDATE_FORM,
  payload: { form }
});

export const setErrorsForm = form => ({
  type: AT.SET_ERRORS_FORM,
  payload: { form }
});

export const getAppsList = () => ({
  type: AT.GET_APPS_LIST
});

export const getEvents = () => ({
  type: AT.API_REQUEST,
  payload: {
    url: "performance/events",
    onSuccess: (body, dispatch) => dispatch(getEventsSuccess(body)),
    onFailure: error => console.log(error.message, error.response.text)
  }
});

export const getEventsSuccess = (data, error) => ({
  type: AT.GET_EVENTS_SUCCESS,
  payload: { data: data, error: error }
});

export const getAppsListAPI = () => ({
  type: AT.API_REQUEST,
  payload: {
    url: "performance/apps",
    label: NETWORK_LABELS.GET_APPS_LIST,
    onSuccess: (body, dispatch) => dispatch(getAppsListSuccess(body)),
    onFailure: error => console.log(error.message, error.response.text)
  }
});

export const getAppsListSuccess = list => ({
  type: AT.GET_APPS_LIST_SUCCESS,
  payload: { list: list.apps.filter(app => app.performance_events.length > 0) }
});

export const verifyPendingSampleAPI = ({ sampleDocID }) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `performance/annotations/verify?doc_id=${sampleDocID}`,
    label: NETWORK_LABELS.VERIFY_PENDING_ANNOTATION,
    onSuccess: (body, dispatch) =>
      dispatch(verifyPendingSampleSuccess(sampleDocID)),
    onFailure: (error, dispatch) => dispatch(apiRequestFailed(error))
  }
});
export const verifyPendingSampleSuccess = sampleDocID => ({
  type: AT.VERIFY_PENDING_ANNOTATION_SUCCESS,
  sampleDocID
});
export const unsetBadVideoForSampleAPI = ({ sampleDocID }) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `performance/unset_corrupted_sample?doc_id=${sampleDocID}`,
    label: NETWORK_LABELS.UNSET_BAD_VIDEO_FOR_SAMPLE,
    onSuccess: (body, dispatch) => dispatch(unsetBadVideoForSampleSuccess()),
    onFailure: (error, dispatch) => dispatch(apiRequestFailed(error))
  }
});

export const unsetBadVideoForSampleSuccess = () => ({
  type: AT.UNSET_BAD_VIDEO_FOR_SAMPLE_SUCCESS
});

export const setBadVideoForSampleAPI = ({ sampleDocID, reason }) => ({
  type: AT.API_REQUEST,
  payload: {
    method: "PUT",
    data: { reason },
    url: `performance/set_corrupted_sample?doc_id=${sampleDocID}`,
    label: NETWORK_LABELS.SET_BAD_VIDEO_FOR_SAMPLE,
    onSuccess: (body, dispatch) => dispatch(setBadVideoForSampleSuccess()),
    onFailure: (error, dispatch) => dispatch(apiRequestFailed(error))
  }
});

export const setBadVideoForSampleSuccess = () => ({
  type: AT.SET_BAD_VIDEO_FOR_SAMPLE_SUCCESS
});

export const getDeviceModels = () => ({
  type: AT.GET_DEVICE_MODELS
});

export const getDeviceModelsAPI = () => ({
  type: AT.API_REQUEST,
  payload: {
    url: "performance/device_models",
    label: NETWORK_LABELS.GET_DEVICE_MODELS,
    onSuccess: (body, dispatch) => dispatch(getDeviceModelsSuccess(body)),
    onFailure: error => console.log(error.message, error.response.text)
  }
});

export const getDeviceModelsSuccess = list => ({
  type: AT.GET_DEVICE_MODELS_SUCCESS,
  payload: { deviceModels: list }
});

export const apiRequestFailed = error => {
  console.log(error.message, error.response);
  return {
    type: AT.API_REQUEST_FAILED,
    payload: { error }
  };
};

export const resetApiRequestError = () => ({
  type: AT.API_REQUEST_RESET_ERROR
});
