import React, { Component } from "react";
import { isEqual } from "lodash";
import { message, List, Button } from "antd";

const ITEM_TYPES = Object.freeze({ EVENT: "EVENT", SUBEVENT: "SUBEVENT" });

export default class AnnotatorEventsSubeventsBoxes extends Component {
  componentDidMount() {
    this.setupPage();
  }

  componentDidUpdate(prevProps) {
    if (
      !isEqual(prevProps.rawSampleEventsArray, this.props.rawSampleEventsArray)
    ) {
      this.setupPage();
    } else {
      // if (!isEqual(prevProps.sampleEventsArray, this.props.sampleEventsArray)) {
      //   const newSelectedEvent = this.props.sampleEventsArray.find(
      //     event => event.id == this.state.selectedEvent.id
      //   );
      //   const newSelectedSubevent = newSelectedEvent.subevents.find(
      //     subevent => subevent.id == this.state.selectedSubevent.id
      //   );
      //   this.handleListItemSelection(newSelectedEvent, ITEM_TYPES.EVENT);
      //   this.handleListItemSelection(newSelectedSubevent, ITEM_TYPES.SUBEVENT);
      // }
    }
  }

  setupPage = () => {
    this.handleListItemSelection(
      this.props.sampleEventsArray[0],
      ITEM_TYPES.EVENT
    );
  };

  getEventListItemColorClass = event => {
    let itemClassName = "not-tagged";
    const previousEvent = this.getPreviousEvent(event);
    if (
      this.props.ignoreSubevents &&
      previousEvent &&
      !this.isEventHasEndingTime(previousEvent)
    ) {
      return (itemClassName += " disabled");
    }
    if (this.isEventHasEndingTime(event)) {
      itemClassName = this.props.ignoreSubevents
        ? "fully-tagged"
        : "partially-tagged";
      if (
        event.subevents.every(subevent => this.isEventHasEndingTime(subevent))
      ) {
        itemClassName = "fully-tagged";
      }
    }
    if (
      this.props.selectedEvent.name == event.name ||
      (this.props.selectedEvent.id && this.props.selectedEvent.id == event.id)
    ) {
      itemClassName += " selected";
    }
    return itemClassName;
  };

  getSubeventListItemColorClass = subevent => {
    let itemClassName = "not-tagged";
    if (subevent.end_frame_index && subevent.end_ts_s) {
      itemClassName = "fully-tagged";
    }
    if (
      this.props.selectedSubevent.name == subevent.name ||
      (this.props.selectedSubevent.id &&
        this.props.selectedSubevent.id == subevent.id)
    ) {
      itemClassName += " selected";
    }
    return itemClassName;
  };

  handleListItemSelection = (item, itemType) => {
    switch (itemType) {
      // eslint-disable-next-line no-case-declarations
      case ITEM_TYPES.EVENT:
        const currentEventIndex = this.props.sampleEventsArray.findIndex(
          event => event.id == item.id
        );
        const nextEvent = this.props.sampleEventsArray.find(
          (event, index, array) => {
            if (currentEventIndex + 1 < array.length) {
              // if the current event is not the last one, return the next event
              if (index == currentEventIndex + 1) {
                return true;
              }
            } else {
              // if the current event is the last one, return the current event
              if (index == currentEventIndex) {
                return true;
              }
            }
            return false;
          }
        );

        if (this.props.handleEventSelection) {
          this.props.handleEventSelection(item, nextEvent);
        }
        if (this.props.handleSubeventSelection && item.subevents.length > 0) {
          this.props.handleSubeventSelection(
            this.props.ignoreSubevents
              ? item.subevents.find(subevent => subevent.name == "end_time")
              : item.subevents[0]
          );
        }
        break;

      case ITEM_TYPES.SUBEVENT:
        if (this.props.handleSubeventSelection) {
          this.props.handleSubeventSelection(item);
        }
        break;

      default:
        break;
    }
  };

  isEventHasEndingTime = e => e.end_frame_index && e.end_ts_s;

  getPreviousEvent = e => {
    const currentEventIndex = this.props.sampleEventsArray.findIndex(
      event => event.id == e.id
    );
    if (currentEventIndex - 1 >= 0) {
      return this.props.sampleEventsArray[currentEventIndex - 1];
    }
    return null;
  };

  getNextEvent = e => {
    const currentEventIndex = this.props.sampleEventsArray.findIndex(
      event => event.id == e.id
    );
    if (currentEventIndex + 1 <= this.props.sampleEventsArray.length) {
      return this.props.sampleEventsArray[currentEventIndex + 1];
    }
    return null;
  };

  /** DEPRECATED: We are no longer checking if one sub event is being tagged before another */
  // canCaptureFrame = () => {
  //   const { selectedEvent, selectedSubevent } = this.state;
  //   const selectedSubeventIndex = selectedEvent.subevents.findIndex(
  //     subevent => subevent.id == selectedSubevent.id
  //   );
  //   const elementsBeforeIndex = selectedEvent.subevents
  //     .slice(0, selectedSubeventIndex)
  //     .filter(subevent => subevent.end_frame_index);
  //   const elementsAfterIndex = selectedEvent.subevents
  //     .slice(selectedSubeventIndex + 1)
  //     .filter(subevent => subevent.end_frame_index);

  //   if (
  //     elementsBeforeIndex.length != 0 &&
  //     this.props.currentScrubberFrame <
  //       elementsBeforeIndex[elementsBeforeIndex.length - 1].end_frame_index
  //   ) {
  //     return false;
  //   }
  //   if (
  //     elementsAfterIndex.length != 0 &&
  //     this.props.currentScrubberFrame > elementsAfterIndex[0].end_frame_index
  //   ) {
  //     return false;
  //   }
  //   return true;
  // };
  /** DEPRECATED: We are no longer checking if one sub event is being tagged before another */

  canCaptureFrame = () => {
    const { selectedEvent, selectedSubevent } = this.props;
    if (
      selectedSubevent.id != 0 &&
      selectedEvent.subevents[0].id == 0 &&
      selectedEvent.subevents[0].end_frame_index
    ) {
      if (
        this.props.currentScrubberFrame >
        selectedEvent.subevents[0].end_frame_index
      ) {
        return false;
      }
    }
    return true;
  };

  render() {
    const { selectedEvent, selectedSubevent } = this.props;
    const { sampleEventsArray } = this.props;
    if (!selectedEvent || !sampleEventsArray) {
      return null;
    }

    let viewableSubeventList = []; // this is the same as the subeventslist but with the end time in the beginning

    const _curEvent = sampleEventsArray.find(
      event => event.id == selectedEvent.id || event.name == selectedEvent.name
    );
    let subevents = _curEvent && _curEvent.subevents;

    const subeventsClone = [...(subevents || [])];
    const lastEvent = subeventsClone.pop();
    subeventsClone.unshift(lastEvent);
    viewableSubeventList = subeventsClone;
    if (subevents && subevents.length > 0 && this.props.ignoreSubevents) {
      // If we are ignoring the event subevents, filter them out and keep the end time subevent
      subevents = subevents.filter(subevent => subevent.id == 0);
    }
    return (
      <div className="events-subevents">
        <div className="events">
          <List
            header={<div>Events</div>}
            locale={{ emptyText: "No Events" }}
            bordered
            dataSource={sampleEventsArray}
            renderItem={item => (
              <List.Item
                className={`list-item ${this.getEventListItemColorClass(item)}`}
                onClick={() => {
                  if (
                    this.props.ignoreSubevents &&
                    this.getPreviousEvent(item) &&
                    !this.isEventHasEndingTime(this.getPreviousEvent(item))
                  ) {
                    message.error(
                      "Can't select next event before tagging the current event!"
                    );
                    return null;
                  }
                  return this.handleListItemSelection(item, ITEM_TYPES.EVENT);
                }}
              >{`${item.title}(${item.name})`}</List.Item>
            )}
          />
        </div>
        <div className="subevents">
          <List
            header={<div>Subevents</div>}
            locale={{ emptyText: "No Subevents" }}
            bordered
            dataSource={subevents}
            renderItem={item => (
              <List.Item
                className={`list-item ${this.getSubeventListItemColorClass(
                  item
                )}`}
                onClick={() =>
                  this.handleListItemSelection(item, ITEM_TYPES.SUBEVENT)
                }
              >{`${item.title}(${item.name})`}</List.Item>
            )}
          />
        </div>
        <div className="utills">
          <div>
            <b>Event Description: </b>
            {this.props.selectedEvent.description}
          </div>
          <div>
            <b>Subevent Description: </b>
            {this.props.selectedSubevent.description}
          </div>
          <div>
            <Button
              disabled={this.props.readonly}
              block
              type="primary"
              onClick={() =>
                this.props.handleFrameCapture(selectedEvent, selectedSubevent)
              }
            >
              Capture Frame
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

AnnotatorEventsSubeventsBoxes.defaultProps = {
  ignoreSubevents: false
};
