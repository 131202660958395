import * as actionTypes from "../actionTypes/index";

const INIT_STATE = {
  status: ""
};

export default function(state = INIT_STATE, action) {
  switch (action.type) {
    case actionTypes.LOGIN_SUCCESS: {
      return {
        ...state,
        status: action.payload.message
      };
    }
    case actionTypes.LOGIN_FAILED: {
      return {
        ...state,
        status: action.payload.message
      };
    }
    default: {
      return state;
    }
  }
}
