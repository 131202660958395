import React, { Component } from "react";
import moment from "moment";
import { Button, DatePicker, Select, Modal, Switch, Input } from "antd";
const { Option } = Select;
import {
  VictoryChart,
  VictoryTheme,
  VictoryScatter,
  VictoryTooltip,
  VictorySelectionContainer,
  VictoryGroup
} from "victory";
import { connect } from "react-redux";
import { Route, withRouter, Link } from "react-router-dom";
import { diffString, diff } from "json-diff";
import { stringify, parse } from "qs";
import {
  getAnnotations,
  getAnnotationsSuccess,
  getAppsList,
  getDeviceModels,
  setBadVideoForSampleAPI
} from "../../../redux/actions/performancePage";

const l = console.log;
const toTitleCase = s =>
  s.substr(0, 1).toUpperCase() + s.substr(1).toLowerCase();

Math.seededRandom = function(max, min) {
  max = max || 1;
  min = min || 0;
  Math.seed = (Math.seed * 9301 + 49297) % 233280;
  var rnd = Math.seed / 233280;
  return min + rnd * (max - min);
};

const arrAvg = arr => arr.reduce((a, b) => a + b, 0) / arr.length;

const SEQANNOTATOR_URL = "/performance/sequential-annotator?";
const SEQANNOTATOR_MAX_GUIDS = 20;

class Chart extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    if (this.props.scales)
      return <VictoryChart {...this.props}>{this.props.children}</VictoryChart>;
    else
      return <VictoryGroup {...this.props}>{this.props.children}</VictoryGroup>;
  }
}
class AnnotationsGraph extends Component {
  aggfields = [
    "type",
    "package_name",
    "device",
    "annotator",
    "event",
    "state",
    "error_reason",
    "error_source",
    "is_outlier",
    "guid"
  ];
  constructor(props) {
    super(props);
    const query = parse(this.props.history.location.search.replace(/^\?/, ""));
    this.state = {
      selectopts: {
        events: [],
        annotators: [],
        states: [],
        types: ["event", "annotation"]
      },
      annotators: [],
      date_from: query.date_from
        ? query.date_from
        : moment
            .utc()
            .subtract(3, "days")
            .format("YYYY-MM-DD"),
      date_to: query.date_to ? query.date_to : null,
      idx: query.idx ? parseInt(query.idx) : 0,
      maxx: query.maxx,
      maxy: query.maxy,
      minx: query.minx,
      miny: query.miny,
      showScales: true,
      showOutliers: query.showOutliers === "true" ? true : false,
      types: query.types,
      colorCodeByPrefix: "Color code by",
      deviceModels: query.deviceModels,
      states: query.states,
      app: query.app,
      colorCodeBy: query.colorCodeBy
        ? query.colorCodeBy
        : ["package_name", "event"],
      annotators: query.annotators ? query.annotators : [],
      events: query.events ? query.events : [],
      guid: query.guid
    };
  }
  getAnnotations = () => {
    //l('getting annotations with state',this.state);
    this.props.getAnnotations(
      this.state.date_from && this.state.date_from,
      this.state.date_to && this.state.date_to,
      this.state.deviceModels,
      this.state.app,
      this.state.annotators,
      this.state.events,
      this.state.states,
      this.state.types,
      this.state.showOutliers
    );
  };
  componentDidMount() {
    if (!this.props.annotations) this.getAnnotations();
    if (!this.props.appsList.length) {
      this.props.getAppsList();
    }
    if (!this.props.deviceModels.length) {
      this.props.getDeviceModels();
    }
  }
  updrouter = () => {
    let fns = [
      "date_from",
      "date_to",
      "colorCodeBy",
      "maxx",
      "maxy",
      "minx",
      "miny",
      "deviceModels",
      "idx",
      "app",
      "events",
      "annotators",
      "showOutliers",
      "types",
      "states",
      "guid"
      //'error_reason'
    ];
    let formvals = {};
    for (let fn of fns) formvals[fn] = this.state[fn];
    const qry = stringify(formvals);
    this.props.history.push("/performance/graphs?" + qry);
  };
  componentDidUpdate(prevProps, prevState) {
    let ds = diff(prevState, this.state);
    if (ds) this.updrouter();
    return true;
  }
  getSeedFromString(label) {
    let input = this.state.colorCodeByPrefix + label;
    var seed = 0,
      rt;
    if (typeof input != "string") rt = input;
    else {
      for (var i = 0; i < input.length; i++) seed += input.charCodeAt(i);
      rt = seed;
    }
    return rt;
  }

  genLabel(d) {
    let lbls = Object.entries(d).filter(
      ([k, v]) => this.state.colorCodeBy.indexOf(k) !== -1
    ); //.map(x => x[1]);
    let o = {};
    for (let [k, v] of lbls) o[k] = v;
    let label = JSON.stringify(o); //lbls.join(':');
    return label;
  }
  getColor(lbl, ci, cq, cd, d) {
    let rt = this.getColorFromSeed(this.getSeedFromString(lbl));
    if (ci) ci[rt] = lbl;
    if (cq && !cq[rt]) cq[rt] = 0;
    if (cq) cq[rt]++;
    if (cd && !cd[rt]) cd[rt] = [];
    if (d) cd[rt].push(d.duration);
    //if (lbl.includes('6cc77b8')) { throw 'at lbl '+lbl+' which returns '+rt; };
    return rt;
  }

  getColorFromSeed(seed) {
    Math.seed = seed;
    let rt,
      luma,
      cnt = 0;
    while (!luma || luma > 200 || rt.length < 6) {
      rt = Math.floor(Math.seededRandom() * (16777215 + cnt)).toString(16);
      // from https://stackoverflow.com/questions/12043187/how-to-check-if-hex-color-is-too-black
      let rgb = parseInt(rt, 16); // convert rrggbb to decimal
      let r = (rgb >> 16) & 0xff; // extract red
      let g = (rgb >> 8) & 0xff; // extract green
      let b = (rgb >> 0) & 0xff; // extract blue
      luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
      //l('generated',rt,r,g,b,'with luma',luma,'cnt',cnt);
      cnt++;
    }
    return "#" + rt;
  }

  getBoundries(data) {
    let boundries = {};
    let xs = data.map(a => a.x);
    let ys = data.map(a => a.y);
    boundries.maxx = this.state.maxx || Math.max(...xs);
    boundries.minx = this.state.minx || Math.min(...xs);
    boundries.maxy = this.state.maxy || Math.max(...ys);
    boundries.miny = this.state.miny || Math.min(...ys);
    return boundries;
  }

  setDate(fn, v) {
    l("setDate(", fn, v, ")");
    this.setState({ fn, v });
  }
  onDeviceModelsChange = value => {
    this.setState({ deviceModels: value });
  };
  onAppChange = value => {
    this.setState({ app: value });
  };

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  resetZoom = () => {
    let ns = {};
    for (let bn of ["maxx", "minx", "maxy", "miny"]) ns[bn] = null;
    this.setState(ns);
  };
  toggleFilter(k, v) {
    switch (k) {
      case "package_name":
        this.setState({ app: v });
        break;
      case "event":
      case "annotator":
      case "device":
      case "state":
      case "type":
        let mp = {
          event: "events",
          annotator: "annotators",
          device: "deviceModels",
          state: "states",
          type: "types"
        };
        let tof = mp[k];
        let evs = this.state[tof];
        if (!evs) evs = [];
        if (evs.indexOf(v) === -1) evs.push(v);
        else evs = evs.filter(x => x !== v);

        let ss = {};
        ss[tof] = evs;
        this.setState(ss);
        break;
      default:
        throw Error("unknown field " + k);
        break;
    }
  }
  renderForm(data, ddata) {
    let guids = [...new Set(ddata.map(d => d.guid))];

    return (
      <React.Fragment>
        {["from", "to"].map(lbl => (
          <label key={"date-" + lbl}>
            {toTitleCase(lbl)}
            &nbsp;
            <DatePicker
              value={
                this.state["date_" + lbl]
                  ? moment(this.state["date_" + lbl])
                  : null
              }
              onChange={date => {
                let ss = {};
                ss["date_" + lbl] = date ? date.format("YYYY-MM-DD") : null;
                this.setState(ss);
              }}
              format="DD/MM/YYYY"
            />
          </label>
        ))}
        &nbsp;
        <label>
          Device Models:{" "}
          <Select
            allowClear
            dropdownMatchSelectWidth={false}
            mode="tags"
            className="control-element"
            value={this.state.deviceModels}
            onChange={this.onDeviceModelsChange}
            tokenSeparators={[","]}
            style={{ minWidth: "100px", maxWidth: "150px" }}
          >
            {this.props.deviceModels.map(model => (
              <Option value={model.model} key={model.model}>
                {model.model}
              </Option>
            ))}
          </Select>
          &nbsp;
        </label>
        <label>
          App:{" "}
          <Select
            allowClear
            label="App"
            className="control-element"
            showSearch
            style={{ width: 100 }}
            placeholder="Select an app"
            value={this.state.app}
            optionFilterProp="children"
            onChange={this.onAppChange}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {this.props.appsList.map(app => (
              <Option value={app.package} key={app.name}>
                {app.name}
              </Option>
            ))}
          </Select>
        </label>
        {["events", "annotators", "states", "types"].map(fn => (
          <label key={fn}>
            &nbsp;
            {toTitleCase(fn)}:{" "}
            <Select
              allowClear
              label={fn}
              className="control-element"
              mode="tags"
              value={this.state[fn]}
              onChange={e => {
                let ss = {};
                ss[fn] = e;
                this.setState(ss);
              }}
            >
              {this.state.selectopts[fn].map(optfn => (
                <Option key={"fn-" + optfn} value={optfn}>
                  {optfn}
                </Option>
              ))}
            </Select>
          </label>
        ))}
        <label>
          <Switch
            checked={this.state.showOutliers}
            onChange={e => {
              this.setState({ showOutliers: !this.state.showOutliers });
            }}
          />
          Include outliers
        </label>
        <br />
        <Button onClick={this.getAnnotations}>Fetch annotations</Button>
        <span>
          {this.numberWithCommas(data.length)} fetched,{" "}
          {this.numberWithCommas(ddata.length)} displayed.
          {guids.length <= SEQANNOTATOR_MAX_GUIDS ? (
            <Link to={SEQANNOTATOR_URL + stringify({ guids: guids })}>
              Open {guids.length} distinct GUIDs in sequential annotator
            </Link>
          ) : (
            <span
              title={`Select a group of ${SEQANNOTATOR_MAX_GUIDS} or less distinct GUIDs to group-open in sequential annotator.`}
            >
              {guids.length} distinct GUIDs displayed.
            </span>
          )}
          {data.length > ddata.length && (
            <Button onClick={this.resetZoom}>Reset zoom</Button>
          )}
        </span>
      </React.Fragment>
    );
  }
  render() {
    let data = this.props.annotations
      ? this.props.annotations.annotations.map(a => ({
          x: new Date(a.started_at.$date),
          y: a.duration,
          ...a
          /*label:Object.entries(a).filter(f=>f[1] !== null).map(([k,v]) => k+': '+
							   (v && v.$date?new Date(v.$date):
							    v && v.$oid?v.$oid:
							    typeof v === 'string'?
							    v:
							    JSON.stringify(v) 
							   )
							   ).join("\n")*/
        }))
      : [];
    let ss, s;
    if (this.state.guid) ss = data.filter(d => d.guid === this.state.guid);
    //l('gotten',ss,'records with guid',this.state.guid);
    if (ss) s = ss[this.state.idx];

    let colorIndex = {},
      colorQty = {},
      colorDurations = {};

    let ddata = [];
    let boundries = this.getBoundries(data);
    for (let d of data) {
      if (
        !(
          d.x >= boundries.minx &&
          d.x <= boundries.maxx &&
          d.y >= boundries.miny &&
          d.y <= boundries.maxy
        )
      ) {
        continue;
      }
      this.getColor(this.genLabel(d), colorIndex, colorQty, colorDurations, d);
      ddata.push(d);
    }

    let colorQtySorted = Object.entries(colorQty).sort(
      (a, b) => (a[1] > b[1] ? -1 : 1)
    );
    let byguid;
    if (s) byguid = ddata.filter(d => d.guid === s.guid);
    return (
      <div>
        {this.renderForm(data, ddata)}
        {s && (
          <Modal
            footer={null}
            onCancel={ev => {
              this.setState({ guid: undefined });
            }}
            destroyOnClose="1"
            visible={true}
            title={"Sample GUID " + s.guid}
          >
            <button
              disabled={this.state.idx <= 0}
              onClick={() => {
                this.setState({ idx: this.state.idx - 1 });
              }}
            >
              prev
            </button>
            [{this.state.idx + 1} / {byguid.length}]
            <button
              disabled={this.state.idx >= byguid.length - 1}
              onClick={() => {
                this.setState({ idx: this.state.idx + 1 });
              }}
            >
              next
            </button>
            <table>
              <tbody>
                {Object.entries(s)
                  .filter(e => ["x", "y"].indexOf(e[0]) === -1 && e[1] !== null)
                  .map(([k, v]) => {
                    return (
                      <tr key={"modal-" + k}>
                        <td>{k}</td>
                        <td>
                          {v && v.$date
                            ? new Date(v.$date).toISOString()
                            : v && v.$oid
                              ? v.$oid
                              : typeof v === "string"
                                ? v
                                : typeof v === "number"
                                  ? v.toFixed(2)
                                  : JSON.stringify(v)}
                        </td>
                        {/*<td>{typeof v}</td>*/}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <Link
              to={
                SEQANNOTATOR_URL +
                stringify({
                  app: s.package_name,
                  "guids[0]": s.guid,
                  fetch: true
                })
              }
              target="_blank"
            >
              Open in sequential annotator
            </Link>
            <button
              style={{ float: "right" }}
              onClick={() => {
                l("setting", s.id.$oid, "as bad");
                this.props
                  .setBadVideoForSampleAPI({ sampleDocID: s.id.$oid })
                  .then(res => {
                    l("setbad returned", res);
                    this.setState({ guid: undefined });
                    this.getAnnotations();
                  });
              }}
            >
              Mark {byguid.length} annotation(s) as bad
            </button>
          </Modal>
        )}
        <div style={{ float: "right", maxWidth: "30%" }}>
          <label>
            <Switch
              checked={this.state.showScales}
              onChange={e => {
                this.setState({ showScales: !this.state.showScales });
              }}
            />
            Show scales
          </label>
          <br />
          <label>
            {/*<input value={this.state.colorCodeByPrefix} onChange={(e) => { this.setState({colorCodeByPrefix:e.target.value}); }}/>*/}
            {this.state.colorCodeByPrefix}
            <Select
              mode="tags"
              tokenSeparators={[","]}
              onChange={v => {
                this.setState({
                  /*colorIndex:{},
					  colorQty:{},*/
                  colorCodeBy: v
                });
              }}
              value={this.state.colorCodeBy}
            >
              {this.aggfields.map(o => (
                <Option key={"colorcodeopt-" + o} value={o}>
                  {o}
                </Option>
              ))}
            </Select>
          </label>
          <br />

          <table>
            <thead>
              <tr>
                <th>color</th>
                {this.state.colorCodeBy.map(cci => (
                  <th key={"cci-" + cci}>{cci}</th>
                ))}
                <th tooltip="average duration">avg</th>
                <th tooltip="amount displayed">qty</th>
              </tr>
            </thead>
            <tbody>
              {colorQtySorted.map(([col, qty]) => (
                <tr key={"ci-" + col}>
                  <td
                    style={{
                      height: "10px",
                      width: "20px",
                      backgroundColor: col
                    }}
                  />
                  {this.state.colorCodeBy.map(cci => {
                    let jsp = JSON.parse(colorIndex[col]);
                    //l('jsp of',col,'=',jsp,'cci=',cci,'is',jsp[cci]);
                    let val = jsp[cci];
                    return (
                      <td key={"cci-" + col + "-" + cci}>
                        <Button
                          onClick={() => {
                            this.toggleFilter(cci, val);
                          }}
                        >
                          {val}
                        </Button>
                      </td>
                    );
                  })}
                  <td style={{ textAlign: "right" }}>
                    {parseFloat(arrAvg(colorDurations[col])).toFixed(2)}
                  </td>
                  <td style={{ textAlign: "right" }}>{qty}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/*	 labelComponent={<VictoryTooltip />}*/}
        {ddata.length ? (
          <Chart
            scales={this.state.showScales}
            style={{ parent: { width: "70%" } }}
            events={[
              {
                childName: ["scatter"],
                target: "data",
                eventHandlers: {
                  onMouseDown: (e, cp) => {
                    //alert('clicked');
                    l("clicked on", cp.datum);
                    this.setState({ guid: cp.datum.guid });
                    //alert('hai'); l('clicked',e,cp.datum);
                  },
                  onMouseOver: (e, cp) => {
                    //l('mouseover!',e,cp);
                  }
                }
              }
            ]}
            containerComponent={
              <VictorySelectionContainer
                activateSelectedData={false}
                onSelection={e => {
                  l("onSelection", e);
                  let seldata = [...e].pop().data;
                  l("selection data is", seldata);
                  let bnd = this.getBoundries(seldata);
                  l("selection boundries are", bnd);
                  this.setState(bnd);
                }}
              />
            }
            domain={{
              x: [boundries.minx, boundries.maxx],
              y: [boundries.miny, boundries.maxy]
            }}
          >
            <VictoryScatter
              name="scatter"
              style={{
                data: {
                  fill: datum => {
                    //if (datum.datum.guid===this.state.guid) return 'gold';
                    let label = this.genLabel(datum.datum);
                    return this.getColor(label);
                  }
                }
              }}
              size={2}
              data={ddata}
            />
          </Chart>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = ({ performancePage }) => ({
  annotations: performancePage.annotations,
  appsList: performancePage.appsList,
  deviceModels: performancePage.deviceModels
});

const mapDispatchToProps = {
  getAnnotations,
  getAnnotationsSuccess,
  getAppsList,
  getDeviceModels,
  setBadVideoForSampleAPI
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AnnotationsGraph)
);
