import { combineReducers } from "redux";
import appsReducer from "./apps";
import performanceReducer from "./performancePage";
import loginReducer from "./login";
import contentDataReducer from "./contentData";

export default combineReducers({
  apps: appsReducer,
  performancePage: performanceReducer,
  loginData: loginReducer,
  contentDataGateway: contentDataReducer
});
