import * as AT from "../actionTypes";
import { NETWORK_LABELS } from "../../constants/networkLabels";
import { stringify } from "query-string";
import { apiRequestFailed } from "./performancePage";

// Content Data Gateway
export const getContentDataFiles = dir => ({
  type: AT.GET_CONTENT_DATA_FILES,
  payload: { dir }
});

export const getContentDataFilesAPI = dir => ({
  type: AT.API_REQUEST,
  payload: {
    url: `s3-gateway/content-data/files?${stringify({ dir })}`,
    label: NETWORK_LABELS.GET_CONTENT_DATA_FILES,
    onSuccess: (body, dispatch) =>
      dispatch(getContentDataFilesSuccess(dir, body)),
    onFailure: (error, dispatch) => dispatch(apiRequestFailed(error))
  }
});

export const getContentDataFilesSuccess = (dir, body) => ({
  type: AT.GET_CONTENT_DATA_FILES_SUCCESS,
  payload: {
    dir,
    files: body.data
  }
});

export const getContentDataLink = dir => ({
  type: AT.GET_CONTENT_DATA_LINK,
  payload: {
    dir
  }
});

export const getContentDataLinkAPI = dir => ({
  type: AT.API_REQUEST,
  payload: {
    url: `s3-gateway/content-data/download-url?${stringify({ dir })}`,
    label: NETWORK_LABELS.GET_CONTENT_DATA_LINK,
    onSuccess: (body, dispatch) =>
      dispatch(getContentDataLinkSuccess(dir, body)),
    onFailure: (error, dispatch) => dispatch(apiRequestFailed(error))
  }
});

export const getContentDataLinkSuccess = (dir, body) => ({
  type: AT.GET_CONTENT_DATA_LINK_SUCCESS,
  payload: {
    dir,
    downloadLink: body.download_url
  }
});
