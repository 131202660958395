import React, { Component } from "react";
import Auth from "@aws-amplify/auth";
import ReactJSONView from "react-json-view";

export default class CurrentToken extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUserToken: "",
      currentUserData: {}
    };
  }

  componentDidMount() {
    Auth.currentSession().then(sessionData => {
      this.setState({
        currentUserToken: sessionData.idToken.jwtToken,
        currentUserData: sessionData.idToken.payload
      });
    });
  }

  render() {
    return (
      <div style={{ padding: "20px" }}>
        <div>Current user JWT token is:</div>
        <textarea
          rows="15"
          cols="200"
          value={this.state.currentUserToken}
          readOnly
          style={{ resize: "none" }}
        />
        <br />
        <div>Current user JWT token data is:</div>
        <ReactJSONView
          src={this.state.currentUserData}
          shouldCollapse={false}
        />
      </div>
    );
  }
}
