import React from "react";
import { HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "redux/store";
import Amplify from "@aws-amplify/core";

import { withAuthenticator } from "aws-amplify-react";
import { AWS_CONFIG } from "../config/aws";
import Home from "./Home";

if (process.env.NODE_ENV === "development") {
  //require('mimic');
}

Amplify.configure({
  Auth: AWS_CONFIG
});

const App = () => (
  <HashRouter>
    <Provider store={store}>
      <Home />
    </Provider>
  </HashRouter>
);

// Change includeGreetings to true if you'd like to have a logout button
export default withAuthenticator(App, {
  includeGreetings: true
});
