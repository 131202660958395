import { handleActions } from "redux-actions";
import * as AT from "../actionTypes";

const initialState = {
  all: [],
  versions: {}
};

const appsReducer = handleActions(
  {
    [AT.SET_APPS]: (state, action) => ({
      ...state,
      all: action.payload.apps.filter(app => app.name)
    }),
    [AT.SET_APP_VERSIONS]: (state, action) => ({
      ...state,
      versions: {
        ...state.versions,
        [action.payload.appPackage]: action.payload.versions
      }
    })
  },
  initialState
);

export default appsReducer;
