/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, createRef, useEffect, useCallback } from "react";
import { Spin } from "antd";

function AnnotatorVideoDisplay({
  videoFrameRate = 0,
  currentVideoFrame = 0,
  videoURL,
  videoDurationInFrames,
  setVideoDurationInFrames
}) {
  let mainVideoRef = createRef();
  let behindVideoRef = createRef();
  const [videoLoading, setVideoLoading] = useState(true);
  const setDurationInFramesMemo = useCallback(frames => {
    setVideoDurationInFrames(frames);
  }, []);
  useEffect(
    () => {
      if (
        mainVideoRef &&
        mainVideoRef.current &&
        behindVideoRef &&
        behindVideoRef.current
      ) {
        mainVideoRef.current.src = videoURL;
        behindVideoRef.current.src = videoURL;
      }
    },
    [videoURL]
  );
  useEffect(
    () => {
      if (
        mainVideoRef &&
        mainVideoRef.current &&
        behindVideoRef &&
        behindVideoRef.current
      ) {
        mainVideoRef.current.currentTime = currentVideoFrame / videoFrameRate;
        behindVideoRef.current.currentTime =
          (currentVideoFrame - 1) / videoFrameRate;
      }
    },
    [currentVideoFrame]
  );
  useEffect(
    () => {
      if (mainVideoRef.current) {
        mainVideoRef.current.ondurationchange = function() {
          console.log("video duration updated!");
          setDurationInFramesMemo(Math.floor(this.duration * videoFrameRate));
        };
        mainVideoRef.current.onseeking = function() {
          setVideoLoading(true);
        };
        mainVideoRef.current.onseeked = function() {
          setVideoLoading(false);
        };
      }
    },
    [mainVideoRef.current]
  );
  return (
    <Spin tip="Loading..." spinning={videoLoading}>
      <div className="videos">
        <div className="video-wrapper">
          <div>
            Frame Number:{" "}
            <em>{currentVideoFrame === 0 ? 0 : currentVideoFrame - 1}</em>
          </div>
          <video id="behind-video" ref={behindVideoRef}>
            <source src={videoURL} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="video-wrapper">
          <div>
            Frame Number: <em>{currentVideoFrame}</em>
          </div>
          <video
            id="main-video"
            preload="auto"
            ref={mainVideoRef}
            style={{ border: "2px solid blue" }}
          >
            <source src={videoURL} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </Spin>
  );
}

export default AnnotatorVideoDisplay;
