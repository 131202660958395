import React, { useState, useEffect } from "react";
import { isEqual } from "lodash";
import { Select, DatePicker, InputNumber, Input, Checkbox, Button } from "antd";
import moment from "moment";
import { parse, stringify } from "qs";
import { LOCATIONS } from "../../../constants/annotationConsts";

const { Option } = Select;

export default function PerformanceToolbar({
  history,
  appsList,
  loadingData,
  allDeviceModels,
  numberOfSamples,
  nonStrictMode,
  loadSignatureVideos,
  resetPageData,
  forceReload,
  readonly
}) {
  const [state, setState] = useState({
    limit: 40,
    fromDate: moment("19:00", "HH:mm")
      .utc()
      .subtract(7, "days")
      .startOf("day"),
    toDate: moment("19:00", "HH:mm")
      .utc()
      .startOf("day")
  }); // define default values here
  useEffect(
    () => {
      // Sync url params to the state if exists
      const query = parse(history.location.search.replace(/^\?/, ""));
      if (query.fromDate) {
        query.fromDate = moment.utc(query.fromDate);
      }
      if (query.toDate) {
        query.toDate = moment.utc(query.toDate);
      }
      let app = appsList.find(a => a.package == query.app);
      if (app) {
        query.platform = app.platform;
        query.appName = app.name;
      }
      for (const k in query) {
        if (!query[k]) {
          delete query[k];
        }
      }
      const stateObj = Object.assign(state, query);
      setState(prevState => ({ ...prevState, ...stateObj }));
      if (!!state.fetch && isFormValid()) {
        fetchData();
      } else if (state.fetch) {
        setState(prevState => ({ ...prevState, fetch: false }));
      }
    },
    [history]
  );

  useEffect(
    () => {
      updrouter();
    },
    [state]
  );

  useEffect(() => {
    if (forceReload) {
      fetchData();
    }
  });

  const isFormValid = () => state.guids || !(!state.app || !state.fromDate);

  const updrouter = () => {
    const formvals = {
      app: state.app,
      fromDate: state.fromDate
        ? moment.utc(state.fromDate).toISOString()
        : undefined,
      toDate: state.toDate ? moment.utc(state.toDate).toISOString() : undefined,
      deviceModel: state.deviceModel,
      osVersion: state.osVersion,
      location: state.location,
      guids: state.guids,
      fetch: state.fetch,
      sampleState: state.sampleState,
      readonly: readonly
    };
    for (const k in formvals) {
      if (!formvals[k]) {
        delete formvals[k];
      }
    }

    const qry = stringify(formvals);

    /** This updrouter is being called on each time the state changes, only cares about the form values
     *  So in order to spare unnecessary renders, I'm comparing the current url query with the constructed one.
     *  Only if they are different we need to push the new url.
     *
     *  TODO: create a better url <-> state sync mechanism
     */
    const currentQry = parse(history.location.search.replace(/^\?/, ""));
    const parsedQry = parse(qry);
    if (isEqual(currentQry, parsedQry)) {
      return;
    }
    const newurl = `${history.location.pathname}?${qry}`;
    console.log(
      "updrouter: pushing",
      newurl,
      "qry=",
      qry,
      "formvals",
      formvals
    );
    history.push(newurl);
  };

  const fetchData = () => {
    resetPageData();
    setState(prevState => ({ ...prevState, fetch: true }));
    let processedArgs = {
      ...state,
      nonStrictMode
    };
    if (processedArgs.deviceModel) {
      processedArgs.deviceModels = [processedArgs.deviceModel];
      delete processedArgs["deviceModel"];
    }
    if (processedArgs.osVersion) {
      processedArgs.osVersions = [processedArgs.osVersion];
      delete processedArgs["osVersion"];
    }
    loadSignatureVideos(processedArgs);
  };

  /** Fonr Handlers */
  const onAppChange = value => {
    let [platform, app, appName] = value.split(",");
    console.log("onAppChange: changing (app) to", appName, platform, app);
    setState(prevState => ({ ...prevState, app, platform, appName }));
  };

  const onLimitChange = value => {
    setState(prevState => ({ ...prevState, limit: value }));
  };

  const onFromDateChange = date => {
    setState(prevState => ({ ...prevState, fromDate: date }));
  };

  const onToDateChange = date => {
    setState(prevState => ({ ...prevState, toDate: date }));
  };

  const onDocVersionChange = event => {
    event.persist();
    setState(prevState => ({
      ...prevState,
      documentVersion: event.target.value
    }));
  };

  const onSampleStateChange = event => {
    event.persist();
    setState(prevState => ({
      ...prevState,
      sampleState: event.target.value
    }));
  };

  const onDeviceIdsChange = value => {
    setState(prevState => ({ ...prevState, deviceIds: value }));
  };

  const onLocationChange = value => {
    setState(prevState => ({ ...prevState, location: value }));
  };

  const onDeviceModelsChange = value => {
    if (value) {
      const match = value.match(/(^.*)\s([0-9\.\-]*?)$/);
      const deviceModel = match[1];
      const osVersion = match.length === 3 ? match[2] : undefined;
      setState(prevState => ({
        ...prevState,
        deviceModel: deviceModel,
        osVersion: osVersion
      }));
    } else {
      setState(prevState => ({
        ...prevState,
        deviceModel: undefined,
        osVersion: undefined
        // osVersions: parsedModels.map(model => model.os_version),
      }));
    }
  };

  const onGuidsChange = value => {
    setState(prevState => ({
      ...prevState,
      guids: value
    }));
  };

  const onAnnotateSubeventsChange = event => {
    setState(prevState => ({
      ...prevState,
      annotateSubevents: event.target.checked
    }));
  };
  return (
    <div className="validate-controls">
      <Select
        disabled={readonly}
        label="App"
        className="control-element"
        showSearch
        style={{ width: 175 }}
        placeholder="Select an app"
        value={state.appName ? `${state.appName} ${state.platform}` : undefined}
        optionFilterProp="children"
        onChange={onAppChange}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {appsList.map(app => (
          <Option
            value={`${app.platform},${app.package},${app.name}`}
            key={app.name}
          >
            {`${app.name} ${app.platform}`}
          </Option>
        ))}
      </Select>
      <InputNumber
        disabled={readonly}
        className="control-element"
        min={1}
        value={state.limit}
        onChange={onLimitChange}
      />
      From Date:
      <DatePicker
        disabled={readonly}
        className="control-element"
        onChange={onFromDateChange}
        value={state.fromDate}
        format="DD/MM/YYYY HH:mm"
        showTime={{
          format: "HH:mm",
          defaultValue: moment("19:00", "HH:mm").subtract(7, "days")
        }}
      />
      To Date:
      <DatePicker
        disabled={readonly}
        className="control-element"
        onChange={onToDateChange}
        value={state.toDate}
        format="DD/MM/YYYY HH:mm"
        showTime={{
          format: "HH:mm",
          defaultValue: moment("19:00", "HH:mm")
        }}
      />
      Doc Ver:
      <Input
        disabled={readonly}
        style={{ maxWidth: 50 }}
        className="control-element"
        value={state.documentVersion}
        onChange={onDocVersionChange}
      />
      Device IDs:
      <Select
        disabled={readonly}
        mode="tags"
        className="control-element"
        value={state.deviceIds}
        onChange={onDeviceIdsChange}
        tokenSeparators={[","]}
        style={{ minWidth: "200px", maxWidth: "250px" }}
        dropdownRender={() => <div />}
      />
      Guids:
      <Select
        disabled={readonly}
        mode="tags"
        className="control-element"
        value={state.guids}
        onChange={onGuidsChange}
        tokenSeparators={[","]}
        style={{ minWidth: "200px", maxWidth: "250px" }}
        dropdownRender={() => <div />}
      />
      Device Model:
      <Select
        disabled={readonly}
        dropdownMatchSelectWidth={false}
        className="control-element"
        value={`${state.deviceModel || ""} ${state.osVersion || ""}`}
        onChange={onDeviceModelsChange}
        tokenSeparators={[","]}
        style={{ minWidth: "100px", maxWidth: "150px" }}
      >
        {allDeviceModels.map(model => (
          <Option
            value={`${model.model} ${model.os_version ? model.os_version : ""}`}
            key={`${model.model} ${model.os_version}`}
          >
            {model.model} {model.os_version}
          </Option>
        ))}
      </Select>
      Sample state:
      <Input
        disabled={readonly}
        style={{ maxWidth: 230 }}
        className="control-element"
        value={state.sampleState}
        onChange={onSampleStateChange}
      />
      Locaction:
      <Select
        disabled={readonly}
        allowClear
        label="Location"
        className="control-element"
        showSearch
        style={{ width: 100 }}
        placeholder="Select a location"
        value={state.location}
        optionFilterProp="children"
        onChange={onLocationChange}
      >
        {Object.keys(LOCATIONS).map(loc => (
          <Option value={LOCATIONS[loc]} key={loc}>
            {loc}
          </Option>
        ))}
      </Select>
      {/* <Checkbox
        checked={state.annotateSubevents}
        disabled={numberOfSamples > 0}
        onChange={onAnnotateSubeventsChange}
      >
        Subevents?
      </Checkbox> */}
      <Button
        disabled={!isFormValid() || readonly}
        className="control-element"
        onClick={fetchData}
        loading={loadingData}
      >
        Fetch Data
      </Button>
    </div>
  );
}
