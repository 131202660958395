import React from "react";
import ReactDOM from "react-dom";
import App from "components/App";
import "./index.scss";

require("./assets/icons/favicon.ico");

ReactDOM.render(<App />, document.getElementById("root"));

if (module.hot) {
  module.hot.accept();
}
